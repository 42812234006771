import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { UIImages } from "../data/images";
import { removeGenericBtnStyles } from "../styles/theme";
import { closeDialog } from "../state/app-state";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("popup-close-button")
export class PopupCloseButton extends LitElement {
  static styles = [
    removeGenericBtnStyles,
    css`
      .padding {
        padding: 8px 8px 0 0;
      }
    `,
  ];

  render() {
    return html`
      <button class="remove-btn-style " @click=${closeDialog}>
        <img
          src=${UIImages.Cross_Active}
          height="20px"
          width="20px"
          class="padding"
          draggable="false"
        />
      </button>
    `;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    playSoundEffect("PopOut");
  }
}
