import { ShopController } from "./shopController";
import { Vector2 } from "../models/vector2";
import { warn } from "../helpers/loggerHelper";
import { Customer, CustomerState, WalkAwayReason } from "../models/customer";
import { GameModel } from "../models/gameModel";
import { IGameView } from "../views/gameView";
import { mapData } from "./mapData";
import { IGameplayParams } from "../config/gameplayParameters";
import { Subcontroller } from "./subcontroller";
import { IntervalTimer } from "../timers/interval_timer";
import { Product } from "../models/product";

// CustomerController is responsible for any game logic related to customers
// and updating the customer game objects to reflect their current state.
export class CustomersController extends Subcontroller {
  private readonly shop: ShopController;

  private customerSpeed: number;
  private customerSpawnInterval: number;
  private customerSpawnProductMultipliers: Record<string, number> = {};
  private spawnIntervalDecrease: number;
  private customerSpawnTimers: Record<string, IntervalTimer> = {};

  constructor(
    model: GameModel,
    view: IGameView,
    shop: ShopController,
    gameplayParams: IGameplayParams,
  ) {
    super(model, view);
    this.shop = shop;

    // Read initial params
    this.customerSpeed = gameplayParams.customer.speed;
    this.customerSpawnInterval = gameplayParams.customer.spawnInterval;
    this.spawnIntervalDecrease = gameplayParams.customer.spawnIntervalDecrease;
    this.setCustomerSpawnProductMultipliers(gameplayParams);
  }

  startGame = (newGame: boolean) => {
    this.customerSpawnTimers = {};
    if (!newGame && !this.model.getShop().getProducts().length) {
      this.createFirstCustomerSpawnTimer(newGame);
    }
    this.model.getShop().getProducts().forEach(this.addCustomerSpawnTimer);
    return;
  };

  updateParams = (gameplayParams: IGameplayParams) => {
    if (this.customerSpeed !== gameplayParams.customer.speed) {
      this.customerSpeed = gameplayParams.customer.speed;
      this.model.allCustomers().forEach((customer) => {
        customer.speed = this.customerSpeed;
      });
    }
    if (
      this.customerSpawnInterval !== gameplayParams.customer.spawnInterval ||
      this.spawnIntervalDecrease !==
        gameplayParams.customer.spawnIntervalDecrease
    ) {
      this.customerSpawnInterval = gameplayParams.customer.spawnInterval;
      this.spawnIntervalDecrease =
        gameplayParams.customer.spawnIntervalDecrease;
      Object.entries(this.customerSpawnTimers).forEach(([key, timer]) =>
        timer.setInterval(
          this.getCustomerSpawnTimerMs(
            this.model
              .getShop()
              .getProducts()
              .find((p) => p.kind.key() === key)?.demand ?? 0,
            key,
          ),
        ),
      );
    }
    this.setCustomerSpawnProductMultipliers(gameplayParams, true);
  };

  private removeCustomer = (customer: Customer) => {
    this.model.removeCustomer(customer.id);
    this.view.destroyCustomer(customer.id);
  };

  private getCustomerLane = (): number => {
    const offset = Math.floor(Math.random() * 3); // there are only three lanes for the customers to walk along

    switch (offset) {
      case 0:
        return mapData.customerTopLane;
      case 1:
        return mapData.customerMidLane;
      case 2:
        return mapData.customerBottomLane;
    }
  };

  // Create a new customer
  create = (speed?: number, product?: Product): string => {
    const need = product
      ? {
          kind: product.kind,
          purchasePrice: product.price,
        }
      : undefined;

    const yPath = this.getCustomerLane();

    const c = new Customer(mapData.width, yPath, yPath, need, {
      speed,
    });
    this.model.addCustomer(c);
    this.view.createCustomer(c);
    return c.id;
  };

  private reachedTill(id: string) {
    const customer = this.model.customer(id);
    if (!customer) {
      return;
    }
    const result = this.shop.orderItem(
      id,
      customer.need.kind,
      customer.getTill(),
    );
    if (result === true) {
      customer.waitAtTill();
    } else {
      const till = this.model.getShop().getTill(customer.getTill());
      if (till && till.claimedBy() === customer.id) till.releaseClaim();
      this.onCustomerWalkAway(customer, result || WalkAwayReason.Exception);
    }
  }

  tickAll = (delta: number) => {
    this.model.allCustomers().forEach((c: Customer) => {
      this.tick(c, delta);
      this.view.updateCustomer(c);
    });
    Object.values(this.customerSpawnTimers).forEach((timer) =>
      timer.tick(delta),
    );
  };

  private tick = (customer: Customer, delta: number) => {
    if (!customer) {
      return;
    }

    switch (customer.getState()) {
      case CustomerState.Browsing:
        this.browse(customer, delta);
        break;
      case CustomerState.WalkingToTill:
        this.walkToTill(customer, delta);
        break;
      case CustomerState.WalkingAway:
        this.walkAway(customer, delta);
        break;
    }
  };

  private browse = (customer: Customer, delta: number) => {
    const offScreen = new Vector2(0, customer.getY());
    if (customer.moveTo(offScreen, delta, true)) {
      this.removeCustomer(customer);
    }
  };

  private walkToTill = (customer: Customer, delta: number) => {
    const till = this.model.getShop()?.getTill(customer.getTill());
    if (!till) {
      warn(`Can't find till to walk to ${customer.getTill()}`);
      return;
    }
    const customerTillPosition = till
      .position()
      .subtract(new Vector2(0, mapData.tileSize));
    if (customer.moveTo(customerTillPosition, delta, true)) {
      this.reachedTill(customer.id);
    }
  };

  private walkAway = (customer: Customer, delta: number) => {
    // setting this to roadTop for now until we get the correct paths sorted
    // NOTE: The x position is a bit offscreen to ensure that the customers don't get
    // removed while they are still visible.
    const offScreen = new Vector2(-mapData.tileSize, customer.getYPath());
    if (customer.moveTo(offScreen, delta, false)) {
      this.removeCustomer(customer);
    }
  };

  thinkAll = () => {
    this.model.allCustomers().forEach(this.think);
  };

  private think = (customer: Customer) => {
    switch (customer.getState()) {
      case CustomerState.Browsing: {
        if (!this.shop.withinRange(customer.getX())) {
          return;
        }
        const product = this.model.getShop().product(customer.need.kind);
        // 1. Check product in stock
        if (!product || product.stock.value === 0) {
          this.onCustomerWalkAway(customer, WalkAwayReason.ProductNotAvailable);
          return;
        }
        // 2. Check till is available
        const tills = this.shop.availableTills();
        if (tills.length == 0) {
          this.onCustomerWalkAway(customer, WalkAwayReason.TillNotAvailable);
          return;
        }
        // 3. Check product is cheap enough
        const chanceToFail = product.getChanceToFail();
        const isTooExpensive = !!chanceToFail && chanceToFail >= Math.random();
        if (isTooExpensive) {
          this.onCustomerWalkAway(customer, WalkAwayReason.ProductTooExpensive);
          return;
        } else {
          customer.need.purchasePrice = product.price;
        }

        // All checks passed, walk to an available till
        const idx = Math.floor(Math.random() * (tills.length - 1));
        const till = tills[idx];
        if (till.claim(customer.id)) {
          customer.walkToTill(till.id);
        } else {
          // If the customer fails to claim the till for some reason, walk away
          this.onCustomerWalkAway(customer, WalkAwayReason.TillNotAvailable);
        }
        return;
      }
    }
  };

  private onCustomerWalkAway = (customer: Customer, reason: WalkAwayReason) => {
    customer.walkAway(reason);
    // Check if the shop actually has that product in stock.
    // We don't want to track "failed" saves that occur before the user launches their first product
    if (
      reason !== WalkAwayReason.Exception &&
      this.model.getShop().product(customer.need.kind)
    ) {
      this.model.loseProductSale(customer.need.kind, reason);
    }
  };

  public createFirstCustomerSpawnTimer = (newGame = true) => {
    if (Object.keys(this.customerSpawnTimers).length) {
      warn(
        "[CustomerController] Tried to set the first spawn timer, but the timer object wasn't empty",
        {
          spawnTimers: this.customerSpawnTimers,
        },
      );
      return;
    }
    const product = this.shop.createFirstProduct();
    this.addCustomerSpawnTimer(product);
    if (newGame) this.create(this.customerSpeed, product);
  };

  private addCustomerSpawnTimer = (product: Product) => {
    this.customerSpawnTimers[product.kind.key()] = new IntervalTimer(
      () => {
        this.create(this.customerSpeed, product);
      },
      this.getCustomerSpawnTimerMs(product.demand, product.kind.key()),
    );
  };

  private getCustomerSpawnTimerMs = (demand: number, multiplierKey?: string) =>
    (this.customerSpawnInterval - demand * this.spawnIntervalDecrease) *
    1000 *
    (this.customerSpawnProductMultipliers[multiplierKey] ?? 1);

  private setCustomerSpawnProductMultipliers = (
    gameplayParams: IGameplayParams,
    update: boolean = false,
  ) => {
    gameplayParams.products.forEach((category) =>
      category.products.forEach((product) => {
        if (
          !update ||
          this.customerSpawnProductMultipliers[product.id] !==
            product.spawnRateMultiplier
        ) {
          this.customerSpawnProductMultipliers[product.id] =
            product.spawnRateMultiplier;
          this.customerSpawnTimers[product.id]?.setInterval(
            this.getCustomerSpawnTimerMs(
              this.model
                .getShop()
                .getProducts()
                .find((p) => p.kind.key() === product.id).demand,
              product.id,
            ),
          );
        }
      }),
    );
  };

  onProductLaunched = (product: Product) => {
    this.addCustomerSpawnTimer(product);
    this.create(this.customerSpeed, product);
  };

  onProductPromoted = (product: Product) => {
    this.customerSpawnTimers[product.kind.key()]?.setInterval(
      this.getCustomerSpawnTimerMs(product.demand, product.kind.key()),
    );
  };
}
