import { html, css, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { customElement, property, query } from "lit/decorators.js";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { buttonStyle, fontStyles } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { translate } from "../translations/translations";
import { playSoundEffect } from "../sound/sound-manager";
import { when } from "lit/directives/when.js";
import {
  IntroImages,
  UIImages,
  ChooseShopCategoryImages,
} from "../data/images";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { gameState, setNextIntroPage, skipIntro } from "../state/game-state";
import { ConfettiJuice } from "./confetti-juice";
import { popIn } from "../juice/juice";
import "./intro-flow-character-dialog";

@customElement("intro-flow")
export class IntroFlow extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    fontStyles,
    popIn,
    css`
      .background {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        position: relative;
        background-color: var(--secondary-violet-highlight);
      }
      .business-select-background {
        background-color: var(--semi-translucent-bg);
      }
      .skip-intro-button {
        position: absolute;
        top: 8px;
        right: 8px;
        border-radius: 59px;
        background: var(--semi-opaque-white);
        padding: 6px 10px;
        margin: 2px;
        color: var(--primary-navy);
        text-align: center;
      }

      .content {
        margin: 0;
        padding: 0;
      }

      .dialogue-content {
        display: flex;
        flex-direction: row;
        gap: 36px;
        position: relative;
      }

      /* Page One */
      .page-one-text {
        color: var(--primary-navy);
        text-align: center;
      }
      .envelope-img {
        width: 221px;
        height: 166px;
        margin: 18px 0px;
        @media (min-width: 1000px) {
          width: 326.622px;
          height: 249px;
        }
      }

      /* Page Two */
      .page-two-text {
        color: var(--primary-navy);
        text-align: left;
        margin: 0 0 14px;
      }

      .letter-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
      }

      .letter-img {
        position: absolute;
        top: -90px;
        left: -74px;
        width: 502px;
        height: 396px;
        z-index: 0;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        @media (min-width: 1000px) {
          width: 753px;
          height: 594px;
          top: -135px;
          left: -111px;
        }
      }

      .letter-text {
        width: 396px;
        /* Top and Left pixel offsets based on asset placement */
        padding: 10px 17px 0;
        box-sizing: border-box;
        z-index: 1;
        @media (min-width: 1000px) {
          width: 594px;
          padding: 15px 25.5px 0;
        }
      }

      #buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: row;
        margin-top: 16px;
        gap: 26px;
      }

      #buttons > button {
        flex-grow: 1;
      }

      /* Page Seven */
      .market-stall-content-header {
        display: flex;
        flex-direction: column;
        background: var(--primary);
        width: 100%;
        height: 36px;
        flex-grow: 0;
        @media (min-width: 1000px) {
          height: 54px;
        }
      }

      .market-stall-content-header-text {
        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        margin: 7px 0;
        @media (min-width: 1000px) {
          font-size: 30px;
        }
      }

      .market-stall-content-background {
        display: flex;
        position: relative;
        flex-direction: column;
        background: var(--white);
        width: 408px;
        height: 266px;
        border: 3px solid var(--primary-navy);
        border-radius: 4px;
        @media (min-width: 1000px) {
          width: 618px;
          height: 399px;
        }

        @media (max-height: 320px) and (max-width: 999px) {
          height: 192px;
        }
      }

      .market-stall-content-container {
        width: 100%;
        flex-grow: 1;
      }

      .market-stall-pill-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .market-stall-pill {
        width: 136px;
        height: 16px;
        padding: 4px 10px;
        border-radius: 12px;
        margin-top: 8px;
        @media (min-width: 1000px) {
          width: 204px;
          height: 24px;
          padding: 6px 15px;
          border-radius: 18px;
          margin-top: 12px;
        }
      }

      .market-stall-pill-colour {
        background: var(--marketStallPill);
      }

      .market-stall-type-pill-text {
        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        @media (min-width: 1000px) {
          font-size: 21px;
        }
      }

      .market-stall-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        @media (min-width: 1000px) {
          margin-top: 12px;
        }
      }

      .market-stall-img {
        width: 144px;
        height: 144px;
        @media (min-width: 1000px) {
          height: 216px;
          width: 216px;
        }

        @media (max-height: 320px) and (max-width: 999px) {
          height: 72px;
        }
      }

      .market-stall-img > img {
        max-width: 100%;
        max-height: 100%;
      }

      .open-btn-container {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }

      confetti-juice {
        touch-action: none;
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        margin: 0;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  @query("confetti-juice")
  confettiJuice: ConfettiJuice;

  @property({ type: Boolean })
  confettiDisabled = false;

  protected firstUpdated(): void {
    if (gameState.introPage.value === 1) {
      playSoundEffect("EmailNotification");
    }
  }

  onContinue = () => {
    setNextIntroPage();
    if (gameState.introPage.value === 2) {
      playSoundEffect("EmailOpen");
    } else {
      playSoundEffect("ButtonSelect_3");
    }
  };

  onSkip = () => {
    skipIntro();
    playSoundEffect("ButtonSelect_3");
  };

  renderPageOne = () => html`
    <div class="page-one-text heading-1">
      ${translate("IntroScreen01_Heading")}
    </div>
    <button @click=${this.onContinue}>
      <img
        class="envelope-img"
        src=${IntroImages.Intro_Envelope}
        draggable="false"
      />
    </button>
    <div class="page-one-text body-copy">
      ${translate("IntroScreen01_SubHeading")}
    </div>
  `;

  renderPageTwo = () => html`
    <div class="letter-container">
      <div class="letter-text">
        <p class="page-two-text body-copy">
          ${translate("IntroScreen02_Heading")}
        </p>
        <p class="page-two-text body-copy">
          ${translate("IntroScreen02_Body01")}
        </p>
        <p class="page-two-text body-copy">
          ${translate("IntroScreen02_Body02")}
        </p>
        <p class="page-two-text body-copy">
          ${translate("IntroScreen02_Regards")}
        </p>
        <p class="page-two-text body-copy">
          ${translate("IntroScreen02_Sender")}
        </p>
        <div id="buttons">
          <app-button
            class="popIn ui-button"
            text=${translate("IntroScreen02_Button_Response01")}
            @click=${this.onContinue}
          ></app-button>
          <app-button
            class="popIn ui-button"
            text=${translate("IntroScreen02_Button_Response02")}
            @click=${this.onContinue}
          ></app-button>
        </div>
      </div>
      <img class="letter-img" src=${IntroImages.Intro_Letter} />
    </div>
  `;

  renderPageThree = () => html`
    <intro-flow-character-dialog
      image=${UIImages.MainCharacterExpression_Sad}
      body1=${translate("IntroScreen03_Body01")}
      body2=${translate("IntroScreen03_Body02")}
      @next=${this.onContinue}
      nextText=${translate("IntroScreen03_Button_Next")}
    ></intro-flow-character-dialog>
  `;

  renderPageFour = () => html`
    <intro-flow-character-dialog
      image=${UIImages.MainCharacterExpression_Determined}
      body1=${translate("IntroScreen04_Body")}
      body2=${translate("IntroScreen04_End")}
      @next=${this.onContinue}
      nextText=${translate("IntroScreen04_Button_Next")}
    ></intro-flow-character-dialog>
  `;

  renderPageFive = () => html`
    <intro-flow-character-dialog
      image=${UIImages.MainCharacterExpression_Pondering}
      body1=${translate("IntroScreen05_Body")}
      body2=${translate("IntroScreen05_End")}
      @next=${this.onContinue}
      nextText=${translate("IntroScreen05_Button_Next")}
    ></intro-flow-character-dialog>
  `;

  renderPageSix = () => html`
    <business-selector-carousel
      @continue=${this.onContinue}
    ></business-selector-carousel>
  `;

  renderPageSeven = () => html`
    <div class="market-stall-content-background popIn">
      <div class="market-stall-content-header">
        <div class="market-stall-content-header-text">
          ${translate("OpenBusiness_Level1")}
        </div>
      </div>
      <div class="market-stall-content-container">
        <div class="market-stall-pill-container">
          <div class="market-stall-pill market-stall-pill-colour">
            <div class="market-stall-type-pill-text">
              ${translate("OpenBusiness_MarketStall")}
            </div>
          </div>
        </div>
        <div class="market-stall-img-container">
          <img
            class="market-stall-img"
            src=${ChooseShopCategoryImages.ChooseShopCatergory_MarketStall}
            draggable="false"
          />
        </div>
      </div>
      <div class="open-btn-container">
        <app-button
          class="popIn ui-button"
          text=${translate("OpenBusiness_Button_Open")}
          @click=${this.onContinue}
        ></app-button>
      </div>
    </div>
    <confetti-juice autoplay></confetti-juice>
  `;

  renderSkipIntro = () =>
    html`<button
      class="skip-intro-button skip-intro-copy"
      @click=${this.onSkip}
    >
      ${translate("IntroScreen01_Button_Skip")}
    </button>`;

  renderCurrentPage(page: number) {
    switch (page) {
      case 1:
        return this.renderPageOne();
      case 2:
        return this.renderPageTwo();
      case 3:
        return this.renderPageThree();
      case 4:
        return this.renderPageFour();
      case 5:
        return this.renderPageFive();
      case 6:
        return this.renderPageSix();
      case 7:
        return this.renderPageSeven();
      default:
        return nothing;
    }
  }

  render() {
    const inDialogue =
      gameState.introPage.value >= 1 && gameState.introPage.value <= 5;
    return html`
      <div
        class=${classMap({
          background: true,
          "business-select-background": !inDialogue,
        })}
      >
        ${when(inDialogue, this.renderSkipIntro)}
        <div class="content">
          ${this.renderCurrentPage(gameState.introPage.value)}
        </div>
      </div>
    `;
  }
}
