// Source code for confetti adapted from
// https://codepen.io/markmulvey/pen/qYmOow
// Credit: Mark Mulvey
// Originally found on codepel.com
// https://www.codepel.com/animation/animated-confetti-background/

import { customElement, property, query } from "lit/decorators.js";
import { randomFromTo } from "../helpers/mathHelper";
import { LitElement, html, css } from "lit";

const possibleColors = [
  "#ff7336",
  "#f9e038",
  "#02cca4",
  "#383082",
  "#fed3f5",
  "#b1245a",
  "#f2733f",
];
const maxConfettis = 25;
const minRadius = 11;
const maxRadius = 33;

class ConfettiParticle {
  x: number;
  y: number;
  r: number;
  d: number;
  color: string;
  tilt: number;
  tiltAngleIncremental: number;
  tiltAngle: number;

  constructor(width: number, height: number) {
    this.x = Math.random() * width;
    this.y = Math.random() * height - height;
    this.r = randomFromTo(minRadius, maxRadius);
    this.d = Math.random() * maxConfettis + minRadius;
    this.color = possibleColors[randomFromTo(0, possibleColors.length)];
    this.tilt = Math.floor(Math.random() * 33) - 11;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;
  }

  draw(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.lineWidth = this.r / 2;
    context.strokeStyle = this.color;
    context.moveTo(this.x + this.tilt + this.r / 3, this.y);
    context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
    return context.stroke();
  }
}

@customElement("confetti-juice")
export class ConfettiJuice extends LitElement {
  static styles = [
    css`
      #canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        margin: 0;
      }

      .hiddenDiv {
        display: none;
      }
    `,
  ];

  @query("#canvas")
  canvas: HTMLCanvasElement;

  @property({ type: Boolean })
  autoplay = false;

  particles: ConfettiParticle[] = [];
  context: CanvasRenderingContext2D;

  firstUpdated() {
    this.context = this.canvas.getContext("2d");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    for (let i = 0; i < maxConfettis; i++) {
      this.particles.push(
        new ConfettiParticle(this.canvas.width, this.canvas.height),
      );
    }

    if (this.autoplay) {
      this.draw();
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.stop();
  }

  private animationId: number;

  stop = () => {
    if (!this.animationId) {
      return;
    }
    cancelAnimationFrame(this.animationId);
  };

  draw = () => {
    this.animationId = requestAnimationFrame(this.draw);
    const { width: w, height: h } = this.canvas;

    this.context.clearRect(0, 0, w, h);

    for (let i = 0; i < maxConfettis; i++) {
      this.particles[i].draw(this.context);
    }

    let particle: ConfettiParticle;
    for (let i = 0; i < maxConfettis; i++) {
      particle = this.particles[i];

      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
      particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

      // If a confetti has fluttered out of view,
      // bring it back to above the viewport and let if re-fall.
      if (particle.x > w + 30 || particle.x < -30 || particle.y > h) {
        particle.x = Math.random() * w;
        particle.y = -30;
        particle.tilt = Math.floor(Math.random() * 10) - 20;
      }
    }
  };

  render() {
    return html`
      <canvas
        id="canvas"
        @resize=${() => {
          if (!this.canvas) return;
          this.canvas.width = window.innerWidth;
          this.canvas.height = window.innerHeight;
        }}
      ></canvas>
    `;
  }
}
