import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { borderStyles, buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIWatchableModel } from "../models/gameModel";
import { FinancialReportModel } from "../models/financialReportModel";
import { translate } from "../translations/translations";
import { UIImages } from "../data/images";
import "./tip-button";

@customElement("business-value")
export class BusinessValue extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    borderStyles,
    css`
      :host {
        width: 100%;
        height: 100%;
      }

      .container {
        width: 100%;
        height: 100%;
        background-color: var(--businessValue-light-orange);

        display: flex;
        flex-direction: row;
        gap: 40px;
        place-content: center;
        place-items: center;

        @media (min-width: 1000px) {
          gap: 80px;
        }
      }

      .grow {
        flex-grow: 1;
      }

      .center-content {
        display: flex;
        place-content: center;
        place-items: center;
      }

      .left {
        box-sizing: border-box;
        width: 238px;
        height: 145px;
        padding: 14px 15px;
        border-radius: 4px;
        background-color: var(--businessValue-medium-orange);

        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: 1000px) {
          width: 330px;
          height: 214px;
          padding: 30px;
          gap: 16px;
        }
      }

      .title {
        font-size: 14px;
        font-weight: 700;
        @media (min-width: 1000px) {
          font-size: 18px;
        }
      }

      .left-total {
        font-size: 24px;
        font-weight: 700;
        gap: 2px;
        @media (min-width: 1000px) {
          font-size: 36px;
        }

        display: flex;
        place-items: center;
      }

      .left-total > img {
        width: 28px;
        height: 28px;
      }

      .left-text {
        font-size: 12px;
        font-weight: 500;

        @media (min-width: 1000px) {
          font-size: 15.8px;
        }
      }

      .right {
        width: 238px;
        height: 145px;
        text-transform: uppercase;

        padding: 0 14px;

        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 10px;

        @media (min-width: 1000px) {
          width: 330px;
          height: 214px;
          gap: 20px;
        }
      }

      .value-breakdown {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .text-line {
        width: 100%;
        gap: 4px;
        display: flex;
        align-items: center;

        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        @media (min-width: 1000px) {
          font-size: 16px;
        }
      }

      .value-total {
        width: 100%;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--businessValue-orange);
        border-radius: 0 0 4px 4px;
        border-top: 2px solid var(--primary-navy);

        font-size: 12px;
        font-weight: 700;
        line-height: 20px;

        @media (min-width: 1000px) {
          font-size: 16px;
        }
      }

      .text-value-pair {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .value-total > .text-value-pair {
        padding: 0 5px;
        box-sizing: border-box;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  uiModel: UIWatchableModel;
  financialReportModel: FinancialReportModel;

  connectedCallback(): void {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
  }

  renderLine = () => {
    return html`
      <div
        style="width: 100%; height: 2px; background-color: var(--primary-navy)"
      ></div>
    `;
  };

  render() {
    return html`
      <div class="container">
        <div class="left">
          <div class="title">${translate("FinancialReport_BV_Tab_Title")}</div>
          <div class="left-total">
            <img src=${UIImages.ValueGraph} draggable="false" />
            ${this.uiModel.businessValue}
          </div>
          ${this.renderLine()}
          <div class="left-text grow center-content">
            ${translate("FinancialReport_BV_Tab_HighestBusinessValue")}
          </div>
        </div>
        <div class="right">
          <div class="value-breakdown">
            <div class="text-line">
              <tip-button key="Tip_Cash"></tip-button>
              <div class="text-value-pair">
                <div>${translate("FinancialReport_BV_Tab_Cash")}</div>
                <div>${this.uiModel.gold}</div>
              </div>
            </div>
            <div class="text-line">
              <tip-button key="Tip_Stock"></tip-button>
              <div class="text-value-pair">
                <div>${translate("FinancialReport_BV_Tab_Stock")}</div>
                <div>${this.financialReportModel.stockValue}</div>
              </div>
            </div>
            <div class="text-line">
              <tip-button key="Tip_CommunityImpact"></tip-button>
              <div class="text-value-pair">
                <div>${translate("FinancialReport_BV_Tab_Achievements")}</div>
                <div>${this.financialReportModel.achievementsValue}</div>
              </div>
            </div>
          </div>
          <div class="value-total">
            <div class="text-value-pair">
              <div>${translate("FinancialReport_BV_Tab_Total")}</div>
              <div>${this.uiModel.businessValue}</div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
