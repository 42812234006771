export interface IProductParams {
  id: string;
  name: string;
  cost: number;
  maxCost: number;
  stockLimit: number;
  spawnRateMultiplier: number;
}

export interface IProductCategoryParams {
  category: string;
  products: IProductParams[];
}

export enum EventTriggerCategories {
  ShopLevel = "ShopLevel", // Shop Level x
  Cash = "Cash", // Cash = x
  BusinessValue = "BusinessValue", // Business Value = x
  Profit = "Profit", // Profit by x payday
  Loss = "Loss", // Loss by x payday
  Staff = "Staff", // Hired x Staff
  ProductLaunch = "ProductLaunch", // Product x Launched
  LostSalePrice = "LostSalePrice", // Lost x Sales due to Price
  LostSaleStock = "LostSaleStock", // Lost x Sales due to No Stock
  LostSaleStaff = "LostSaleStaff", // Lost x Sales due to No Staff
  LostSaleMarketing = "LostSaleMarketing", // Lost x Sales due to No Marketing
  StaffIdleTime = "StaffIdleTime", // Staff Idling for x Seconds
  Sales = "Sales", // Sale No. x
  CanLaunchBy = "CanLaunchBy", // Can Launch Next Product By x
  CanHireBy = "CanHireBy", // Can Hire Next Staff By x
  Payday = "Payday", // Payday x
}

interface EventTrigger {
  category: keyof typeof EventTriggerCategories;
  value: number;
}

export interface IEventParams {
  id: string;
  trigger: EventTrigger;
  cash?: number;
  businessValue?: number;
}

export interface IGameplayParams {
  customer: {
    spawnInterval: number;
    spawnIntervalDecrease: number;
    speed: number;
  };
  staff: {
    orderTakingSpeed: number;
    orderFetchingSpeed: number;
    orderFinishingSpeed: number;
    orderSpeedMultiplier: number;
    speed: number;
    hiringCost: number;
    multiplierPerStaff: number;
  };
  marketing: {
    cost: number;
    increasePerLevel: number;
    maxDemand: number;
  };
  products: IProductCategoryParams[];
  payday: {
    secondsPerPayday: number;
    paydayCount: number;
  };
  events: IEventParams[];
}

export interface ISerializedParamsJson {
  version: number;
  timestamp: string;
  params: IGameplayParams;
}

// Increment if there are breaking changes to the scheme of the Gameplay Params
export const GameplayParamsVersion = 1;

export const GameplayParams: IGameplayParams = {
  customer: {
    spawnInterval: 15,
    spawnIntervalDecrease: 0.2,
    speed: 150,
  },
  staff: {
    orderTakingSpeed: 1,
    orderFetchingSpeed: 1,
    orderFinishingSpeed: 1,
    orderSpeedMultiplier: 0.4,
    speed: 175,
    hiringCost: 15,
    multiplierPerStaff: 2,
  },
  marketing: {
    cost: 1,
    increasePerLevel: 0.5,
    maxDemand: 50,
  },
  products: [
    {
      category: "clothing",
      products: [
        {
          id: "product_clothing_0",
          name: "Caps",
          cost: 1,
          maxCost: 7,
          stockLimit: 5,
          spawnRateMultiplier: 0.5,
        },
        {
          id: "product_clothing_1",
          name: "Flip flops",
          cost: 3,
          maxCost: 25,
          stockLimit: 5,
          spawnRateMultiplier: 0.9,
        },
        {
          id: "product_clothing_2",
          name: "T-shirt",
          cost: 8,
          maxCost: 50,
          stockLimit: 4,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_clothing_3",
          name: "Strappy top",
          cost: 10,
          maxCost: 60,
          stockLimit: 8,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_clothing_4",
          name: "Hoodie",
          cost: 50,
          maxCost: 150,
          stockLimit: 3,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_clothing_5",
          name: "Jean",
          cost: 100,
          maxCost: 275,
          stockLimit: 6,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_clothing_6",
          name: "Boots",
          cost: 180,
          maxCost: 400,
          stockLimit: 10,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_clothing_7",
          name: "Sneakers",
          cost: 2000,
          maxCost: 3000,
          stockLimit: 2,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_clothing_8",
          name: "Street Puffer Jacket",
          cost: 1500,
          maxCost: 2800,
          stockLimit: 6,
          spawnRateMultiplier: 4,
        },
      ],
    },
    {
      category: "technology",
      products: [
        {
          id: "product_technology_0",
          name: "Phone cover",
          cost: 1,
          maxCost: 7,
          stockLimit: 5,
          spawnRateMultiplier: 0.5,
        },
        {
          id: "product_technology_1",
          name: "Earphones",
          cost: 3,
          maxCost: 25,
          stockLimit: 5,
          spawnRateMultiplier: 0.9,
        },
        {
          id: "product_technology_2",
          name: "Bluetooth speaker",
          cost: 8,
          maxCost: 50,
          stockLimit: 4,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_technology_3",
          name: "Ring light camera",
          cost: 10,
          maxCost: 60,
          stockLimit: 8,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_technology_4",
          name: "Smart watch",
          cost: 50,
          maxCost: 150,
          stockLimit: 3,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_technology_5",
          name: "Cellphone",
          cost: 100,
          maxCost: 275,
          stockLimit: 6,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_technology_6",
          name: "Camera",
          cost: 180,
          maxCost: 400,
          stockLimit: 10,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_technology_7",
          name: "Laptop",
          cost: 2000,
          maxCost: 3000,
          stockLimit: 2,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_technology_8",
          name: "Game Console",
          cost: 1500,
          maxCost: 2800,
          stockLimit: 6,
          spawnRateMultiplier: 4,
        },
      ],
    },
    {
      category: "coffeeShop",
      products: [
        {
          id: "product_coffeeShop_0",
          name: "Coffee",
          cost: 1,
          maxCost: 7,
          stockLimit: 5,
          spawnRateMultiplier: 0.5,
        },
        {
          id: "product_coffeeShop_1",
          name: "Pastry",
          cost: 3,
          maxCost: 25,
          stockLimit: 5,
          spawnRateMultiplier: 0.9,
        },
        {
          id: "product_coffeeShop_2",
          name: "Bubble Tea",
          cost: 8,
          maxCost: 50,
          stockLimit: 4,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_coffeeShop_3",
          name: "Sandwich",
          cost: 10,
          maxCost: 60,
          stockLimit: 8,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_coffeeShop_4",
          name: "Fancy Coffee (Starbucks)",
          cost: 50,
          maxCost: 150,
          stockLimit: 3,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_coffeeShop_5",
          name: "Full cake",
          cost: 100,
          maxCost: 275,
          stockLimit: 6,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_coffeeShop_6",
          name: "Cake slice",
          cost: 180,
          maxCost: 400,
          stockLimit: 10,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_coffeeShop_7",
          name: "Coffee Beans",
          cost: 2000,
          maxCost: 3000,
          stockLimit: 2,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_coffeeShop_8",
          name: "Coffee Machine",
          cost: 1500,
          maxCost: 2800,
          stockLimit: 6,
          spawnRateMultiplier: 4,
        },
      ],
    },
    {
      category: "stationary",
      products: [
        {
          id: "product_stationary_0",
          name: "Notebook",
          cost: 1,
          maxCost: 7,
          stockLimit: 5,
          spawnRateMultiplier: 0.5,
        },
        {
          id: "product_stationary_1",
          name: "Colourful pens",
          cost: 3,
          maxCost: 25,
          stockLimit: 5,
          spawnRateMultiplier: 0.9,
        },
        {
          id: "product_stationary_2",
          name: "Pencil case",
          cost: 8,
          maxCost: 50,
          stockLimit: 4,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_stationary_3",
          name: "(Tote?) Bag",
          cost: 10,
          maxCost: 60,
          stockLimit: 8,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_stationary_4",
          name: "Pencil holder",
          cost: 50,
          maxCost: 150,
          stockLimit: 3,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_stationary_5",
          name: "Desk calendar",
          cost: 100,
          maxCost: 275,
          stockLimit: 6,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_stationary_6",
          name: "Novel",
          cost: 180,
          maxCost: 400,
          stockLimit: 10,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_stationary_7",
          name: "Journal",
          cost: 2000,
          maxCost: 3000,
          stockLimit: 2,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_stationary_8",
          name: "Art Supplies Box Set",
          cost: 1500,
          maxCost: 2800,
          stockLimit: 6,
          spawnRateMultiplier: 4,
        },
      ],
    },
    {
      category: "toiletries",
      products: [
        {
          id: "product_toiletries_0",
          name: "Eyeshadow",
          cost: 1,
          maxCost: 7,
          stockLimit: 5,
          spawnRateMultiplier: 0.5,
        },
        {
          id: "product_toiletries_1",
          name: "Lipstick",
          cost: 3,
          maxCost: 25,
          stockLimit: 5,
          spawnRateMultiplier: 0.9,
        },
        {
          id: "product_toiletries_2",
          name: "Nail polish",
          cost: 8,
          maxCost: 50,
          stockLimit: 4,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_toiletries_3",
          name: "False Eyelashes",
          cost: 10,
          maxCost: 60,
          stockLimit: 8,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_toiletries_4",
          name: "Hair treatment",
          cost: 50,
          maxCost: 150,
          stockLimit: 3,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_toiletries_5",
          name: "Hair clip",
          cost: 100,
          maxCost: 275,
          stockLimit: 6,
          spawnRateMultiplier: 2,
        },
        {
          id: "product_toiletries_6",
          name: "Face cream",
          cost: 180,
          maxCost: 400,
          stockLimit: 10,
          spawnRateMultiplier: 1,
        },
        {
          id: "product_toiletries_7",
          name: "Fancy hair weave",
          cost: 2000,
          maxCost: 3000,
          stockLimit: 2,
          spawnRateMultiplier: 4,
        },
        {
          id: "product_toiletries_8",
          name: "Serum",
          cost: 1500,
          maxCost: 2800,
          stockLimit: 6,
          spawnRateMultiplier: 4,
        },
      ],
    },
  ],
  payday: {
    secondsPerPayday: 136,
    paydayCount: 12,
  },
  events: [
    {
      id: "shop-2",
      trigger: {
        category: "ShopLevel",
        value: 2,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "shop-3",
      trigger: {
        category: "ShopLevel",
        value: 3,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "shop-4",
      trigger: {
        category: "ShopLevel",
        value: 4,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "shop-5",
      trigger: {
        category: "ShopLevel",
        value: 5,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "first-profit",
      trigger: {
        category: "Profit",
        value: 3,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "first-loss",
      trigger: {
        category: "Loss",
        value: 3,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-1st-staff",
      trigger: {
        category: "Staff",
        value: 2,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-2nd-staff",
      trigger: {
        category: "Staff",
        value: 3,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-3rd-staff",
      trigger: {
        category: "Staff",
        value: 4,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-4th-staff",
      trigger: {
        category: "Staff",
        value: 5,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-5th-staff",
      trigger: {
        category: "Staff",
        value: 6,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-6th-staff",
      trigger: {
        category: "Staff",
        value: 7,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "hired-7th-staff",
      trigger: {
        category: "Staff",
        value: 8,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "product-1-added",
      trigger: {
        category: "ProductLaunch",
        value: 1,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-price",
      trigger: {
        category: "LostSalePrice",
        value: 5,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-stock",
      trigger: {
        category: "LostSaleStock",
        value: 4,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-staff",
      trigger: {
        category: "LostSaleStaff",
        value: 4,
      },
      cash: 15,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-marketing",
      trigger: {
        category: "LostSaleMarketing",
        value: 100,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-price-n",
      trigger: {
        category: "LostSalePrice",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-stock-n",
      trigger: {
        category: "LostSaleStock",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-staff-n",
      trigger: {
        category: "LostSaleStaff",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "lost-sale-no-marketing-n",
      trigger: {
        category: "LostSaleMarketing",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "business-value-1",
      trigger: {
        category: "BusinessValue",
        value: 500,
      },
      businessValue: 0,
      cash: 0,
    },
    {
      id: "business-value-2",
      trigger: {
        category: "BusinessValue",
        value: 1250,
      },
      cash: 0,
      businessValue: 100,
    },
    {
      id: "business-value-3",
      trigger: {
        category: "BusinessValue",
        value: 3000,
      },
      cash: 0,
      businessValue: 250,
    },
    {
      id: "business-value-4",
      trigger: {
        category: "BusinessValue",
        value: 9000,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "business-value-5",
      trigger: {
        category: "BusinessValue",
        value: 20000,
      },
      cash: 0,
      businessValue: 1000,
    },
    {
      id: "business-value-6",
      trigger: {
        category: "BusinessValue",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "cash-value",
      trigger: {
        category: "Cash",
        value: -5,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "salary-first-due-1-second",
      trigger: {
        category: "StaffIdleTime",
        value: 5,
      },
      cash: 15,
      businessValue: 0,
    },
    {
      id: "salary-first-due-30-seconds",
      trigger: {
        category: "StaffIdleTime",
        value: 30,
      },
      cash: 10,
      businessValue: 0,
    },
    {
      id: "number-of-sales-equals-1",
      trigger: {
        category: "Sales",
        value: 1,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "number-of-sales-equals-n",
      trigger: {
        category: "Sales",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "cash-cost-of-next-product-x",
      trigger: {
        category: "CanLaunchBy",
        value: 10,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "cash-cost-of-next-staff-x",
      trigger: {
        category: "CanHireBy",
        value: 55,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-1",
      trigger: {
        category: "Payday",
        value: 1,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-2",
      trigger: {
        category: "Payday",
        value: 2,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-3",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-4",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-5",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-6",
      trigger: {
        category: "Payday",
        value: 6,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-7",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-8",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-9",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-10",
      trigger: {
        category: "Payday",
        value: 0,
      },
      cash: 0,
      businessValue: 0,
    },
    {
      id: "payday-11",
      trigger: {
        category: "Payday",
        value: 11,
      },
      cash: 0,
      businessValue: 0,
    },
  ],
};
