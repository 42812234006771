import { debug, error } from "../helpers/loggerHelper";
import { ProductInfo } from "../models/financialReportModel";
import { appState } from "../state/app-state";
if (__BUILD_ENV__ !== "TEST") {
  window.dataLayer = window.dataLayer || [];
}

declare type EventKey =
  | "LoginSuccess"
  | "GameStart"
  | "GameAbandoned"
  | "ChooseBusinessType"
  | "StockOrdered"
  | "ProductPricing"
  | "SaleMade"
  | "BeginEarningProfit"
  | "MarketingSpend"
  | "LevelUpToFullTime"
  | "HireStaff"
  | "PayStaff"
  | "ProductAdded"
  | "FinancialReport"
  | "MarketResearch"
  | "BusinessGrowth"
  | "FinalPaydayBusinessValuation"
  | "GameplayTips"
  | "AchievementCompleted";

let user_id: string = "";
appState.user.subscribe((user) => {
  if (user && user.userId) {
    user_id = user.userId;
  }
});

function evt(
  event: EventKey,
  eventParams: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  },
) {
  if (__BUILD_ENV__ === "TEST") return;
  if (!user_id) {
    error("[analytics] user_id was undefined");
    return;
  }
  if (__BUILD_ENV__ === "DEV") {
    debug("Analytics event", {
      event,
      user_id,
      params: eventParams,
    });
  } else {
    window.dataLayer.push({
      event,
      user_id,
      ...eventParams,
    });
  }
}

export function LoginSuccessEvent() {
  evt("LoginSuccess", {});
}

export function GameStartEvent(newGame: boolean) {
  evt("GameStart", {
    game_type: newGame ? "new_game" : "Continuation of previous session",
  });
}

export function GameAbandonedEvent(
  payday_count: number,
  current_payday_progress: number,
) {
  evt("GameAbandoned", {
    payday_number: payday_count + 1,
    current_payday_progress,
  });
}

export function ChooseBusinessTypeEvent(business_type: string) {
  evt("ChooseBusinessType", {
    business_type,
  });
}

export function StockOrderedEvent(
  stock_quantity: number,
  stock_cost: number,
  product_key: string,
  product_name: string,
) {
  evt("StockOrdered", {
    stock_quantity,
    stock_cost,
    product_key,
    product_name,
  });
}

export function ProductPricingEvent(
  selling_price: number,
  max_price: number,
  purchase_price: number,
  product_key: string,
  product_name: string,
) {
  evt("ProductPricing", {
    selling_price,
    max_price,
    purchase_price,
    product_key,
    product_name,
  });
}

export function SaleMadeEvent(
  product_key: string,
  product_name: string,
  sale_price: number,
  cost: number,
) {
  evt("SaleMade", {
    product_key,
    product_name,
    sale_price,
    profit_margin: sale_price - cost,
  });
}

export function FirstProfitEvent(profit: number, payday_count: number) {
  evt("BeginEarningProfit", {
    revenue_vs_expenses: profit,
    payday_number: payday_count + 1,
  });
}

export function MarketingSpendEvent(
  amount_spent: number,
  demand_level: number,
  product_key: string,
  product_name: string,
) {
  evt("MarketingSpend", {
    amount_spent,
    demand_level,
    product_key,
    product_name,
  });
}

export function HireStaffEvent(staff_index: number, salary: number) {
  evt("HireStaff", {
    staff_index,
    salary,
  });
}

export function PayStaffEvent(staff_index: number, salary: number) {
  evt("PayStaff", {
    staff_index,
    salary,
  });
}

export function ProductAddedEvent(
  product_key: string,
  product_name: string,
  sale_price: number,
) {
  evt("ProductAdded", {
    product_key,
    product_name,
    sale_price,
  });
}

export function FinancialReportEvent(
  business_value: number,
  profit_loss: number,
  payday_count: number,
) {
  evt("FinancialReport", {
    business_value,
    profit_loss,
    payday_number: payday_count + 1,
  });
}

export function MarketResearchEvent(
  number_of_sales: number,
  totalProductInfo: ProductInfo,
) {
  const {
    product_not_available,
    till_not_available,
    product_too_expensive,
    no_marketing,
  } = totalProductInfo;
  evt("MarketResearch", {
    number_of_sales,
    product_feedback_received: {
      product_not_available,
      till_not_available,
      product_too_expensive,
      no_marketing,
    },
  });
}

export function BusinessGrowthEvent(level_up: number) {
  evt("BusinessGrowth", {
    level_up,
  });
}

export function AchievementCompletedEvent(
  achievement_id: string,
  gold: number | undefined,
  business_value: number | undefined,
  payday_count: number,
  current_payday_progress: number,
) {
  evt("AchievementCompleted", {
    achievement_id,
    gold: gold ?? 0,
    business_value: business_value ?? 0,
    payday_number: payday_count + 1,
    current_payday_progress,
  });
}

export function TipOpenEvent(tip_id: string) {
  evt("GameplayTips", {
    tip_id,
  });
}

export function FinalPaydayBusinessValuation(
  final_business_value: number,
  total_profit: number | null,
) {
  evt("FinalPaydayBusinessValuation", {
    final_business_value,
    total_profit,
  });
}
