/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameDataResponse } from "../models/GameDataResponse";
import type { SaveUserGameData } from "../models/SaveUserGameData";
import type { UserDataResponse } from "../models/UserDataResponse";
import type { UserHighScoreResponse } from "../models/UserHighScoreResponse";
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";
export class GameDataService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns UserDataResponse Returns the name of the currently logged in user.
   * @throws ApiError
   */
  public getCurrentUser(): CancelablePromise<UserDataResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/user",
      errors: {
        400: `One or more validation errors have occurred.`,
        404: `No UserDataResponse could be found with the provided parameters.`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @param slug The slug of the game making the request.
   * @returns GameDataResponse Returns the specified GameDataResponse.
   * @throws ApiError
   */
  public getUserGameData(slug: string): CancelablePromise<GameDataResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/game/{slug}/game-data",
      path: {
        slug: slug,
      },
      errors: {
        400: `One or more validation errors have occurred.`,
        404: `No GameDataResponse could be found with the provided parameters.`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @param slug The unique slug of the game making the request.
   * @param requestBody
   * @returns GameDataResponse Successfully created.
   * @throws ApiError
   */
  public saveUserGameData(
    slug: string,
    requestBody?: SaveUserGameData,
  ): CancelablePromise<GameDataResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/resources/game/{slug}/game-data",
      path: {
        slug: slug,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `One or more validation errors have occurred.`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @param slug The slug of the game making the request.
   * @returns UserHighScoreResponse Returns the specified GameDataResponse.
   * @throws ApiError
   */
  public getUserHighScore(
    slug: string,
  ): CancelablePromise<UserHighScoreResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/game/{slug}/my-high-score",
      path: {
        slug: slug,
      },
      errors: {
        400: `One or more validation errors have occurred.`,
        404: `No UserHighScoreResponse could be found with the provided parameters.`,
        500: `Server Error`,
      },
    });
  }
}
