import { Signal, signal } from "@lit-labs/preact-signals";
import { IUserData } from "../portal/type";
import { Employee } from "../models/employee";
import { Product } from "../models/product";
import { info } from "../helpers/loggerHelper";
import { TipTabKeys } from "../data/tips";

export enum Screen {
  Loading = "Loading",
  LogoutWarningScreen = "Logout Warning Screen",
  LoggedOutScreen = "Logged Out Screen",
  MainMenu = "Main Menu",
  Game = "Game",
  SignIn = "Sign-in Screen",
  ChooseSave = "Choose Save",
  GameOver = "GameOver",
}

export enum ActiveDialog {
  None,
  HireEmployee,
  PayEmployee,
  LaunchProduct,
  OpenProduct,
  TestEvent,
}

export declare type PayEmployeeProps = Employee;
export interface LaunchProductProps {
  x?: number;
  y?: number;
}
export interface OpenProductProps {
  product: Product;
  x?: number;
  y?: number;
  isLeft?: boolean;
}
declare type DialogProps =
  | PayEmployeeProps
  | LaunchProductProps
  | OpenProductProps;

export interface Dialog {
  type: ActiveDialog;
  props?: DialogProps;
}

export interface IAppState {
  user: Signal<IUserData>;
  language: Signal<string>;
  screen: Signal<Screen>;
  dialog: Signal<Dialog>;
  saving: Signal<boolean>;
  tip: Signal<TipTabKeys | null>;
  preloadedGame: Signal<boolean>;
  preloadedUI: Signal<boolean>;
  isMuted: Signal<boolean>;
  isPageHidden: Signal<boolean>;
  isHorizontal: Signal<boolean>;
}

// Using an initial value of undefined for "not set" and null for "no value"
const user: Signal<IUserData> = signal(undefined);
const language = signal("");
const screen = signal(Screen.Loading);
const dialog: Signal<Dialog> = signal({ type: ActiveDialog.None });
const saving: Signal<boolean> = signal(false);
const tip = signal(null);
const preloadedGame: Signal<boolean> = signal(false);
const preloadedUI: Signal<boolean> = signal(false);
const isMuted: Signal<boolean> = signal(false);
const isPageHidden = signal(false);
const isHorizontal = signal(window.innerWidth >= window.innerHeight);

export const appState: IAppState = {
  user,
  language,
  screen,
  dialog,
  saving,
  tip,
  preloadedGame,
  preloadedUI,
  isMuted,
  isPageHidden,
  isHorizontal,
};

export const setUser = (u: IUserData | null) => {
  user.value = u;
};
export const setLanguage = (l: string) => (language.value = l);
export const setScreen = (s: Screen) => {
  info(`Setting screen to ${s}`);
  screen.value = s;
};
export const setSaving = (value: boolean) => (saving.value = value);

export const openDialog = (type: ActiveDialog, props?: DialogProps) =>
  (dialog.value = { type, props });
export const closeDialog = () => {
  dialog.value = { type: ActiveDialog.None };
};

export const setActiveTipKey = (t: TipTabKeys | null) => (tip.value = t);

export const onGamePreloadComplete = () => (preloadedGame.value = true);
export const onUIPreloadComplete = () => (preloadedUI.value = true);
export const isPreloaded = () => preloadedGame.value && preloadedUI.value;

export const toggleMute = () => (isMuted.value = !isMuted.value);

document.addEventListener("visibilitychange", () => {
  isPageHidden.value = document.hidden;
});

const orientationCheck = () => {
  isHorizontal.value = window.innerWidth >= window.innerHeight;
};

const onResize = () => {
  orientationCheck();
  // iOS debounce, bc of course
  setTimeout(orientationCheck, 500);
};

orientationCheck();
window.addEventListener("resize", onResize);
