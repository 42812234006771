/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { addBreadcrumb, captureException } from "@sentry/browser";
import { isDevEnv } from "../env";

/**
 * This helper is intended to funnel all debug, info, warn and error logs to conditionally log to console.
 */

declare type dataObject = { [key: string]: any };
declare type LogLevel =
  | "fatal"
  | "error"
  | "warning"
  | "log"
  | "info"
  | "debug";

export function _log(
  logFn: (...parts: any[]) => void,
  level: LogLevel,
  msg: string,
  data?: dataObject,
) {
  if (isDevEnv) {
    if (data) {
      // Make a copy of the object, so you get the actual value at time of logging, rather than the reference.
      logFn(msg, JSON.parse(JSON.stringify(data)));
    } else {
      logFn(msg);
    }
  }
  if (level !== "debug") {
    addBreadcrumb({
      message: msg,
      level: level,
      data: data,
    });
  }
}

export function _log_error(
  logFn: (...parts: any[]) => void,
  level: LogLevel,
  err: any,
  params?: dataObject,
) {
  if (isDevEnv) {
    if (params) {
      // Make a copy of the object, so you get the actual value at time of logging, rather than the reference.
      logFn(err, JSON.parse(JSON.stringify(params)));
    } else {
      logFn(err);
    }
  }
  captureException(err, {
    level,
    extra: params,
  });
}

export const debug = (msg: string, data?: dataObject) =>
  _log(console.debug, "debug", msg, data);
export const info = (msg: string, data?: dataObject) =>
  _log(console.info, "info", msg, data);
export const warn = (msg: string, data?: dataObject) =>
  _log(console.warn, "warning", msg, data);
export const error = (err: any, data?: dataObject) =>
  _log_error(console.error, "error", err, data);
export const fatal = (err: any, data?: dataObject) =>
  _log_error(console.error, "fatal", err, data);
