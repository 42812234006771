import Phaser from "phaser";
import { GameController } from "../controllers/gameController";
import {
  loadMainCharacterImages,
  loadEmployeeImages,
  loadEmoticonAndThoughtBubbleImages,
  loadMiscGameAssets,
  loadTillImages,
} from "../data/images";
import {
  loadAllShopProducts,
  loadShopLevelImages,
  loadCustomerImages,
} from "../data/images";
import { gameConfig } from "../config/gameConfig";
import { effect } from "@lit-labs/preact-signals";
import { gameState, isIntroComplete } from "../state/game-state";
import { appState, onGamePreloadComplete } from "../state/app-state";

interface Coord {
  x: number;
  y: number;
  kind: "pointer" | "touch";
}
const CoordFromPointerEvent = (e: PointerEvent): Coord => ({
  x: e.pageX,
  y: e.pageY,
  kind: "pointer",
});
const CoordFromTouchEvent = (e: TouchEvent): Coord => ({
  x: e.touches[0].pageX,
  y: e.touches[0].pageY,
  kind: "touch",
});

class GameScene extends Phaser.Scene {
  private readonly gameCtrl: GameController;
  private readonly onCreated: () => void;

  private unsubscribeFromSignal: () => void;

  private clicking = false;
  private startCoord: Coord;
  private startScroll: Coord;

  private defaultZoom: number;
  private currentZoom: number;

  constructor(ctrl: GameController, onCreated: () => void) {
    super();
    this.onCreated = onCreated;
    this.gameCtrl = ctrl;
  }

  adjustCameraZoomAndScroll = () => {
    const cam = this.cameras.main;
    cam.setPosition(0, 0);
    // Adding a 1-pixel safe zone, noticed a splash of green around the edges on scaled monitors
    cam.setBounds(
      1,
      1,
      gameConfig.backgroundWidth - 1,
      gameConfig.backgroundHeight - 1,
    );

    let zoomFactor = gameConfig.mobileZoom;

    const xScroll = gameConfig.backgroundWidth / 2 - cam.width / 2;
    let yScroll =
      gameConfig.backgroundHeight / 2 -
      cam.height / 2 -
      gameConfig.cameraOffsetMobile;

    // cam width and height values have the dpi added to them
    const canvasWidth = this.game.canvas.width / window.devicePixelRatio;
    if (canvasWidth > gameConfig.smlDeviceWidth) {
      zoomFactor = gameConfig.desktopZoom;

      yScroll =
        gameConfig.backgroundHeight / 2 -
        cam.height / 2 -
        gameConfig.cameraOffsetDeskop;
    }

    // We need to scale the zoom value by the difference in resolution between background
    // and viewport.
    const scaledHeightZoom =
      cam.height / (gameConfig.backgroundHeight / zoomFactor);
    const scaledWidthZoom =
      cam.width / (gameConfig.backgroundWidth / zoomFactor);

    // We use the smaller scale value so that you can never see beyond the canvas.
    this.defaultZoom = Math.max(scaledHeightZoom, scaledWidthZoom);

    // I think zoom values should reset when you resize. Otherwise you get that jumpy issue
    // after resizing when you zoom again and it clamps to the new max zoom value.
    cam.zoom = this.defaultZoom;
    this.currentZoom = cam.zoom;

    cam.setScroll(xScroll, yScroll);
  };

  init() {
    this.events.on(Phaser.Scenes.Events.CREATE, this.onCreated);

    this.adjustCameraZoomAndScroll();

    this.scale.on(Phaser.Scale.Events.RESIZE, this.adjustCameraZoomAndScroll);

    this.unsubscribeFromSignal = effect(() => {
      const isEventModalOpen =
        gameState.eventQueue.value.length && !gameState.displayLevelUp.value;
      const playGame =
        !gameState.displayFinancialReport.value &&
        !gameState.isPaused.value &&
        !isEventModalOpen &&
        !appState.tip.value &&
        appState.isHorizontal.value &&
        !appState.isPageHidden.value &&
        isIntroComplete();

      if (playGame) {
        this.scene.resume();
      } else {
        this.input.resetPointers();
        this.scene.pause();
      }

      if (gameState.displayLevelUp.value) {
        this.input.resetPointers();
      }
    });
  }

  preload() {
    loadMainCharacterImages(this);
    loadEmployeeImages(this);
    loadShopLevelImages(this);
    loadCustomerImages(this);
    loadAllShopProducts(this);
    loadEmoticonAndThoughtBubbleImages(this);
    loadMiscGameAssets(this);
    loadTillImages(this);
  }

  onDragStartPointer = (e: PointerEvent) =>
    this.onDragStart(CoordFromPointerEvent(e));
  onDragStartTouch = (e: TouchEvent) =>
    this.onDragStart(CoordFromTouchEvent(e));
  onDragStart = (coord: Coord) => {
    this.clicking = true;
    this.startCoord = coord;
    this.startScroll = {
      x: this.cameras.main.scrollX,
      y: this.cameras.main.scrollY,
      kind: this.startCoord.kind,
    };
  };
  onDraggingPointer = (e: PointerEvent) =>
    this.onDragging(CoordFromPointerEvent(e));
  onDraggingTouch = (e: TouchEvent) => this.onDragging(CoordFromTouchEvent(e));
  onDragging = (c: Coord) => {
    // Do nothing if the user is not busy dragging
    if (!this.clicking) {
      return;
    }
    // On some systems both the pointer and touch events trigger, this check ensures that we don't handle both
    if (c.kind != this.startCoord.kind) {
      return;
    }

    const cam = this.cameras.main;
    cam.scrollX =
      this.startScroll.x +
      ((this.startCoord.x - c.x) / cam.zoom) * window.devicePixelRatio;
    cam.scrollY =
      this.startScroll.y +
      ((this.startCoord.y - c.y) / cam.zoom) * window.devicePixelRatio;
  };

  onDragStop = () => {
    this.clicking = false;
    this.startCoord = null;
    this.startScroll = null;
  };

  create() {
    this.input.on(
      Phaser.Input.Events.POINTER_WHEEL,
      (p: Phaser.Input.Pointer) => {
        const cam = this.cameras.main;
        const newZoom = cam.zoom * (1 - p.deltaY / 1000);
        this.currentZoom =
          newZoom < this.defaultZoom ? this.defaultZoom : newZoom;
        cam.zoom = this.currentZoom;
      },
    );

    this.game.canvas.addEventListener("pointerdown", this.onDragStartPointer);
    this.game.canvas.addEventListener("pointermove", this.onDraggingPointer);
    window.addEventListener("pointerup", this.onDragStop);
    this.game.canvas.addEventListener("touchstart", this.onDragStartTouch);
    this.game.canvas.addEventListener("touchmove", this.onDraggingTouch);
    window.addEventListener("touchend", this.onDragStop);
    onGamePreloadComplete();
  }

  destroy() {
    this.scale.off(Phaser.Scale.Events.RESIZE, this.adjustCameraZoomAndScroll);
    this.game.canvas.removeEventListener(
      "pointerdown",
      this.onDragStartPointer,
    );
    this.game.canvas.removeEventListener("pointermove", this.onDraggingPointer);
    window.removeEventListener("pointerup", this.onDragStop);
    this.game.canvas.removeEventListener("touchstart", this.onDragStartTouch);
    this.game.canvas.removeEventListener("touchmove", this.onDraggingTouch);
    window.removeEventListener("touchend", this.onDragStop);
    this.unsubscribeFromSignal?.();
  }

  update(_: number, delta: number) {
    this.gameCtrl.tick(delta);
  }
}

export default GameScene;
