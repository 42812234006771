import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { when } from "lit/directives/when.js";
import { popIn } from "../juice/juice";
import { classMap } from "lit/directives/class-map.js";

@customElement("arrow-container")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ArrowContainer extends LitElement {
  static styles = [
    popIn,
    buttonStyle,
    css`
      .speech-bubble {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-white);
        display: flex;
        position: relative;
        flex-direction: column;
      }

      .header-color {
        width: 100%;
        height: 67px;
        background-color: var(--primary-teal-main);
        border-radius: 4px 4px 0px 0px;
        position: relative;
        top: 0px;
      }

      .header-color.small {
        height: 36px;
      }

      .header-color.events {
        @media (min-width: 1000px) {
          height: 56px;
        }
      }
    `,
  ];

  @property({ type: Boolean })
  isLeft: boolean;

  @property({ type: Boolean })
  smallGreen: boolean;

  @property({ type: Boolean })
  hasArrow: boolean;

  @property({ type: Boolean })
  eventsContainer: boolean;

  renderDialogueArrow = (isLeft: boolean) => html`
    <dialog-arrow direction=${isLeft ? "left" : "right"}></dialog-arrow>
  `;

  render() {
    return html`
      <div class="speech-bubble popIn">
        <div
          class=${classMap({
            "header-color": true,
            small: this.smallGreen,
            events: this.eventsContainer,
          })}
        >
          <slot></slot>
        </div>

        ${when(this.hasArrow, () => this.renderDialogueArrow(this.isLeft))}
      </div>
    `;
  }
}
