import "./registerSW";
import { css, html, nothing } from "lit";
import { state } from "lit/decorators.js";
import "./polyfills/polyfillsLoader"; /* start dynamically loading polyfills if they are needed */
import "./components/app-button.ts";
import "./components/hamburger-menu.ts";
import "./components/business-selector-carousel";
import "./components/choose-save";
import "./components/dialog-arrow";
import "./components/financial-report";
import "./components/game-container";
import "./components/game-over";
import "./components/gameplay-parameters";
import "./components/loading-screen";
import "./components/logged-out";
import "./components/logout-warning";
import "./components/main-menu";
import "./components/orientation-change";
import "./components/phaser-game";
import "./components/saving-indicator";
import "./components/sign-in-screen";
import "./components/tabbed-container";
import "./components/tip-button";
import "./components/tip-modal";
import "./components/confetti-juice";
import "./components/arrow-container";
import "./components/popup-close-button";
import {
  appState,
  isPreloaded,
  Screen,
  setScreen,
  setUser,
} from "./state/app-state";
import { initializeTranslations } from "./translations/translations";
import { LitElementI18n } from "./translations/lit-mixin";
import { provide } from "@lit/context";
import { gameContext } from "./gameContext";
import { GameController } from "./controllers/gameController";
import { GameModel } from "./models/gameModel";
import { GameplayParams } from "./config/gameplayParameters";
import { effect, SignalWatcher } from "@lit-labs/preact-signals";
import { when } from "lit/directives/when.js";
import { online } from "./helpers/connectivityHelper";
import { preloadHTMLImageData } from "./helpers/preloadHelper";
import { SecondaryPreloadImages, UIImages } from "./data/images";
import { PortalAPI } from "./portal/api";
import { getLocalUser, logInUser } from "./user";
import { refreshHighScore, saveManager } from "./state/save-state";
import { isDevEnv } from "./env";
import { initializeSentry } from "./analytics/sentry";
import { LoginSuccessEvent } from "./analytics/analytics";
import { toLeaderboard } from "./redirects";

class App extends SignalWatcher(LitElementI18n) {
  static styles = css`
    :host {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #ffeeee;
      position: relative;
    }

    .orientation-ui {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-index-orientation);
    }

    .ui {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-index-ui);
    }
    game-container.ui {
      z-index: var(--z-index-game);
    }

    .offline-tag {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: var(--semi-opaque-red);
      display: inline-block;
      z-index: 15;
    }

    .testing-ui {
      position: absolute;
      bottom: 0;
      right: 50%;
      background-color: var(--semi-opaque-red);
      display: inline-block;
      z-index: 15;
    }

    .menu-ui {
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-index-hamburger-menu);

      padding-left: 8px;
      padding-top: 8px;

      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  `;

  constructor() {
    super();
    initializeTranslations();
    initializeSentry();
    this.initializeGameController();
    preloadHTMLImageData(SecondaryPreloadImages);
    this.setupSavingDebounce();
    this.fetchUserAndGameData();

    /* Prevents double clicks from zooming in when clicking a button on iOS */
    document.addEventListener("dblclick", (event) => {
      event.preventDefault();
    });
  }

  fetchUserAndGameData = async () => {
    // Fetch the online user info
    try {
      const user = await PortalAPI.getUser();
      logInUser(user);
      if (user) {
        LoginSuccessEvent();
      }
    } catch {
      const localUser = getLocalUser();
      setUser(localUser);
      if (localUser === null) {
        // if we don't have online or local user, don't try to fetch saves
        return;
      }
    }

    await refreshHighScore();

    // Try fetching save data and then redirect to the main menu
    await saveManager.refreshLocalSave();
    await saveManager.refreshCloudSave();
    setScreen(Screen.MainMenu);
  };

  @state()
  savingDebounce: boolean = false;
  // Respond the saving signal changing to true and set the savingDebounce to
  // true for a short while. This is used to display the saving indicator and
  // prevents it from not being displayed to the user long enough to see it.
  private setupSavingDebounce = () => {
    let saveTimeout: NodeJS.Timeout;
    effect(() => {
      if (appState.saving.value) {
        this.savingDebounce = true;
        clearTimeout(saveTimeout);
        saveTimeout = setTimeout(() => {
          this.savingDebounce = false;
        }, 500); // show the saving indicator for at least 500ms
      }
    });
  };

  @provide({ context: gameContext })
  game: GameController;
  @state()
  isGameControllerLoaded: boolean = false;

  testingMessage: string;

  private initializeGameController = async () => {
    this.game = new GameController(GameplayParams, new GameModel());
    this.isGameControllerLoaded = true;
  };

  connectedCallback(): void {
    super.connectedCallback();

    appState.user.subscribe((user) => {
      if (user === null) {
        setScreen(Screen.LoggedOutScreen);
      } else if (appState.screen.value === Screen.LoggedOutScreen) {
        // go to the main menu if the user has logged in while on the logged out screen
        setScreen(Screen.MainMenu);
      }
    });
  }

  renderCurrentScreen = () => {
    switch (appState.screen.value) {
      case Screen.Game:
        return nothing;
      case Screen.MainMenu:
        return html`<main-menu class="ui"></main-menu>`;
      case Screen.SignIn:
        return html`<sign-in-screen class="ui"></sign-in-screen>`;
      case Screen.GameOver:
        return html`<game-over class="ui"></game-over>`;
      case Screen.ChooseSave:
        return html`<choose-save class="ui"></choose-save>`;
      case Screen.LogoutWarningScreen:
        return html`<logout-warning class="ui"></logout-warning>`;
      case Screen.LoggedOutScreen:
        return html`<logged-out class="ui"></logged-out>`;
      default:
        return html`<loading-screen class="ui"></loading-screen>`;
    }
  };

  renderLoadingScreen = () =>
    html`<loading-screen class="ui"></loading-screen>`;

  render() {
    if (!this.isGameControllerLoaded) {
      return html` <div>Loading...</div> `;
    }

    const isLoaded = isPreloaded() || appState.screen.value === Screen.Loading;
    const showHamburgerMenu =
      appState.screen.value === Screen.MainMenu ||
      appState.screen.value === Screen.ChooseSave ||
      appState.screen.value === Screen.Game;

    return html`
      ${when(
        !appState.isHorizontal.value,
        () =>
          html`<orientation-change
            class="orientation-ui"
          ></orientation-change>`,
      )}
      ${when(!online.value, () => html`<div class="offline-tag">Offline</div>`)}
      ${when(
        isDevEnv && this.testingMessage,
        () => html`<div class="testing-ui">${this.testingMessage}</div>`,
      )}

      <div>
        ${when(isLoaded, this.renderCurrentScreen, this.renderLoadingScreen)}
        <game-container class="ui"></game-container>
        ${when(
          appState.saving.value || this.savingDebounce,
          () => html`<saving-indicator></saving-indicator>`,
        )}
      </div>
      <div class="menu-ui">
        ${when(
          isLoaded && showHamburgerMenu,
          () => html`<hamburger-menu></hamburger-menu>`,
        )}
        ${when(
          isLoaded && appState.screen.value === Screen.MainMenu,
          () =>
            html`<app-button
              imgURL=${UIImages.Menu_Leaderboard}
              @click=${toLeaderboard}
            >
            </app-button>`,
        )}
      </div>
    `;
  }
}

// define custom element
customElements.define("app-main", App);
