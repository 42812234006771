import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { TipTabKeys } from "../data/tips";
import { setActiveTipKey } from "../state/app-state";
import { removeGenericBtnStyles } from "../styles/theme";
import { UIImages } from "../data/images";

@customElement("tip-button")
export class TipButton extends LitElementI18n {
  static styles = [
    removeGenericBtnStyles,
    css`
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button {
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    `,
  ];

  @property({ attribute: "key" })
  tipModalKey: TipTabKeys;

  onTipButtonClicked = () => {
    setActiveTipKey(this.tipModalKey);
  };

  render = () => html`
    <button class="remove-btn-style" @click=${this.onTipButtonClicked}>
      <img src=${UIImages.Report_Info} draggable="false" />
    </button>
  `;
}
