import { Howl } from "howler";
import { MusicData, MusicKey, Sound, SoundData, SoundKey } from "../data/sound";
import { effect } from "@lit-labs/preact-signals";
import { appState, Screen } from "../state/app-state";

const soundMap: Record<SoundKey, { play(): void }> = {
  ButtonSelect_1: createSoundEffect(SoundData.ButtonSelect_1),
  ButtonSelect_2: createSoundEffect(SoundData.ButtonSelect_2),
  ButtonSelect_3: createSoundEffect(SoundData.ButtonSelect_3),
  Error: createSoundEffect(SoundData.Error),
  EmailNotification: createSoundEffect(SoundData.EmailNotification),
  EmailOpen: createSoundEffect(SoundData.EmailOpen),
  Swipe: createSoundEffect(SoundData.Swipe),
  Success: createSoundEffect(SoundData.Success),
  PopIn: createSoundEffect(SoundData.PopIn),
  PopOut: createSoundEffect(SoundData.PopOut),
  Purchase: createSoundEffect(SoundData.Purchase),
  Sale: createSoundEffect(SoundData.Sale),
  OrderTaken: createSoundEffect(SoundData.OrderTaken),
  Report: createSoundEffect(SoundData.Report),
};

const musicMap: Record<MusicKey, { play(): void; stop(): void }> = {
  MenuMusic: createMusicTrack(MusicData.MenuMusic),
  GameMusic: createMusicTrack(MusicData.GameMusic),
};

effect(() => {
  Howler.mute(appState.isMuted.value || appState.isPageHidden.value);
});

function createSoundEffect(data: Sound) {
  return {
    howl: createHowl(data),
    play() {
      this.howl.play();
    },
  };
}

const playSoundEffect = (key: SoundKey): void => {
  soundMap[key].play();
};

function createHowl(sound: Sound): Howl {
  return new Howl({
    ...sound,
  });
}

function createMusicTrack(data: Sound) {
  return {
    howl: createHowl(data),
    play() {
      this.howl.play();
    },
    stop() {
      this.howl.stop();
    },
  };
}

let musicTrack: { play(): void; stop(): void };

const playMusic = (key: MusicKey) => {
  // Don't restart existing tracks
  if (musicMap[key] === musicTrack) return;

  // Stop the previous track playing a new one
  if (musicTrack) {
    musicTrack.stop();
  }
  musicTrack = musicMap[key];
  musicTrack.play();
};

effect(() => {
  switch (appState.screen.value) {
    case Screen.Loading:
      return;
    case Screen.Game:
      playMusic("GameMusic");
      break;
    default:
      playMusic("MenuMusic");
      return;
  }
});

export { playSoundEffect };
