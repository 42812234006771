import { css } from "lit";
export const popIn = css`
  /*This animation is for all the in-game UI containers */
  @keyframes popInAnim {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  /*This animation is for the score container in the game-over screen */
  @keyframes popInAnimGameOver {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(0);
    }
    70% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  /*This animation is for the in-game and UI layer buttons*/
  @keyframes popInAnimButtons {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  /*This animation is for UI intro dialogue buttons as a translation Y has been applied to its styling*/
  @keyframes popInAnimIntroDialogue {
    0% {
      transform: translate(0%, 0%) scale(0);
    }
    50% {
      transform: translate(0%, 0%) scale(0);
    }
    100% {
      transform: translate(0%, 50%) scale(1);
    }
  }

  .popIn {
    animation-name: popInAnim;
    animation-duration: 0.25s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.55, 1.35);
    animation-fill-mode: none;
  }

  .popIn.levelUp {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: none;
  }

  .popIn.game-button {
    animation-name: popInAnimButtons;
    animation-duration: 0.35s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.55, 1.35);
    animation-fill-mode: none;
  }

  .popIn.ui-button {
    animation-name: popInAnimButtons;
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.55, 1.35);
    animation-fill-mode: none;
  }

  .popIn.ui-button-dialogue {
    animation-name: popInAnimIntroDialogue;
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.55, 1.35);
    animation-fill-mode: none;
  }
`;
