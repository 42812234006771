import { html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { translate } from "../translations/translations";
import { closeDialog } from "../state/app-state";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIImages } from "../data/images";
import { playSoundEffect } from "../sound/sound-manager";
import "./arrow-container";
import "./popup-close-button";
import { getProductImage } from "../helpers/productImageHelper";
import { popIn } from "../juice/juice";

@customElement("add-product-dialog")
export class AddProductDialog extends LitElementI18n {
  static styles = [
    popIn,
    css`
      arrow-container {
        width: fit-content;
        height: 141px;
        position: absolute;
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .header {
        display: flex;
        width: 100%;
        height: 36px;
        justify-content: space-between;
      }

      .header-text {
        padding: 6px;
      }

      .product-body {
        width: fit-content;
        display: flex;
        flex-direction: row;
        padding: 14px 18px 15px;
        justify-content: center;
      }

      .product-info {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        flex-direction: column;
        width: fit-content;
      }

      .line-wrapper {
        display: flex;
        justify-content: space-between;
        min-width: 130px;
        padding-bottom: 1px;
        overflow-wrap: normal;
      }

      app-button {
        align-items: center;
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
      }

      .line-layout {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: flex-start;
        flex-shrink: 0;
      }

      .line-start {
        width: 81px;
        padding-right: 16px;
        flex-shrink: 0;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  onAccept = () => {
    this.game.launchProduct();
    playSoundEffect("Purchase");
    closeDialog();
  };

  protected firstUpdated(): void {
    playSoundEffect("PopIn");
  }

  render() {
    const idx = this.game.getNextAvailableProductIdx();
    const businessType = this.game.getModel().getShop().getBusinessType();

    const productInfo = this.game.gameplayParams.products.find(
      (group) => group.category === businessType,
    ).products[idx];

    const isBtnDisabled =
      this.game.getModel().getGold() <
      this.game.getModel().getShop().nextProductCost;

    return html`
      <arrow-container smallGreen>
        <div class="header">
          <div class="header-text">${translate("Product_LaunchProduct")}</div>
          <popup-close-button></popup-close-button>
        </div>
        <div class="product-body">
          <img
            src=${getProductImage(idx, businessType)}
            height="48px"
            style="padding-right: 14px;"
            draggable="false"
          />

          <div class="product-info">
            <div class="line-wrapper">
              <div class="line-start">${translate("Product_Max")}</div>
              <div class="line-layout">x${productInfo.stockLimit}</div>
            </div>
            <div class="line-wrapper">
              <div class="line-start">${translate("Product_Cost")}</div>
              <div class="line-layout">
                <img
                  height="16px"
                  src=${UIImages.CoinLine}
                  draggable="false"
                />${productInfo.cost}
              </div>
            </div>
            <div class="line-wrapper">
              <div class="line-start">${translate("Product_SellingPrice")}</div>
              <div class="line-layout">
                <img
                  height="16px"
                  src=${UIImages.CoinLine}
                  draggable="false"
                />${productInfo.cost + 1}-${productInfo.maxCost}
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper">
          <app-button
            class="popIn game-button"
            @click=${() => this.onAccept()}
            ?disabled=${isBtnDisabled}
          >
            <img
              src=${UIImages.CoinColor}
              height="28"
              style=${isBtnDisabled ? "opacity: 0.3" : ""}
            />
            ${this.game.getModel().getShop().nextProductCost ?? ""}
          </app-button>
        </div>
      </arrow-container>
    `;
  }
}
