import { HTMLTemplateResult, css, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Screen, setScreen } from "../state/app-state";
import { buttonStyle } from "../styles/theme";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { translate } from "../translations/translations";
import { LitElementI18n } from "../translations/lit-mixin";
import { when } from "lit/directives/when.js";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import { refreshHighScore, saveManager, saveState } from "../state/save-state";
import { installApp } from "../install";
import { RequestStatus, apiState } from "../state/api-state";
import { classMap } from "lit/directives/class-map.js";
import { PortalAPI } from "../portal/api";

@customElement("main-menu")
export class MainMenu extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background: url("assets/tiles/BG_pattern_transparent.png") repeat 0 0;
        background-size: 500px 500px;
        background-color: var(--secondary-violet-highlight);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        background-color: var(--semi-opaque-white);
      }

      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        @media (min-width: 1000px) {
          gap: 32px;
        }
      }

      .install-prompt {
        display: flex;
        flex-direction: column;
        place-items: center;
        position: absolute;
        bottom: 28px;
        right: 18px;
      }

      .logo {
        width: 180px;
        height: 76px;

        @media (min-width: 1000px) {
          width: 276px;
          height: 118px;
        }
      }

      .score-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hidden {
        display: none !important;
      }

      .score {
        width: 252px;
        height: 84px;
        box-sizing: border-box;
        padding: 12px 2px 2px 2px;
        fill: var(--seconday-violet-lowlight);
        stroke-width: 2px;
        stroke: var(--primary-navy);
        position: relative;
        background-size: 100% 100%;

        @media (min-width: 1000px) {
          width: 500px;
          height: 166px;
          padding-top: 32px;
        }
      }

      .score-text-container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      .score-text {
        color: var(--primary-white);
        text-align: center;
        font-family: Ubuntu;
        font-style: normal;
        line-height: normal;
        margin: 0;
      }
      p.score-text {
        font-size: 14px;
        font-weight: 500;

        @media (min-width: 1000px) {
          font-size: 24px;
          padding-bottom: 13px;
        }
      }
      h1.score-text {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.144px;

        @media (min-width: 1000px) {
          font-size: 36px;
        }
      }

      .score-submit {
        position: absolute;
        bottom: 0;
        margin: 0;
        transform: translateY(50%);
      }

      /* TODO - We should refactor this into the secondary app button at some point */
      .secondary-button {
        display: inline-flex;

        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 10px;

        height: 40px;

        border-radius: 59px;
        border: 2px solid var(--primary-teal-main);
        background-color: var(--primary-teal-highlight);

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .secondary-button:active {
        border: 2px solid var(--primary-teal-lowlight);
        background: var(--primary-teal-main);
      }

      .secondary-button:disabled {
        border: 2px solid var(--button-disabled-lowlight);
        background: var(--button-disabled-primary);
      }

      .secondary-button > * {
        flex-shrink: 1;
      }

      .install-tip {
        position: relative;
        width: 149px;
        background-color: var(--primary-white);
        box-sizing: border-box;
        padding: 9px;
        border-radius: 4px;
        margin-bottom: 12px;

        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (min-width: 1000px) {
          width: 268px;
          margin-bottom: 16px;
          font-size: 24px;
          margin-bottom: 24px;
        }
      }
    `,
  ];

  @state()
  displayDevUI: boolean;

  @consume({ context: gameContext })
  game: GameController;

  submitScoreToLeaderboard = async () => {
    if (saveManager.localSave()) {
      await PortalAPI.saveGame(saveManager.localSave());
      await refreshHighScore();
    }
  };

  renderDownloadButton = () => html`
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6H1L1 18L19 18L19 6H15.5"
        stroke="var(--primary-navy)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 11L10 1"
        stroke="var(--primary-navy)"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M10 13L13 10"
        stroke="var(--primary-navy)"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M10 13L7 10"
        stroke="var(--primary-navy)"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  `;

  renderSubmitButton = (
    canSubmit: boolean,
    isSubmitting: boolean,
    hasFailed: boolean,
  ) => {
    let content: HTMLTemplateResult | string;
    if (hasFailed) {
      content = translate("GameEnd_Button_TryAgain");
    } else if (isSubmitting) {
      content = html`<loading-spinner></loading-spinner>`;
    } else content = translate("HomeScreen_Submit");
    return html`
      <button
        class="score-submit secondary-button"
        @click=${this.submitScoreToLeaderboard}
        ?disabled=${isSubmitting}
        style="display: ${canSubmit ? "block" : "none"}"
      >
        ${content}
      </button>
    `;
  };

  render() {
    const highScore = saveState.highScore.value;
    const localSave = saveManager.localSave();
    const canSubmit = localSave && localSave.score > highScore;
    const isSubmitting =
      apiState.saveGameRequest.value.status === RequestStatus.InProgress;
    const hasFailed =
      apiState.saveGameRequest.value.status === RequestStatus.Failed;
    return html`
      <div class="container">
        <div class="main">
          <img class="logo" src=${UIImages.LogoHome} draggable="false" />
          <div
            class=${classMap({
              "score-container": true,
              hidden: !highScore,
            })}
            style="margin-bottom: ${canSubmit ? "16px" : "0"};"
          >
            <div
              class="score"
              style="background-image: url(${UIImages.ScoreContainer});"
            >
              <div class="score-text-container">
                <p class="score-text">${translate("HomeScreen_HighScore")}</p>
                <h1 class="score-text">${highScore}</h1>
              </div>
            </div>
            ${this.renderSubmitButton(canSubmit, isSubmitting, hasFailed)}
          </div>

          <app-button
            @click=${() => setScreen(Screen.ChooseSave)}
            text=${translate("HomeScreen_Start")}
          >
          </app-button>
        </div>
        ${when(
          window.installPrompt,
          () => html`
            <div class="install-prompt">
              <div class="install-tip">
                ${translate("HomeScreen_InstallTip")}
                <dialog-arrow direction="bottom" no-border></dialog-arrow>
              </div>
              <button class="secondary-button" @click=${installApp}>
                ${this.renderDownloadButton()}
                ${translate("HomeScreen_InstallButton")}
              </button>
            </div>
          `,
        )}
      </div>
    `;
  }
}
