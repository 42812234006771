import { CopyKey } from "../translations/keys";
import { tipImages } from "./images";

interface TipTabInfo {
  title: CopyKey;
  body: CopyKey[];
  image: string;
}

export declare type TipTabKeys =
  | "Tip_MissedSalesNoStaffAvailable"
  | "Tip_MissedSalesPriceTooHigh"
  | "Tip_MissedSaleLackOfMarketing"
  | "Tip_MissedSaleOutofStock"
  | "Tip_ProfitOrLoss"
  | "Tip_Expenses"
  | "Tip_Revenue"
  | "Tip_CommunityImpact"
  | "Tip_Stock"
  | "Tip_Cash";

export const TipsTab: Record<TipTabKeys, TipTabInfo> = {
  Tip_MissedSalesNoStaffAvailable: {
    title: "Tip_NoStaff_Heading",
    body: ["Tip_NoStaff_Body"],
    image: tipImages.noStaff,
  },
  Tip_MissedSalesPriceTooHigh: {
    title: "Tip_HighPrice_Heading",
    body: ["Tip_HighPrice_Body01", "Tip_HighPrice_Body02"],
    image: tipImages.priceTooHigh,
  },
  Tip_MissedSaleLackOfMarketing: {
    title: "Tip_NoMarketing_Heading",
    body: ["Tip_NoMarketing_Body"],
    image: tipImages.lackOfMarketing,
  },
  Tip_MissedSaleOutofStock: {
    title: "Tip_OutOfStock_Heading",
    body: ["Tip_OutOfStock_Body"],
    image: tipImages.noStock,
  },
  Tip_ProfitOrLoss: {
    title: "Tip_ProfitOrLoss_Heading",
    body: ["Tip_ProfitOrLoss_Body"],
    image: tipImages.phone,
  },
  Tip_Expenses: {
    title: "Tip_Expenses_Heading",
    body: ["Tip_Expenses_Body"],
    image: tipImages.phone,
  },
  Tip_Revenue: {
    title: "Tip_Revenue_Heading",
    body: ["Tip_Revenue_Body"],
    image: tipImages.phone,
  },
  Tip_CommunityImpact: {
    title: "Tip_CommunityImpact_Heading",
    body: ["Tip_CommunityImpact_Body01", "Tip_CommunityImpact_Body02"],
    image: tipImages.phone,
  },
  Tip_Stock: {
    title: "Tip_Stock_Heading",
    body: ["Tip_Stock_Body"],
    image: tipImages.stock,
  },
  Tip_Cash: {
    title: "Tip_Cash_Heading",
    body: ["Tip_Cash_Body"],
    image: tipImages.cash,
  },
};
