import { AppClient, SaveUserGameData } from "./api-gen";
import { IGameSave, IPortalAPI, IUserData } from "./type";
import { sha256 } from "../hash";

const gameSlug: string = "agec-high-school-2024";

export const createSaveUserGameData = async (
  score: number,
  data: string,
  completed: boolean,
): Promise<SaveUserGameData> => {
  const hash = await sha256(
    `${data}${score}${completed}gf024mf239fj092jmff916nf1g`,
  );
  return { score, data, completed, hash };
};

export class PortalAPIProd implements IPortalAPI {
  private client: AppClient;

  constructor(baseURL: string) {
    this.client = new AppClient({ BASE: baseURL });
  }

  getUser = async (): Promise<IUserData> => {
    const resp = await this.client.gameData.getCurrentUser();
    return {
      displayName:
        resp.firstName && resp.lastName
          ? `${resp.firstName} ${resp.lastName}`
          : resp.firstName
          ? resp.firstName
          : resp.lastName
          ? resp.lastName
          : null,
      userId: resp.userId,
      email: resp.email,
    };
  };

  getUserHighScore = async (): Promise<number> => {
    const resp = await this.client.gameData.getUserHighScore(gameSlug);
    return resp.score;
  };

  // Download game data from the backend
  loadGame = async (): Promise<IGameSave> => {
    const resp = await this.client.gameData.getUserGameData(gameSlug);
    return {
      score: resp.score,
      data: JSON.parse(resp.data),
      completed: resp.completed,
    };
  };

  // Upload game data to the backend
  saveGame = async (game: IGameSave): Promise<void> => {
    const data = await createSaveUserGameData(
      game.score,
      JSON.stringify(game.data),
      game.completed,
    );
    await this.client.gameData.saveUserGameData(gameSlug, data);
  };

  loginURL = () => `${window.location.origin}/login`;

  leaderBoardURL = () =>
    `${window.location.origin}/leaderboard?gameSlug=${gameSlug}`;
}
