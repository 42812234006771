import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { UIImages } from "../data/images";
import { removeGenericBtnStyles } from "../styles/theme";
import { closeDialog } from "../state/app-state";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("close-button-fin-report")
export class CloseButtonFinReport extends LitElement {
  static styles = [
    removeGenericBtnStyles,
    css`
      button {
        padding: 0;
        width: 22px;
        height: 22px;
      }
    `,
  ];

  render() {
    return html`
      <button class="remove-btn-style" @click=${closeDialog}>
        <img
          src=${UIImages.Cross_Active}
          height="22px"
          width="22px"
          draggable="false"
        />
      </button>
    `;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    playSoundEffect("PopOut");
  }
}
