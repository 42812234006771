import {
  BottomLeftProductIndex,
  mapData,
  BottomRightProductIndex,
  sideLeftProductIndex,
  sideRightProductIndex,
} from "../controllers/mapData";
import { SpriteDirection } from "../types/spriteDirection";

export const customerDirectionHelper = (
  prevX: number,
  prevY: number,
  currentX: number,
  currentY: number,
): SpriteDirection => {
  if (
    (prevX === currentX && prevY < currentY) ||
    (prevX === currentX && prevY === currentY)
  ) {
    // moving down
    return SpriteDirection.Front;
  } else if (prevX === currentX && prevY > currentY) {
    // moving up
    return SpriteDirection.Back;
  } else if (prevY === currentY && prevX < currentX) {
    return SpriteDirection.Right;
  } else {
    return SpriteDirection.Left;
  }
};

export const employeeDirectionHelper = (
  prevX: number,
  prevY: number,
  currentX: number,
  currentY: number,
): SpriteDirection => {
  if (prevX === currentX && prevY < currentY) {
    // moving down
    return SpriteDirection.Front;
  } else if (prevX === currentX && prevY > currentY) {
    // moving up
    return SpriteDirection.Back;
  } else if (prevY === currentY && prevX < currentX) {
    // moving right
    return SpriteDirection.Right;
  } else if (prevY === currentY && prevX > currentX) {
    //moving left
    return SpriteDirection.Left;
  } else {
    if (
      currentY > mapData.products[sideLeftProductIndex].y ||
      currentY > mapData.products[sideRightProductIndex].y
    ) {
      // is at bottom product row
      return SpriteDirection.Front;
    } else if (currentX < mapData.products[BottomLeftProductIndex].x) {
      // getting product left side
      return SpriteDirection.Left;
    } else if (currentX > mapData.products[BottomRightProductIndex].x) {
      // getting product right side
      return SpriteDirection.Right;
    } else {
      return SpriteDirection.Back;
    }
  }
};
