import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import { blockPointerEvents } from "../styles/theme";
import { popIn } from "../juice/juice";

@customElement("level-up")
export class LevelUp extends SignalWatcher(LitElement) {
  static styles = [
    blockPointerEvents,
    popIn,
    css`
      :host {
        display: flex;
        place-items: center;
        width: 100%;
        height: 100%;
        display: grid;
      }

      confetti-juice {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        margin: 0;
      }

      .level-up-container {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .level-up-background {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: var(--level-up-bg);
        opacity: 0.3;
      }

      .banner {
        position: absolute;
        opacity: 1 !important;
      }
      .rays {
        position: relative;
        flex-shrink: 0;
      }
    `,
  ];

  render() {
    return html`
      <div class="level-up-container block-pointers">
        <div class="level-up-background">
          <img class="rays" src=${UIImages.LevelUpRays} draggable="false" />
        </div>
        <img
          class="banner popIn levelUp"
          src=${UIImages.LevelUpBanner}
          draggable="false"
        />
      </div>
      <confetti-juice autoplay></confetti-juice>
    `;
  }
}
