import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages, TillKeys } from "../data/images";
import { PointerJuice } from "./pointer-juice";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min.js";

export class HireEmployeeButton extends Phaser.GameObjects.Container {
  private button: Phaser.GameObjects.Image;
  private upgradePip: Phaser.GameObjects.Image;
  private pointer: PointerJuice;
  private juice: phaserJuice;

  constructor(scene: Phaser.Scene) {
    super(scene);
    this.juice = new phaserJuice(this.scene);

    this.button = this.scene.add.image(
      0,
      0,
      GameImages.TillSheet,
      TillKeys.HireEmployeeButton,
    );
    this.add(this.button);
    this.setDepth(GameObjectDepth.Product);

    this.upgradePip = this.scene.add.image(
      this.button.getTopRight().x,
      this.button.getTopRight().y,
      GameImages.DemandAndUpgradeAlert,
    );
    this.add(this.upgradePip);
    this.wiggle();

    // setup click event
    this.button.setInteractive({
      useHandCursor: true,
    });
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }

  playPointerJuice = () => {
    this.pointer = new PointerJuice(this.scene);

    this.pointer.pointer.setPosition(
      this.button.getCenter().x + this.pointer.pointer.width,
      this.button.getBottomRight().y + this.pointer.pointer.height / 2,
    );

    this.add(this.pointer);

    this.pointer.playJuice();
  };

  destroyPointer = () => {
    this.pointer?.destroy();
  };

  setUpgradePipVisibility = (visible: boolean) => {
    if (this.upgradePip.visible !== visible) {
      this.upgradePip.setVisible(visible);
    }
  };

  private onPointerDown = (
    pointer: Phaser.Input.Pointer,
    currentlyOver: Phaser.GameObjects.GameObject[],
  ) => {
    if (currentlyOver.includes(this.button)) {
      this.emit("click", pointer.worldX, pointer.worldY);
    }
  };

  destroy(fromScene?: boolean): void {
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
    this.destroyPointer();
    super.destroy(fromScene);
  }

  private wiggle() {
    this.juice.rotate(this.upgradePip, {
      target: this.upgradePip,
      x: 0,
      y: 0,
      repeat: -1,
      yoyo: true,
      angle: [-15, 15],
      ease: "Circular.easeInOut",
      duration: 300,
    });
  }
}
