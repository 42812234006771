import { html, css, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { TipsTab } from "../data/tips";
import { playSoundEffect } from "../sound/sound-manager";
import { translate } from "../translations/translations";
import { appState, setActiveTipKey } from "../state/app-state";
import { popIn } from "../juice/juice";
import { TipOpenEvent } from "../analytics/analytics";

@customElement("tip-modal")
export class TipModal extends LitElementI18n {
  static styles = [
    popIn,
    css`
      :host {
        display: flex;
        font-family: Ubuntu;
        color: var(--primary-navy);
        font-style: normal;
        line-height: normal;
      }

      p {
        margin-block-start: 0px;
      }

      .header {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .header-text {
        display: flex;
        padding: 6px 6px 7px;
        font-size: 20px;
        font-weight: 700;
      }

      .content {
        width: 100%;
        height: 214px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        gap: 14px;
        padding: 12px 14px 8px;
      }

      .left-container {
        display: flex;
        gap: 8px;
        flex-direction: column;
      }

      .img-container {
        display: flex;
        width: 204px;
        height: 108px;
      }

      .right-container {
        display: flex;
        flex-direction: column;
      }

      .tip-text {
        font-size: 14px;
        font-weight: 400;
      }

      .btn-container {
        display: flex;
        justify-content: center;
        transform: translate(0, -50%);
      }

      .img-size {
        width: 204px;
        height: 108px;
      }

      arrow-container {
        width: 476px;
        height: 254px;
      }
    `,
  ];

  dismissButton = (e: InputEvent) => {
    e.preventDefault();
    this.closeModal();
  };

  closeModal = () => {
    playSoundEffect("PopOut");
    setActiveTipKey(null);
  };

  firstUpdated() {
    playSoundEffect("PopIn");
    if (appState.tip.value) {
      TipOpenEvent(appState.tip.value);
    }
  }

  render() {
    if (!appState.tip.value) {
      return nothing;
    }
    const data = TipsTab[appState.tip.value];
    return html`
      <arrow-container smallGreen>
        <div class="header">
          <div class="header-text">${translate(data.title)}</div>
          <popup-close-button @click=${this.closeModal}></popup-close-button>
        </div>
        <div class="content">
          <div class="left-container">
            <div class="img-container">
              <img class="img-size" src=${data.image} draggable="false" />
            </div>
          </div>
          <div class="right-container">
            <div class="tip-text">
              ${data.body.map((b) => html`<p>${translate(b)}</p>`)}
            </div>
          </div>
        </div>
        <div class="btn-container">
          <app-button
            class="popIn game-button"
            text=${translate("Tip_Button_Dismiss")}
            @click=${this.closeModal}
          ></app-button>
        </div>
      </arrow-container>
    `;
  }
}
