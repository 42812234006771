import { PortalAPIFirebase } from "./dev";
import { IGameSave, IPortalAPI, IUserData } from "./type";
import { usingFirebaseBackend, usingLocalBackend } from "../env";
import { info } from "../helpers/loggerHelper";
import { requestActions } from "../state/api-state";
import { PortalAPIProd } from "./prod";
import { saveManager } from "../state/save-state";

class PortalAPIStateWrapper implements IPortalAPI {
  api: IPortalAPI;

  constructor() {
    if (usingLocalBackend) {
      info("Using Local API");
      this.api = new PortalAPIProd("http://localhost:8080");
    } else if (usingFirebaseBackend) {
      info("Using Firebase (development) API");
      this.api = new PortalAPIFirebase();
    } else {
      this.api = new PortalAPIProd(window.location.origin);
    }
  }

  getUser = async (): Promise<IUserData> => {
    requestActions.getUser.start();
    try {
      const user = await this.api.getUser();
      requestActions.getUser.success(user);
      return user;
    } catch (e) {
      requestActions.getUser.failed(e.toString());
      throw e;
    }
  };

  getUserHighScore = async (): Promise<number> => {
    requestActions.getUserHighScore.start();
    try {
      const score = await this.api.getUserHighScore();
      requestActions.getUserHighScore.success(score);
      return score;
    } catch (e) {
      requestActions.loadGame.failed(e.toString());
      throw e;
    }
  };

  loadGame = async (): Promise<IGameSave> => {
    requestActions.loadGame.start();
    try {
      const game = await this.api.loadGame();
      requestActions.loadGame.success(game);
      return game;
    } catch (e) {
      requestActions.loadGame.failed(e.toString());
      throw e;
    }
  };

  saveGame = async (game: IGameSave): Promise<void> => {
    requestActions.saveGame.start();
    try {
      await this.api.saveGame(game);
      requestActions.saveGame.success();
      saveManager.onCloudSaveSubmission(game);
    } catch (e) {
      requestActions.saveGame.failed(e.toString());
      throw e;
    }
  };

  loginURL = () => this.api.loginURL();

  leaderBoardURL = () => this.api.leaderBoardURL();
}

export const PortalAPI = new PortalAPIStateWrapper();
