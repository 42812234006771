import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { borderStyles } from "../styles/theme";
import { playSoundEffect } from "../sound/sound-manager";
import { classMap } from "lit/directives/class-map.js";

@customElement("app-button")
export class AppButton extends LitElement {
  static styles = [
    borderStyles,
    css`
      button {
        background: var(--primary-teal-main);
        padding: var(--app-btn-padding, 8px 10px 12px);
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0px -4px 0px 0px var(--primary-lowlight) inset;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        color: var(--primary-navy);
        margin: 0px;

        cursor: pointer;
      }

      button.minimal-padding {
        padding: 2px 4px 0px;
      }

      button.minimal-padding:active {
        padding: 0px 4px 0px;
        background: var(--primary-teal-main);
        box-shadow: none;
      }
      button.minimal-padding:disabled {
        padding: 0px 4px 0px;
        background: var(--button-disabled-primary);
        border: 2px solid var(--button-disabled-lowlight);
        box-shadow: 0px -4px 0px 0px var(--button-disabled-lowlight) inset;
        color: var(--button-disabled-lowlight);
      }

      button:active {
        margin-top: 4px;
        padding: var(--app-btn-padding-active, 8px 10px);
        background: var(--primary-teal-main);
        box-shadow: none;
      }

      button:disabled {
        background: var(--button-disabled-primary);
        border: 2px solid var(--button-disabled-lowlight);
        box-shadow: 0px -4px 0px 0px var(--button-disabled-lowlight) inset;
        color: var(--button-disabled-lowlight);
      }

      button > * {
        flex-shrink: 1;
      }

      button.shorter-height {
        height: 44px;
      }
    `,
  ];

  @property({ type: Boolean })
  disabled: boolean;
  @property({ type: Boolean })
  hamburgerMenu: boolean = false;
  @property({ type: Boolean, attribute: "minimal-padding" })
  minimalPadding = false;
  @property({ type: Boolean, attribute: "shorter-height" })
  shorterHeight = false;
  @property({ type: String })
  text?: string;
  @property({ type: String })
  imgURL?: string;

  onClick = () => {
    this.hamburgerMenu
      ? playSoundEffect("ButtonSelect_1")
      : playSoundEffect("ButtonSelect_3");
  };

  render() {
    return html`
      <button
        ?disabled=${this.disabled}
        @click=${this.onClick}
        class=${classMap({
          "border-normal": true,
          "minimal-padding": this.minimalPadding,
          "shorter-height": this.shorterHeight,
        })}
      >
        ${when(
          this.imgURL,
          () =>
            html`<img
              src=${this.imgURL}
              style=${this.disabled ? "opacity: 0.3" : ""}
              draggable="false"
            />`,
        )}
        ${when(this.text, () => html`<div id="text">${this.text}</div>`)}
        <slot></slot>
      </button>
    `;
  }
}
