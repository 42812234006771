import { TillKeys } from "../data/images";
import { GameObjectDepth } from "../data/game-objects";

const xTiles = 48;
const yTiles = 27;
// The number of units each tile takes up in the world
export const tileSize = 50;

// All objects' origins in the center, thus an offset of 0.5 in both x and y directions
const originOffset = 0.5;

const tillTileWidth = 2;
const tillTileHeight = 1;

const tillWidthOffset = tillTileWidth * originOffset;
const tillHeightOffset = tillTileHeight * originOffset;

const tillYPos = 13;

// Bottom product row
const bottomProductYPos = 16;
const sideProductYPos = 14;

// Product Indexes used to get the bounds for the direction helper.
// If you are above the position of the middle product index, you are at a till.
// If you are below it, you are at the bottom product row.

export const sideLeftProductIndex = 8; // Item 9
export const sideRightProductIndex = 6; // Item 7

export const BottomLeftProductIndex = 7; // Item 8
export const BottomRightProductIndex = 5; // Item 6

const horizontalProduct = {
  y: bottomProductYPos,
  w: 2,
  h: 1,
  image: TillKeys.ProductTill,
  depth: GameObjectDepth.Product,
  pickupOffsetX: 0,
  pickupOffsetY: -1,
};
const verticalProduct = {
  y: sideProductYPos,
  w: 1,
  h: 2,
  image: TillKeys.ProductTillVertical,
  depth: GameObjectDepth.Till, // employees need to be "above" the vertical counters
};

export const mapData = {
  width: xTiles * tileSize,
  height: yTiles * tileSize,
  tilesX: xTiles,
  tilesY: yTiles,
  tileSize: tileSize,
  roadTop: 7 * tileSize,
  roadBottom: 10 * tileSize,
  customerTopLane: 8.5 * tileSize, // The top lane is set to higher than the road top to account for the size and origin of the customer game object.
  customerMidLane: 9.5 * tileSize,
  customerBottomLane: 10.5 * tileSize,
  tillPosition: [
    /* Main Character*/
    { x: 22, y: tillYPos },
    /*Staff 1*/
    { x: 24, y: tillYPos },
    /*Staff 2*/
    { x: 19, y: tillYPos },
    /*Staff 3*/
    { x: 27, y: tillYPos },
    /*Staff 4*/
    { x: 16, y: tillYPos },
    /*Staff 5*/
    { x: 30, y: tillYPos },
    /*Staff 6*/
    { x: 13, y: tillYPos },
  ].map((v) => {
    // apply offset to get position of the object origin
    v.x += tillWidthOffset;
    v.y += tillHeightOffset;
    v.x *= tileSize;
    v.y *= tileSize;
    return v;
  }),

  hireEmployeePosition: [
    /* Main Character*/
    { x: 22, y: tillYPos },
    /*Staff 1*/
    { x: 24, y: tillYPos },
    /*Staff 2*/
    { x: 21, y: tillYPos },
    /*Staff 3*/
    { x: 26, y: tillYPos },
    /*Staff 4*/
    { x: 18, y: tillYPos },
    /*Staff 5*/
    { x: 29, y: tillYPos },
    /*Staff 6*/
    { x: 15, y: tillYPos },
  ].map((v) => {
    // apply offset to get position of the object origin
    // hire employee objects have a 1x1 tile size so use origin offset as is
    v.x += originOffset;
    v.y += originOffset;
    v.x *= tileSize;
    v.y *= tileSize;
    return v;
  }),
  tillSize: {
    w: tillTileWidth * tileSize,
    h: tillTileHeight * tileSize,
  },
  products: [
    /* Item 1 */
    { ...horizontalProduct, x: 23 },
    /* Item 2 */
    { ...horizontalProduct, x: 26 },
    /* Item 3 */
    { ...horizontalProduct, x: 20 },
    /* Item 4 */
    { ...horizontalProduct, x: 16 },
    /* Item 5 */
    { ...horizontalProduct, x: 30 },
    /* Item 6 */
    { ...horizontalProduct, x: 33 },
    /* Item 7 */
    { ...verticalProduct, x: 36, pickupOffsetX: -1, pickupOffsetY: -0.5 },
    /* Item 8 */
    { ...horizontalProduct, x: 13 },
    /* Item 9 */
    { ...verticalProduct, x: 11, pickupOffsetX: 1, pickupOffsetY: -0.5 },
  ].map((v) => {
    // apply offset to get position of the object origin
    v.x += v.w * originOffset;
    v.y += v.h * originOffset;

    v.x *= tileSize;
    v.y *= tileSize;
    v.w *= tileSize;
    v.h *= tileSize;
    v.pickupOffsetX *= tileSize;
    v.pickupOffsetY *= tileSize;
    return v;
  }),

  launchProductPosition: [
    /*Item 1*/
    { x: 22, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 2*/
    { x: 25, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 3*/
    { x: 22, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 4*/
    { x: 19, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 5*/
    { x: 28, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 6*/
    { x: 32, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 7*/
    { x: 35, y: sideProductYPos, w: 1, h: 1 },
    /*Item 8 */
    { x: 15, y: bottomProductYPos, w: 1, h: 1 },
    /*Item 9 */
    { x: 12, y: sideProductYPos, w: 1, h: 1 },
  ].map((v) => {
    // apply offset to get position of the object origin
    v.x += v.w * originOffset;
    v.y += v.h * originOffset;

    v.x *= tileSize;
    v.y *= tileSize;
    v.w *= tileSize;
    v.h *= tileSize;
    return v;
  }),
};
