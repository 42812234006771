import { watch } from "@lit-labs/preact-signals";
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { gameState } from "../state/game-state";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIWatchableModel } from "../models/gameModel";
import { greenContainer } from "../styles/theme";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import { popIn } from "../juice/juice";

@customElement("payday-tracker")
export class PaydayTracker extends SignalWatcher(LitElement) {
  static styles = [
    greenContainer,
    popIn,
    css`
      :host {
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100%;
        height: 100%;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
      }

      .container {
        display: flex;
        align-items: center;
        padding-bottom: 4px;
      }

      .gold-text {
        margin: 0px;
        padding-left: 8px;
      }

      .bar-container {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        width: 100%;
      }

      .payday-bar {
        background-color: white;
        border-radius: 8px;
        border: 2px solid var(--primary-navy);

        flex-grow: 0;

        display: flex;
        align-items: center;
      }

      .payday-text {
        margin: 0px;
      }

      #bar {
        background-color: var(--blue);
        height: 6px;
        border-radius: 4px;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  uiModel: UIWatchableModel;

  connectedCallback() {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
  }

  render() {
    return html`
      <div class="green-container wrapper popIn">
        <div class="container">
          <img src=${UIImages.CoinColor} height="28px" draggable="false" />
          <div class="gold-text">${watch(this.uiModel.gold)}</div>
        </div>
        <div class="container">
          <img src=${UIImages.Clock} height="28px" draggable="false" />
          <div class="bar-container">
            <div class="payday-text">
              ${gameState.paydayCount.value + 1} of 12
            </div>
            <div class="payday-bar">
              <div
                id="bar"
                style="width: ${watch(gameState.paydayBarPercentage)}%"
              ></div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
