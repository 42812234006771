import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import "./game-ui";
import { when } from "lit/directives/when.js";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { appState, Screen } from "../state/app-state";
import { isDevEnv } from "../env";

@customElement("game-container")
export class GameContainer extends SignalWatcher(LitElement) {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: relative;
    }
    game-ui {
      position: absolute;
      top: 0;
      left: 0;
    }
  `;

  render() {
    return html` ${when(
        appState.screen.value === Screen.Game,
        () => html`
          <game-ui></game-ui>
          ${when(
            isDevEnv,
            () => html`<gameplay-parameters></gameplay-parameters>`,
          )}
        `,
      )}
      <phaser-game></phaser-game>`;
  }
}
