export interface Sound {
  src: string | string[];
  /** Normalized value between 0 and 1 */
  volume?: number;
  loop?: boolean;
  autoplay?: boolean;
}

export const SoundData = {
  ButtonSelect_1: {
    src: "assets/audio/ButtonSelect_01.mp3",
  },
  ButtonSelect_2: {
    src: "assets/audio/ButtonSelect_02.mp3",
  },
  ButtonSelect_3: {
    src: "assets/audio/ButtonSelect_03.mp3",
  },
  Error: {
    src: "assets/audio/Error.mp3",
  },
  EmailNotification: {
    src: "assets/audio/Email_Notification.mp3",
  },
  EmailOpen: {
    src: "assets/audio/Email_Open.mp3",
  },
  Swipe: {
    src: "assets/audio/Swipe.mp3",
  },
  Success: {
    src: "assets/audio/Success.mp3",
  },
  PopIn: {
    src: "assets/audio/Pop_In.mp3",
  },
  PopOut: {
    src: "assets/audio/Pop_Out.mp3",
  },
  Purchase: {
    src: "assets/audio/Purchase.mp3",
  },
  Sale: {
    src: "assets/audio/Sale.mp3",
  },
  OrderTaken: {
    src: "assets/audio/Order_Taken.mp3",
  },
  Report: {
    src: "assets/audio/Report.mp3",
  },
};

export declare type SoundKey = keyof typeof SoundData;

export const MusicData = {
  MenuMusic: {
    src: "assets/audio/Music_Menu_Loop.mp3",
    loop: true,
  },
  GameMusic: {
    src: "assets/audio/Music_Game_Loop.mp3",
    loop: true,
  },
};

export declare type MusicKey = keyof typeof MusicData;
