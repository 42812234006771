import { css, html } from "lit";
import { property, customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { IDialogButton } from "./warning-dialog";
import "./warning-dialog";

export interface IErrorScreen {
  title: string;
  content: string[];
  buttons: IDialogButton[];
}

@customElement("error-screen")
export class ErrorScreen extends SignalWatcher(LitElementI18n) {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;
    }

    #background {
      width: 100%;
      height: 100%;
      background: url("assets/tiles/BG_pattern_transparent.png") repeat 0 0;
      background-size: 500px 500px;
      background-color: var(--secondary-violet-highlight);
    }

    #dialog-container {
      width: 100%;
      height: 100%;
      background-color: var(--semi-opaque-white);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    #dialog-container > warning-dialog {
      display: block;
      width: 412px;
      height: 228px;

      @media (min-width: 1000px) {
        width: 618px;
        height: 399px;
      }
    }
  `;

  @property({ attribute: false })
  details: IErrorScreen;

  render() {
    return html`
      <div id="background">
        <div id="dialog-container">
          <warning-dialog
            title=${this.details.title}
            .buttons=${this.details.buttons}
          >
            <div slot="content">
              ${this.details.content.map((c, index) =>
                index === 0 ? html`${c}` : html`<br />${c}`,
              )}
            </div>
          </warning-dialog>
        </div>
      </div>
    `;
  }
}
