import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("dialog-arrow")
export class DialogArrow extends LitElement {
  static styles = [
    css`
      .arrow {
        position: absolute;
      }

      .arrow.mobile {
        width: 26px;
        height: 16px;
        display: block;
        @media (min-width: 1000px) {
          display: none;
        }
      }

      .arrow.desktop {
        width: 39px;
        height: 24px;
        display: none;
        @media (min-width: 1000px) {
          display: block;
        }
      }

      .left {
        bottom: 8px;
        left: -5px;
        transform: rotate(90deg) translateY(100%);
        @media (min-width: 1000px) {
          bottom: 12px;
        }
      }

      .left.desktop {
        left: -7px;
      }

      .right {
        bottom: 8px;
        right: -5px;
        transform: rotate(270deg) translateY(100%);
        @media (min-width: 1000px) {
          bottom: 12px;
        }
      }

      .right.desktop {
        right: -8px;
      }

      .bottom {
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    `,
  ];

  @property({ type: String })
  direction: "left" | "right" | "bottom";

  @property({ type: Boolean, attribute: "no-border" })
  noBorder: boolean = false;

  render() {
    return html`
      <svg
        width="26"
        height="16"
        viewBox="0 0 26 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="arrow mobile ${this.direction}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.70526 -6.01262e-05L9.51372 13.8816C11.0429 16.6003 14.9571 16.6002 16.4863 13.8816L24.2948 -6.01262e-05H1.70526Z"
          fill="${!this.noBorder ? "var(--primary-navy)" : ""}"
        />
        <path
          d="M14.7432 12.9012C13.9785 14.2605 12.0215 14.2605 11.2568 12.9012L4 0.000111914L22 0.000113487L14.7432 12.9012Z"
          fill="white"
        />
      </svg>
      <svg
        width="39"
        height="24"
        viewBox="0 0 39 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="arrow desktop ${this.direction}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.55789 -2.91541e-05L14.2706 20.8225C16.5644 24.9004 22.4357 24.9004 24.7295 20.8225L36.4422 -2.91541e-05H2.55789Z"
          fill="${!this.noBorder ? "var(--primary-navy)" : ""}"
        />
        <path
          d="M22.1147 19.3516C20.9678 21.3906 18.0322 21.3906 16.8853 19.3516L6 4.58001e-05L33 4.81606e-05L22.1147 19.3516Z"
          fill="white"
        />
      </svg>
    `;
  }
}
