import { usingFirebaseBackend, usingLocalBackend } from "./env";
import { PortalAPI } from "./portal/api";

export const toLeaderboard = () => {
  if (usingLocalBackend || usingFirebaseBackend) {
    alert("Redirect to leaderboard");
  } else {
    // make the current page the origin, i.e. the base URL.
    // this will navigate to the portal
    window.location.href = PortalAPI.leaderBoardURL();
  }
};

export const toLogin = () => {
  if (usingLocalBackend || usingFirebaseBackend) {
    alert("Redirect to login");
  } else {
    // make the current page the origin, i.e. the base URL.
    // this will navigate to the portal
    window.location.href = PortalAPI.loginURL();
  }
};
