import { CopyKey } from "../translations/keys";
import { eventsSingleImages, eventsMultiImages } from "./images";
interface EventCopy {
  titleKey: CopyKey;
  bodyKey: CopyKey[];
  images?: string[];
}

export const EventsTabs: Record<string, EventCopy> = {
  //SHOP LEVEL UPGRADES
  "shop-2": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_02_Upgrade_Body01", "Event_Level_02_Upgrade_Body02"],
    images: [eventsSingleImages.levelUp_1],
  },
  "shop-3": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_03_Upgrade_Body01", "Event_Level_03_Upgrade_Body02"],
    images: [eventsSingleImages.levelUp_2],
  },
  "shop-4": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_04_Upgrade_Body01", "Event_Level_04_Upgrade_Body02"],
    images: [eventsSingleImages.levelUp_3],
  },
  "shop-5": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_05_Upgrade_Body01"],
    images: [eventsSingleImages.levelUp_4],
  },

  //FIRST PROFIT/LOSS
  "first-profit": {
    titleKey: "Event_Profit_Heading",
    bodyKey: ["Event_Profit_Body_01", "Event_Profit_Body_02"],
    images: [eventsSingleImages.tip],
  },
  "first-loss": {
    titleKey: "Event_Loss_Heading",
    bodyKey: ["Event_Loss_Body01", "Event_Loss_Body02"],
    images: [eventsSingleImages.tip],
  },

  //STAFF HIRES
  "hired-1st-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff01_Body01", "Event_Staff01_Body02"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-2nd-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff02_Body01", "Event_Staff02_Body02"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-3rd-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff03_Body01"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-4th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff04_Body01"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-5th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff05_Body01"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-6th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff06_Body01"],
    images: [eventsSingleImages.hire_staff],
  },
  "hired-7th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff07_Body01"],
    images: [eventsSingleImages.hire_staff],
  },

  //PRODUCTS LAUNCHED
  "product-1-added": {
    titleKey: "Event_FirstProduct_Heading",
    bodyKey: [
      "Event_FirstProduct_Body01",
      "Event_FirstProduct_Body02",
      "Event_FirstProduct_Body03",
    ],
    images: [eventsSingleImages.first_product],
  },

  //LOST SALES
  "lost-sale-price": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Event_Expensive_Body01", "Event_Expensive_Body02"],
    images: [
      eventsMultiImages.expensive_1,
      eventsMultiImages.expensive_2,
      eventsMultiImages.expensive_3,
      eventsMultiImages.expensive_4,
    ],
  },
  "lost-sale-price-n": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Not_Implemented"],
  },
  "lost-sale-no-stock": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Event_NoStock_Body_01"],
    images: [
      eventsMultiImages.stock_1,
      eventsMultiImages.stock_2,
      eventsMultiImages.stock_3,
    ],
  },
  "lost-sale-no-stock-n": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Not_Implemented"],
  },
  "lost-sale-no-staff": {
    titleKey: "Event_StaffNeeded_Heading",
    bodyKey: ["Event_StaffNeeded_Body01", "Event_StaffNeeded_Body02"],
    images: [
      eventsMultiImages.staff_1,
      eventsMultiImages.staff_2,
      eventsMultiImages.staff_3,
    ],
  },
  "lost-sale-no-staff-n": {
    titleKey: "Event_StaffNeeded_Heading",
    bodyKey: ["Not_Implemented"],
  },
  "lost-sale-no-marketing": {
    titleKey: "Event_NoMarketing_Heading",
    bodyKey: ["Event_NoMarketing_Body01", "Event_NoMarketing_Body02"],
    images: [
      eventsMultiImages.marketing_1,
      eventsMultiImages.marketing_2,
      eventsMultiImages.marketing_3,
      eventsMultiImages.marketing_4,
    ],
  },
  "lost-sale-no-marketing-n": {
    titleKey: "Event_NoMarketing_Heading",
    bodyKey: ["Not_Implemented"],
  },

  //BUSINESS VALUE
  "business-value-1": {
    titleKey: "Event_BusinessValue_01_Heading",
    bodyKey: ["Event_BusinessValue_01_Body"],
    images: [eventsSingleImages.exclamation],
  },
  "business-value-2": {
    titleKey: "Event_BusinessValue_02_Heading",
    bodyKey: ["Event_BusinessValue_02_Body"],
    images: [eventsSingleImages.tip],
  },
  "business-value-3": {
    titleKey: "Event_BusinessValue_03_Heading",
    bodyKey: ["Event_BusinessValue_03_Body"],
    images: [eventsSingleImages.tip],
  },
  "business-value-4": {
    titleKey: "Event_BusinessValue_04_Heading",
    bodyKey: ["Event_BusinessValue_04_Body"],
    images: [eventsSingleImages.tip],
  },
  "business-value-5": {
    titleKey: "Event_BusinessValue_05_Heading",
    bodyKey: ["Event_BusinessValue_05_Body"],
    images: [eventsSingleImages.tip],
  },
  "business-value-6": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  },

  //CASH VALUE
  "cash-value": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  },

  //SALARY DUE
  "salary-first-due-1-second": {
    titleKey: "Event_SalaryDue_Heading",
    bodyKey: ["Event_SalaryDue_Body01", "Event_SalaryDue_Body02"],
    images: [eventsMultiImages.salary_1, eventsMultiImages.salary_2],
  },
  "salary-first-due-30-seconds": {
    titleKey: "Event_Strike_Heading",
    bodyKey: ["Event_Strike_Body"],
    images: [eventsMultiImages.salary_1, eventsMultiImages.salary_2],
  },

  //NUMBER OF SALES
  "number-of-sales-equals-1": {
    titleKey: "Event_FirstSale_Heading",
    bodyKey: ["Event_FirstSale_Body01", "Event_FirstSale_Body02"],
    images: [
      eventsMultiImages.firstSale_1,
      eventsMultiImages.firstSale_2,
      eventsMultiImages.firstSale_3,
    ],
  },
  "number-of-sales-equals-n": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  },

  //NEXT PRODUCT/STAFF
  "cash-cost-of-next-product-x": {
    titleKey: "Event_Expand_Items_Heading",
    bodyKey: ["Event_Expand_Items_Body"],

    images: [eventsSingleImages.time_expand],
  },
  "cash-cost-of-next-staff-x": {
    titleKey: "Event_Expand_Staff_Heading",
    bodyKey: ["Event_Expand_Staff_Body"],
    images: [
      eventsMultiImages.staff_1,
      eventsMultiImages.staff_2,
      eventsMultiImages.staff_3,
    ],
  },

  //AFTER PAYDAY
  "payday-1": {
    titleKey: "Event_AfterPayday01_Heading",
    bodyKey: [
      "Event_AfterPayday01_Body01",
      "Event_AfterPayday01_Body02",
      "Event_AfterPayday01_Body03",
    ],
    images: [eventsSingleImages.tip],
  },
  "payday-2": {
    titleKey: "Event_AfterPayday02_Heading",
    bodyKey: ["Event_AfterPayday02_Body01"],
    images: [
      eventsMultiImages.marketing_1,
      eventsMultiImages.marketing_2,
      eventsMultiImages.marketing_3,
      eventsMultiImages.marketing_4,
    ],
  },
  "payday-6": {
    titleKey: "Event_AfterPayday06_Heading",
    bodyKey: ["Event_AfterPayday06_Body01", "Event_AfterPayday06_Body02"],
    images: [eventsSingleImages.business_value],
  },
  "payday-11": {
    titleKey: "Event_AfterPayday011_Heading",
    bodyKey: ["Event_AfterPayday011_Body01"],
    images: [eventsSingleImages.business_value],
  },
};
