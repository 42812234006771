import { html, css, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { clearEvent, gameState } from "../state/game-state";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { EventsTabs } from "../data/events";
import { translate } from "../translations/translations";
import { UIImages } from "../data/images";
import "./popup-close-button";
import { playSoundEffect } from "../sound/sound-manager";
import { IEventParams } from "../config/gameplayParameters";
import { when } from "lit/directives/when.js";
import { popIn } from "../juice/juice";

const nextPictureMs = 1300;
@customElement("event-modal")
export class EventModal extends SignalWatcher(LitElementI18n) {
  static styles = [
    popIn,
    css`
      :host {
        display: flex;
        font-family: Ubuntu;
        color: var(--primary-navy);
        font-style: normal;
        line-height: normal;
      }

      p {
        margin-block-start: 0px;
      }

      .header {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .header-text {
        display: flex;
        padding: 6px 10px 7px;
        font-size: 20px;
        font-weight: 700;

        @media (min-width: 1000px) {
          padding: 10px 12px 7px;
          font-size: 32px;
        }
      }

      .content {
        width: 100%;
        height: 214px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        gap: 14px;
        padding: 12px 14px 8px;

        @media (min-width: 1000px) {
          height: 240px;
          padding: 17px 24px 22px 30px;
          gap: 22px;
        }
      }

      .left-container {
        display: flex;
        gap: 8px;
        flex-direction: column;
      }

      .img-container {
        display: flex;
        width: 204px;
        height: 108px;
      }

      .cash-and-business-value-container {
        display: flex;
        width: 204px;
        height: fit-content;
        border-radius: 4px;
        background-color: var(--secondary-violet-semi-transparent);
        flex-direction: column;
        gap: 4px;
      }

      .cash-and-business-value-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px;
      }

      .cash-and-business-value-text {
        font-size: 14px;
        font-weight: 500;
      }

      .coin-line-icon {
        width: 16px;
        height: 16px;
      }

      .right-container {
        display: flex;
        flex-direction: column;
      }

      .event-text {
        font-size: 14px;
        font-weight: 400;

        @media (min-width: 1000px) {
          font-size: 18px;
        }
      }

      .btn-container {
        display: flex;
        justify-content: center;
        transform: translate(0, -50%);
      }

      .coin-and-number {
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: fit-content;
      }

      .number {
        position: relative;
        font-weight: 700;
        text-align: right;
        font-size: 14px;
        font-weight: 700;
      }

      .img-size {
        width: 204px;
        height: 108px;
      }

      arrow-container {
        width: 476px;
        height: 254px;

        @media (min-width: 1000px) {
          width: 758px;
          height: 300px;
        }
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  @state()
  imgUrl: string;

  eventTimeout: {
    id: string;
    interval: NodeJS.Timer;
    index: number;
    images: string[];
  } = undefined;

  closeModal = () => {
    const events = gameState.eventQueue.value;
    if (events.length === 0) return;
    const { cash: gold, businessValue: value } = events[0];
    if (gold) {
      playSoundEffect("Sale");
    }
    this.game.getModel().onEvent({ gold, value });
    playSoundEffect("PopOut");
    if (this.eventTimeout) {
      clearInterval(this.eventTimeout.interval);
      this.eventTimeout = undefined;
    }
    clearEvent();
  };

  firstUpdated() {
    playSoundEffect("PopIn");
  }

  nextPicture = () => {
    if (!this.eventTimeout || !this.eventTimeout.images) return;
    this.eventTimeout.index++;
    if (this.eventTimeout.index >= this.eventTimeout.images.length) {
      this.eventTimeout.index = 0;
    }
    this.imgUrl = this.eventTimeout.images[this.eventTimeout.index];
  };

  createImgInterval = (events: IEventParams[]) => {
    if (this.eventTimeout) {
      clearInterval(this.eventTimeout.interval);
      this.eventTimeout = undefined;
    }
    if (!EventsTabs[events[0].id].images) return;
    const interval = setInterval(this.nextPicture, nextPictureMs);
    this.eventTimeout = {
      id: events[0].id,
      index: 0,
      images: EventsTabs[events[0].id].images,
      interval,
    };
    this.imgUrl = this.eventTimeout.images[this.eventTimeout.index];
  };

  render() {
    const events = gameState.eventQueue.value;
    if (events.length === 0) return nothing;

    const { titleKey, bodyKey } = EventsTabs[events[0].id];
    const { cash, businessValue } = events[0];
    if (!this.eventTimeout || events[0].id !== this.eventTimeout.id) {
      this.createImgInterval(events);
    }

    return html`
      <arrow-container smallGreen eventsContainer>
        <div class="header">
          <div class="header-text">${translate(titleKey)}</div>
          <popup-close-button @click=${this.closeModal}></popup-close-button>
        </div>
        <div class="content">
          <div class="left-container">
            <div class="img-container">
              <img class="img-size" src=${this.imgUrl} draggable="false" />
            </div>
            <div class="cash-and-business-value-container">
              ${when(
                cash,
                () => html`
                  <div class="cash-and-business-value-content">
                    <div class="cash-and-business-value-text">
                      ${translate("Event_Cash")}
                    </div>
                    <div class="coin-and-number">
                      <img
                        class="coin-line-icon"
                        src=${UIImages.CoinLine}
                        draggable="false"
                      />
                      <div class="number">${cash || nothing}</div>
                    </div>
                  </div>
                `,
              )}
              ${when(
                businessValue,
                () =>
                  html` <div class="cash-and-business-value-content">
                    <div class="cash-and-business-value-text">
                      ${translate("Event_BusinessValue")}
                    </div>
                    <div class="coin-and-number">
                      <img
                        class="coin-line-icon "
                        src=${UIImages.CoinLine}
                        draggable="false"
                      />
                      <div class="number">${businessValue || nothing}</div>
                    </div>
                  </div>`,
              )}
            </div>
          </div>
          <div class="right-container">
            <div class="event-text">
              ${bodyKey.map((key) => html`<p>${translate(key)}</p>`)}
            </div>
          </div>
        </div>
        <div class="btn-container">
          <app-button
            class="popIn game-button"
            text=${translate(
              cash || businessValue ? "Event_Claim" : "Event_Dismiss",
            )}
            @click=${this.closeModal}
          ></app-button>
        </div>
      </arrow-container>
    `;
  }
}
