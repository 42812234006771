export const gameConfig = {
  backgroundWidth: 2400,
  backgroundHeight: 1350,

  mobileZoom: 2,
  desktopZoom: 1.2,

  // Adjust these values for the mobile zoom to be applied to different screen sizes
  smlDeviceWidth: 1000,
  smlDeviceHeight: 400,

  cameraOffsetMobile: 50, // 1 tile size in mapData
  cameraOffsetDeskop: 75,

  textColor: "#16006D",
  strokeColor: "#FFFFFF",
};
