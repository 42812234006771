import { html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";

@customElement("coin-slider")
export class CoinSlider extends LitElementI18n {
  static styles = css`
    :host {
      --thumb-size: 28px;
      --end-size: 6px;

      width: 100%;
      min-height: var(--thumb-size);
      max-height: var(--thumb-size);
    }

    .slider {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    .slider-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
    }

    #slider-track {
      width: 100%;
      display: flex;
      place-items: center;
    }

    #slider-track-line {
      width: calc(100% - var(--thumb-size)); /* half from both sides */
      height: 2px;
      margin-left: calc(var(--thumb-size) / 2);
      background: var(--primary-navy);
    }

    .slider-track-end {
      width: var(--end-size);
      height: var(--end-size);
      border-radius: 50%;
      background-color: var(--primary-navy);
    }
    .slider-track-end.left {
      position: absolute;
      left: calc((var(--thumb-size) - var(--end-size)) / 2);
    }
    .slider-track-end.right {
      position: absolute;
      right: calc((var(--thumb-size) - var(--end-size)) / 2);
    }

    #slider-input {
      -webkit-appearance: none;
      margin: 0;
      background: none;
      width: 100%;
      z-index: 2;
    }

    #slider-input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: var(--thumb-size);
      height: var(--thumb-size);
      border: 0;
      background: url("assets/misc-game/icon_coin_colour.svg");
      cursor: pointer;
      box-shadow: none;
    }

    #slider-input::-moz-range-thumb {
      box-shadow: none;
      appearance: none;
      width: var(--thumb-size);
      height: var(--thumb-size);
      border: 0;
      background: url("assets/misc-game/icon_coin_colour.svg");
      cursor: pointer;
    }

    .abs {
      position: absolute;
    }
    .rel {
      position: relative;
    }
  `;
  @property({ type: Number })
  minValue: number;
  @property({ type: Number })
  maxValue: number;
  @property({ type: Number })
  value: number;

  onValueChanged = () => {
    this.dispatchEvent(
      new CustomEvent<{ value: number }>("change", {
        // Cast value to integer
        detail: { value: +this.input.value },
        bubbles: true,
        composed: true,
      }),
    );
  };
  @query("#slider-input")
  input: HTMLInputElement;

  render() {
    return html`<div class="slider">
      <div class="slider-wrapper rel">
        <div id="slider-track" class="abs">
          <div id="slider-track-line"></div>
          <div class="slider-track-end left"></div>
          <div class="slider-track-end right"></div>
        </div>
        <input
          id="slider-input"
          type="range"
          min=${this.minValue}
          max=${this.maxValue}
          value=${this.value}
          class="price-slider"
          @input=${this.onValueChanged}
        />
      </div>
    </div>`;
  }
}
