import Phaser from "phaser";
import { preloadPhaserImageData } from "../helpers/preloadHelper";
import { ProductGroup, ProductKind } from "./products";
import { SpriteInfo } from "../types/spriteTypes";

/* ---------------------------- TYPES --------------------------- */
export enum ImageType {
  Image,
  Sprite,
  Atlas,
  SVG,
  PNG,
}

export enum GameImages {
  MainCharacterSheet = "main_character_sheet",
  EmployeeSheet = "employee_sheet",
  TillSheet = "till_sheet",
  CustomerSheet = "customer_sheet",
  ClothingSheet = "clothing_sheet",
  TechnologySheet = "technology_sheet",
  CoffeeShopSheet = "coffeeShop_sheet",
  StationarySheet = "stationary_sheet",
  ToiletriesSheet = "toiletries_sheet",
  EmoticonAndThoughtBubbleSheet = "emoticonAndThoughtBubble_sheet",
  ShopLevel1 = "shop_level1",
  ShopLevel2 = "shop_level2",
  ShopLevel3 = "shop_level3",
  ShopLevel4 = "shop_level4",
  ShopLevel5 = "shop_level5",
  Coin = "coin",
  StockAndPayAlert = "stock_and_pay_alert",
  DemandAndUpgradeAlert = "demand_and_upgrade_alert",
  Pointer = "pointer",
}

export interface ImageObject {
  key: GameImages;
  type: ImageType;
  src: string;
  json?: string;
}

export declare type ImageData = ImageObject[];

/* ---------------------------- SHOP LEVEL BACKGROUNDS ---------------------------- */
const shopLevelImageData: ImageData = [
  {
    key: GameImages.ShopLevel1,
    type: ImageType.Image,
    src: "assets/backgrounds/level1.png",
  },
  {
    key: GameImages.ShopLevel2,
    type: ImageType.Image,
    src: "assets/backgrounds/level2.png",
  },
  {
    key: GameImages.ShopLevel3,
    type: ImageType.Image,
    src: "assets/backgrounds/level3.png",
  },
  {
    key: GameImages.ShopLevel4,
    type: ImageType.Image,
    src: "assets/backgrounds/level4.png",
  },
  {
    key: GameImages.ShopLevel5,
    type: ImageType.Image,
    src: "assets/backgrounds/level5.png",
  },
];

export const loadShopLevelImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(shopLevelImageData, scene.load, scene.textures);
};

/* ---------------------------- BACKGROUND COLOUR ---------------------------- */
export enum BackgroundColour {
  TestBackgroundColor = "#ffffff",
}

/* ---------------------------- MAIN CHARACTER ---------------------------- */
export const loadMainCharacterImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(mainCharacterImageData, scene.load, scene.textures);
};

export const mainCharacterImageData: ImageData = [
  {
    key: GameImages.MainCharacterSheet,
    type: ImageType.Atlas,
    src: "assets/main-character/MiniMainCharacter.png",
    json: "assets/main-character/MiniMainCharacter.json",
  },
];

/* ---------------------------- EMPLOYEES ---------------------------- */
export const loadEmployeeImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(employeeImageData, scene.load, scene.textures);
};

export const employeeImageData: ImageData = [
  {
    key: GameImages.EmployeeSheet,
    type: ImageType.Atlas,
    src: "assets/employees/employees.png",
    json: "assets/employees/employees.json",
  },
];

/* ---------------------------- CUSTOMERS ---------------------------- */
export const loadCustomerImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(customerImageData, scene.load, scene.textures);
};

export const customerImageData: ImageData = [
  {
    key: GameImages.CustomerSheet,
    type: ImageType.Atlas,
    src: "assets/customers/customers.png",
    json: "assets/customers/customers.json",
  },
];

/* ---------------------------- COUNTERS/TILLS ---------------------------- */
export const loadTillImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(tillImageData, scene.load, scene.textures);
};

export const tillImageData: ImageData = [
  {
    key: GameImages.TillSheet,
    type: ImageType.Atlas,
    src: "assets/tills/countertop_assets.png",
    json: "assets/tills/countertop_assets.json",
  },
];

export enum TillKeys {
  ProductTill = "counter_empty.png",
  ProductTillVertical = "counter_vertical.png",
  EmployeeTill = "counter_serving.png",
  LaunchProductButton = "button_new_product.png",
  HireEmployeeButton = "button_new_serve.png",
  ProductCountPill = "pill_stock_indicator.png",
}

/* ---------------------------- PRODUCTS-ALL ---------------------------- */
export const loadAllShopProducts = (scene: Phaser.Scene) => {
  preloadPhaserImageData(clothingShopImageData, scene.load, scene.textures);
  preloadPhaserImageData(technologyShopImageData, scene.load, scene.textures);
  preloadPhaserImageData(coffeeShopImageData, scene.load, scene.textures);
  preloadPhaserImageData(stationaryShopImageData, scene.load, scene.textures);
  preloadPhaserImageData(toiletriesShopImageData, scene.load, scene.textures);
};

export const getProductSpriteInfo = (p: ProductKind): SpriteInfo => {
  let spriteSheet: string;

  switch (p.group) {
    case ProductGroup.Clothing:
      spriteSheet = GameImages.ClothingSheet;
      break;
    case ProductGroup.CoffeeShop:
      spriteSheet = GameImages.CoffeeShopSheet;
      break;
    case ProductGroup.Stationary:
      spriteSheet = GameImages.StationarySheet;
      break;
    case ProductGroup.Technology:
      spriteSheet = GameImages.TechnologySheet;
      break;
    case ProductGroup.Toiletries:
      spriteSheet = GameImages.ToiletriesSheet;
      break;
  }

  return { spriteKey: spriteSheet, frame: `${p.group}_${p.index}.png` };
};

/* ---------------------------- PRODUCTS-CLOTHING ---------------------------- */
export const clothingShopImageData: ImageData = [
  {
    key: GameImages.ClothingSheet,
    type: ImageType.Atlas,
    src: "assets/products/clothing.png",
    json: "assets/products/clothing.json",
  },
];

/* ---------------------------- PRODUCTS-TECHNOLOGY ---------------------------- */
export const technologyShopImageData: ImageData = [
  {
    key: GameImages.TechnologySheet,
    type: ImageType.Atlas,
    src: "assets/products/technology.png",
    json: "assets/products/technology.json",
  },
];

/* ---------------------------- PRODUCTS-COFFEE ---------------------------- */
export const coffeeShopImageData: ImageData = [
  {
    key: GameImages.CoffeeShopSheet,
    type: ImageType.Atlas,
    src: "assets/products/coffeeShop.png",
    json: "assets/products/coffeeShop.json",
  },
];

/* ---------------------------- PRODUCTS-STATIONARY ---------------------------- */
export const stationaryShopImageData: ImageData = [
  {
    key: GameImages.StationarySheet,
    type: ImageType.Atlas,
    src: "assets/products/stationary.png",
    json: "assets/products/stationary.json",
  },
];

/* ---------------------------- PRODUCTS-TOILETRIES ---------------------------- */
export const toiletriesShopImageData: ImageData = [
  {
    key: GameImages.ToiletriesSheet,
    type: ImageType.Atlas,
    src: "assets/products/toiletries.png",
    json: "assets/products/toiletries.json",
  },
];

/* ---------------------------- EMOTICONS AND THOUGHT BUBBLE ---------------------------- */
export const loadEmoticonAndThoughtBubbleImages = (scene: Phaser.Scene) => {
  preloadPhaserImageData(
    emoticonsAndThoughtBubbleImageData,
    scene.load,
    scene.textures,
  );
};

export const emoticonsAndThoughtBubbleImageData: ImageData = [
  {
    key: GameImages.EmoticonAndThoughtBubbleSheet,
    type: ImageType.Atlas,
    src: "assets/emoticons-and-thought-bubble/emoticons_and_thoughtBubble.png",
    json: "assets/emoticons-and-thought-bubble/emoticons_and_thoughtBubble.json",
  },
];

/* ---------------------------- MISC GAME ASSETS ---------------------------- */

export const loadMiscGameAssets = (scene: Phaser.Scene) => {
  preloadPhaserImageData(miscGameAssets, scene.load, scene.textures);
};

export const miscGameAssets: ImageData = [
  {
    key: GameImages.Coin,
    type: ImageType.SVG,
    src: "assets/misc-game/icon_coin_colour.svg",
  },
  {
    key: GameImages.StockAndPayAlert,
    type: ImageType.SVG,
    src: "assets/misc-game/pip_stock_and_pay_alert.svg",
  },
  {
    key: GameImages.DemandAndUpgradeAlert,
    type: ImageType.SVG,
    src: "assets/misc-game/pip_upgrade_alert.svg",
  },
  {
    key: GameImages.Pointer,
    type: ImageType.Image,
    src: "assets/misc-game/pointer.png",
  },
];

/* ---------------------------- UI IMAGE DATA ---------------------------- */
export const UIImages = {
  LoadingBackgroundTile: "assets/tiles/BG_pattern_transparent.png",

  MainCharacterExpression_Determined:
    "assets/main-character/expressions/determined.svg",
  MainCharacterExpression_Pondering:
    "assets/main-character/expressions/pondering.svg",
  MainCharacterExpression_Sad: "assets/main-character/expressions/sad.svg",

  Cheveron_Left_Active: "assets/icons/icon_chevron_left_active.svg",
  Cheveron_Right_Active: "assets/icons/icon_chevron_right_active.svg",
  Chevron_Down_Active: "assets/icons/icon_chevron_down_active.svg",

  Menu_Home: "assets/icons/icon_home.svg",
  Menu_Install: "assets/icons/icon_install.svg",
  Menu_Logout: "assets/icons/icon_logout.svg",
  Menu_Active: "assets/icons/icon_menu_active.svg",
  Menu_Disabled: "assets/icons/icon_menu_disabled.svg",
  Menu_Mute: "assets/icons/icon_mute.svg",
  Menu_Unmute: "assets/icons/icon_unmute.svg",
  Menu_Avatar: "assets/icons/icon_avatar.svg",
  Menu_Leaderboard: "assets/icons/icon_leaderboard.svg",

  Cross_Active: "assets/icons/icon_cross_active.svg",
  Cross_Disabled: "assets/icons/icon_cross_disabled.svg",

  LogoLoader: "assets/icons/Logo_AG.svg",
  LogoHome: "assets/icons/logo_AG_home.svg",
  ScoreContainer: "assets/icons/container_home_screen.svg",
  CoinColor: "assets/misc-game/icon_coin_colour.svg",
  CoinLine: "assets/icons/icon_coin_line.svg",
  ValueGraph: "assets/misc-game/icon_graph.svg",
  AlertPip: "assets/misc-game/pip_stock_and_pay_alert.svg",
  UpgradePip: "assets/misc-game/pip_upgrade_alert.svg",
  Clock: "assets/misc-game/icon_time.svg",

  RotateDevice: "assets/icons/icon_rotate_warning.svg",

  LevelUpBanner: "assets/level-up/level_up_banner.svg",
  LevelUpRays: "assets/level-up/level_up_rays.svg",

  SaveNewGame: "assets/icons/icon_save_newgame.svg",
  SaveInternet: "assets/icons/icon_save_internet.svg",
  SaveDevice: "assets/icons/icon_save_device.svg",

  GameOver_Bg: "assets/game-over/endgame_bg.svg",
  GameOver_Title: "assets/game-over/endgame_title.svg",
  GameOver_HighScorePill: "assets/game-over/endgame_pill_highscore.svg",
  GameOver_HighScoreContainer:
    "assets/game-over/endgame_container_highscore.svg",
  GameOver_MainCharacter: "assets/game-over/endgame_maincharacter.svg",

  Report_Info: "assets/icons/icon_monthly_report_info.svg",
  Report_Equal: "assets/icons/icon_monthly_report_equal.svg",
  Report_Minus: "assets/icons/icon_monthly_report_minus.svg",
  Report_Portrait_Expensive: "assets/icons/img_market_res_expensive.svg",
  Report_Portrait_Marketing: "assets/icons/img_market_res_marketing.svg",
  Report_Portrait_Staff: "assets/icons/img_market_res_staff.svg",
  Report_Portrait_Stock: "assets/icons/img_market_res_stock.svg",
};

export const LottieAnimationJSONs = {
  LoadingSpinner: "assets/lottie/spinning_coin.json",
  GameOver: "assets/lottie/endgame_title.json",
  MarketingJuice: [
    [
      "assets/lottie/marketing_juice/juice_marketing_L1_1.json",
      "assets/lottie/marketing_juice/juice_marketing_L1_2.json",
    ],
    [
      "assets/lottie/marketing_juice/juice_marketing_L2_1.json",
      "assets/lottie/marketing_juice/juice_marketing_L2_2.json",
    ],
    [
      "assets/lottie/marketing_juice/juice_marketing_L3_1.json",
      "assets/lottie/marketing_juice/juice_marketing_L3_2.json",
    ],
    [
      "assets/lottie/marketing_juice/juice_marketing_L4_1.json",
      "assets/lottie/marketing_juice/juice_marketing_L4_2.json",
    ],
    [
      "assets/lottie/marketing_juice/juice_marketing_L5_1.json",
      "assets/lottie/marketing_juice/juice_marketing_L5_2.json",
    ],
    ["assets/lottie/marketing_juice/juice_marketing_L5_1.json"],
  ],
};

/* ---------------------------- INTRO IMAGES ---------------------------- */
export const IntroImages = {
  Intro_Envelope: "assets/intro/img_intro_envelope.svg",
  Intro_Letter: "assets/intro/img_intro_letter.svg",
};

/* ----------------------------SHOP CATEGORY IMAGES ---------------------------- */
export const ChooseShopCategoryImages = {
  ChooseShopCategory_Clothing: "assets/choose-shop-category/Clothing.svg",
  ChooseShopCategory_Coffee: "assets/choose-shop-category/Coffee.svg",
  ChooseShopCategory_Stationery: "assets/choose-shop-category/Stationery.svg",
  ChooseShopCategory_Technology: "assets/choose-shop-category/Technology.svg",
  ChooseShopCategory_Toiletries: "assets/choose-shop-category/Toiletries.svg",
  ChooseShopCatergory_MarketStall:
    "assets/choose-shop-category/Market_Stall.svg",
};

/* ----------------------------HIRE EMPLOYEE DIALOG IMAGES ---------------------------- */
export const hireEmloyeeDialogImages = [
  "assets/hire-employees/staff_01.svg",
  "assets/hire-employees/staff_02.svg",
  "assets/hire-employees/staff_03.svg",
  "assets/hire-employees/staff_04.svg",
  "assets/hire-employees/staff_05.svg",
  "assets/hire-employees/staff_06.svg",
];
/* ---------------------------- PRODUCT IMAGES ---------------------------- */

// work out if there is a way to selectively pre-load based on chosen business type
export const CoffeeShopProducts = [
  "assets/products-svg/Product_CoffeeShop_0.svg",
  "assets/products-svg/Product_CoffeeShop_1.svg",
  "assets/products-svg/Product_CoffeeShop_2.svg",
  "assets/products-svg/Product_CoffeeShop_3.svg",
  "assets/products-svg/Product_CoffeeShop_4.svg",
  "assets/products-svg/Product_CoffeeShop_5.svg",
  "assets/products-svg/Product_CoffeeShop_6.svg",
  "assets/products-svg/Product_CoffeeShop_7.svg",
  "assets/products-svg/Product_CoffeeShop_8.svg",
];
export const FashionProducts = [
  "assets/products-svg/Product_Fashion_0.svg",
  "assets/products-svg/Product_Fashion_1.svg",
  "assets/products-svg/Product_Fashion_2.svg",
  "assets/products-svg/Product_Fashion_3.svg",
  "assets/products-svg/Product_Fashion_4.svg",
  "assets/products-svg/Product_Fashion_5.svg",
  "assets/products-svg/Product_Fashion_6.svg",
  "assets/products-svg/Product_Fashion_7.svg",
  "assets/products-svg/Product_Fashion_8.svg",
];

export const StationeryProducts = [
  "assets/products-svg/Product_Stationery_0.svg",
  "assets/products-svg/Product_Stationery_1.svg",
  "assets/products-svg/Product_Stationery_2.svg",
  "assets/products-svg/Product_Stationery_3.svg",
  "assets/products-svg/Product_Stationery_4.svg",
  "assets/products-svg/Product_Stationery_5.svg",
  "assets/products-svg/Product_Stationery_6.svg",
  "assets/products-svg/Product_Stationery_7.svg",
  "assets/products-svg/Product_Stationery_8.svg",
];

export const TechnologyProducts = [
  "assets/products-svg/Product_Technology_0.svg",
  "assets/products-svg/Product_Technology_1.svg",
  "assets/products-svg/Product_Technology_2.svg",
  "assets/products-svg/Product_Technology_3.svg",
  "assets/products-svg/Product_Technology_4.svg",
  "assets/products-svg/Product_Technology_5.svg",
  "assets/products-svg/Product_Technology_6.svg",
  "assets/products-svg/Product_Technology_7.svg",
  "assets/products-svg/Product_Technology_8.svg",
];

export const ToiletriesProducts = [
  "assets/products-svg/Product_Toiletries_0.svg",
  "assets/products-svg/Product_Toiletries_1.svg",
  "assets/products-svg/Product_Toiletries_2.svg",
  "assets/products-svg/Product_Toiletries_3.svg",
  "assets/products-svg/Product_Toiletries_4.svg",
  "assets/products-svg/Product_Toiletries_5.svg",
  "assets/products-svg/Product_Toiletries_6.svg",
  "assets/products-svg/Product_Toiletries_7.svg",
  "assets/products-svg/Product_Toiletries_8.svg",
];

/* ----------------------------TIP IMAGES ---------------------------- */
export const tipImages = {
  lackOfMarketing: "assets/tips/tip_lackOfMarketing.png",
  noStaff: "assets/tips/tip_noStaffAvailable.png",
  noStock: "assets/tips/tip_noStock.png",
  priceTooHigh: "assets/tips/tip_priceTooHigh.png",
  phone: "assets/tips/tip_phone.png",
  stock: "assets/tips/tip_stock.png",
  cash: "assets/tips/tip_cash.png",
};

/* ----------------------------EVENT IMAGES-SINGLE FRAMES ---------------------------- */
export const eventsSingleImages = {
  business_value: "assets/events/single-frames/img_event_business_value.png",
  exclamation: "assets/events/single-frames/img_event_exclamation.png",
  first_product: "assets/events/single-frames/img_event_first_product.png",
  hire_staff: "assets/events/single-frames/img_event_hire_staff.png",
  levelUp_1: "assets/events/single-frames/img_event_level_up01.png",
  levelUp_2: "assets/events/single-frames/img_event_level_up02.png",
  levelUp_3: "assets/events/single-frames/img_event_level_up03.png",
  levelUp_4: "assets/events/single-frames/img_event_level_up04.png",
  time_expand: "assets/events/single-frames/img_event_time_to_expand.png",
  tip: "assets/events/single-frames/img_event_tip.png",
};

/* ----------------------------EVENT IMAGES-SINGLE FRAMES ---------------------------- */
export const eventsMultiImages = {
  expensive_1: "assets/events/multi-frames/img_event_expensive01.png",
  expensive_2: "assets/events/multi-frames/img_event_expensive02.png",
  expensive_3: "assets/events/multi-frames/img_event_expensive03.png",
  expensive_4: "assets/events/multi-frames/img_event_expensive04.png",

  firstSale_1: "assets/events/multi-frames/img_event_first_sale01.png",
  firstSale_2: "assets/events/multi-frames/img_event_first_sale02.png",
  firstSale_3: "assets/events/multi-frames/img_event_first_sale03.png",

  marketing_1: "assets/events/multi-frames/img_event_marketing01.png",
  marketing_2: "assets/events/multi-frames/img_event_marketing02.png",
  marketing_3: "assets/events/multi-frames/img_event_marketing03.png",
  marketing_4: "assets/events/multi-frames/img_event_marketing04.png",

  salary_1: "assets/events/multi-frames/img_event_salary01.png",
  salary_2: "assets/events/multi-frames/img_event_salary02.png",

  staff_1: "assets/events/multi-frames/img_event_staff01.png",
  staff_2: "assets/events/multi-frames/img_event_staff02.png",
  staff_3: "assets/events/multi-frames/img_event_staff03.png",

  stock_1: "assets/events/multi-frames/img_event_stock01.png",
  stock_2: "assets/events/multi-frames/img_event_stock02.png",
  stock_3: "assets/events/multi-frames/img_event_stock03.png",
};

/* ---------------------------- UI/NON-GAME IMAGES PRELOAD---------------------------- */
export const SecondaryPreloadImages = [
  IntroImages.Intro_Envelope,
  IntroImages.Intro_Letter,

  UIImages.MainCharacterExpression_Determined,
  UIImages.MainCharacterExpression_Pondering,
  UIImages.MainCharacterExpression_Sad,

  ChooseShopCategoryImages.ChooseShopCategory_Clothing,
  ChooseShopCategoryImages.ChooseShopCategory_Coffee,
  ChooseShopCategoryImages.ChooseShopCategory_Stationery,
  ChooseShopCategoryImages.ChooseShopCategory_Technology,
  ChooseShopCategoryImages.ChooseShopCategory_Toiletries,
  ChooseShopCategoryImages.ChooseShopCatergory_MarketStall,

  UIImages.Cheveron_Left_Active,
  UIImages.Cheveron_Right_Active,

  UIImages.Menu_Home,
  UIImages.Menu_Install,
  UIImages.Menu_Logout,
  UIImages.Menu_Active,
  UIImages.Menu_Disabled,
  UIImages.Menu_Mute,
  UIImages.Menu_Unmute,
  UIImages.Menu_Active,

  UIImages.Cross_Active,
  UIImages.Cross_Disabled,

  UIImages.RotateDevice,

  UIImages.CoinColor,
  UIImages.CoinLine,

  UIImages.LevelUpBanner,
  UIImages.LevelUpRays,

  UIImages.AlertPip,
];
