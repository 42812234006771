import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIWatchableModel } from "../models/gameModel";
import {
  FinancialReportModel,
  ProductInfo,
} from "../models/financialReportModel";
import { UIImages } from "../data/images";
import { translate } from "../translations/translations";

@customElement("profit-loss")
export class ProfitLoss extends LitElementI18n {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;

        --bg-color: var(--profitLoss-yellow);
      }

      .container {
        background-color: var(--profitLoss-light-yellow);
        box-sizing: border-box;
        padding: 6px 10px;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 4px;

        @media (min-width: 1000px) {
          padding: 8px 20px;
          gap: 8px;
        }
      }

      .top {
        background-color: var(--bg-color);
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        padding: 4px 0;
      }

      .title {
        flex-shrink: 1;
        flex-grow: 1;
        width: 150px; /* to give each a similar starting size to flex grow together*/

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
      }

      .title-text {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;

        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;

        @media (min-width: 1000px) {
          font-size: 24px;
        }
      }

      .title-pill {
        width: 84px;
        border-radius: 10px;
        background: var(--yellow);

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        font-size: 12px;
        font-weight: 500;
        @media (min-width: 1000px) {
          font-size: 16px;
        }
      }

      .top-img {
        width: 28px;
        height: 28px;
      }

      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        flex: 1 1;

        @media (min-width: 1000px) {
          gap: 20px;
        }
      }

      .bottom-panel {
        background-color: var(--bg-color);
        border-radius: 4px;

        flex: 1 1;
        padding: 6px 12px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        height: 100%;
      }

      .text-value-pair {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 12px;

        @media (min-width: 1000px) {
          font-size: 16px;
        }
      }

      .text-value-pair > .left-text {
        font-weight: 500;
      }

      .text-value-pair > .right-text {
        font-weight: 400;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  uiModel: UIWatchableModel;
  financialReportModel: FinancialReportModel;

  connectedCallback(): void {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
  }

  renderProfitOrLoss = () => {
    const text =
      this.financialReportModel.profit >= 0
        ? translate("FinancialReport_P/L_Tab_Profit")
        : translate("FinancialReport_P/L_Tab_Loss");

    return html` <div class="title">
      <div class="title-text">
        <tip-button key="Tip_ProfitOrLoss"></tip-button>
        ${text}
      </div>
      <div class="title-pill">
        ${Math.abs(this.financialReportModel.profit)}
      </div>
    </div>`;
  };

  renderProductSales = (info: ProductInfo) => {
    return html`
      <div class="text-value-pair">
        <div class="left-text">${info.kind.productName()}</div>
        <div class="right-text">${info.income}</div>
      </div>
    `;
  };

  render() {
    return html`
      <div class="container">
        <div class="top">
          <div class="title">
            <div class="title-text">
              <tip-button key="Tip_Revenue"></tip-button>
              ${translate("FinancialReport_P/L_Tab_Revenue")}
            </div>
            <div class="title-pill">${this.financialReportModel.income}</div>
          </div>
          <img class="top-img" src=${UIImages.Report_Minus} draggable="false" />
          <div class="title">
            <div class="title-text">
              <tip-button key="Tip_Expenses"></tip-button>
              ${translate("FinancialReport_P/L_Tab_Expenses")}
            </div>
            <div class="title-pill">${this.financialReportModel.expenses}</div>
          </div>
          <img class="top-img" src=${UIImages.Report_Equal} draggable="false" />
          ${this.renderProfitOrLoss()}
        </div>
        <div class="bottom">
          <div class="bottom-panel">
            ${Object.values(this.financialReportModel.productInfo).map((info) =>
              this.renderProductSales(info),
            )}
          </div>
          <div class="bottom-panel">
            <div class="text-value-pair">
              <div class="left-text">
                ${translate("FinancialReport_P/L_Tab_Expenses_Stock")}
              </div>
              <div class="right-text">
                ${this.financialReportModel.stockCost}
              </div>
            </div>
            <div class="text-value-pair">
              <div class="left-text">
                ${translate("FinancialReport_P/L_Tab_Expenses_Marketing")}
              </div>
              <div class="right-text">
                ${this.financialReportModel.marketing}
              </div>
            </div>
            <div class="text-value-pair">
              <div class="left-text">
                ${translate("FinancialReport_P/L_Tab_Expenses_Salaries")}
              </div>
              <div class="right-text">
                ${this.financialReportModel.salaries}
              </div>
            </div>
          </div>
          <div
            class="bottom-panel"
            style="background-color: transparent !important;"
          >
            <!-- Empty box for responsive sizing -->
          </div>
        </div>
      </div>
    `;
  }
}
