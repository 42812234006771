import Phaser from "phaser";
import { ProductGroup, ProductKind } from "../data/products";
import { GameImages, TillKeys } from "../data/images";
import { Product } from "../models/product";
import { effect } from "@lit-labs/preact-signals";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min.js";
import { mapData } from "../controllers/mapData";
import { gameConfig } from "../config/gameConfig";

interface ProductSpriteInfo {
  spriteKey: string;
  frame: string;
}

const counterBottomPx = 16;

const getProductSpriteInfo = (p: ProductKind): ProductSpriteInfo => {
  let spriteSheet: string;

  switch (p.group) {
    case ProductGroup.Clothing:
      spriteSheet = GameImages.ClothingSheet;
      break;
    case ProductGroup.CoffeeShop:
      spriteSheet = GameImages.CoffeeShopSheet;
      break;
    case ProductGroup.Stationary:
      spriteSheet = GameImages.StationarySheet;
      break;
    case ProductGroup.Technology:
      spriteSheet = GameImages.TechnologySheet;
      break;
    case ProductGroup.Toiletries:
      spriteSheet = GameImages.ToiletriesSheet;
      break;
  }

  return { spriteKey: spriteSheet, frame: `${p.group}_${p.index}.png` };
};

export class ProductGameObject extends Phaser.GameObjects.Container {
  private till: Phaser.GameObjects.Image;
  private stockPip: Phaser.GameObjects.Image;
  private unsubscribeFromSignal: () => void;

  private juice: phaserJuice;

  constructor(scene: Phaser.Scene, product: Product) {
    super(scene);
    this.juice = new phaserJuice(this.scene);
    this.setPosition(product.position.x, product.position.y);

    const pData = mapData.products[product.index];
    this.setDepth(pData.depth);

    this.till = this.scene.add.image(0, 0, GameImages.TillSheet, pData.image);
    this.add(this.till);

    const { spriteKey, frame } = getProductSpriteInfo(product.kind);
    // The product needs to be placed in the centre of the product counter,
    // not the image itself.
    const pos = new Phaser.Math.Vector2(0, -counterBottomPx / 2);
    const image = this.scene.add.image(pos.x, pos.y, spriteKey, frame);
    this.add(image);

    const stockPill = this.scene.add.image(
      pos.x,
      pos.y + image.height,
      GameImages.TillSheet,
      TillKeys.ProductCountPill,
    );
    this.add(stockPill);

    const stockCounter = this.scene.add
      .text(
        stockPill.getCenter().x,
        stockPill.getCenter().y,
        `${product.availableStock.value}`,
        {
          fontSize: "14px",
          fontStyle: "Normal",
          fontFamily: "Ubuntu",
          color: `${gameConfig.textColor}`,
        },
      )
      .setOrigin(0.5);
    this.add(stockCounter);

    this.stockPip = this.scene.add.image(
      stockPill.getTopRight().x,
      stockPill.getTopRight().y,
      GameImages.StockAndPayAlert,
    );
    this.add(this.stockPip);

    this.unsubscribeFromSignal = effect(() => {
      stockCounter.text = `${product.availableStock.value}`;
      this.stockPip.setVisible(product.availableStock.value === 0);
      if (product.availableStock.value === 0) {
        this.pulse();
      }
    });

    // setup click event
    this.till.setInteractive({
      useHandCursor: true,
    });
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }

  private onPointerDown = (
    pointer: Phaser.Input.Pointer,
    currentlyOver: Phaser.GameObjects.GameObject[],
  ) => {
    if (currentlyOver.includes(this.till)) {
      this.emit("click", pointer.worldX, pointer.worldY);
    }
  };

  destroy(fromScene?: boolean): void {
    this.unsubscribeFromSignal?.();
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
    super.destroy(fromScene);
  }

  private pulse() {
    this.stockPip.setScale(1);
    this.juice.pulse(this.stockPip, {
      repeat: -1,
      duration: 500,
      scaleX: this.stockPip.scaleX * 1.2,
      scaleY: this.stockPip.scaleY * 1.2,
      ease: "Quad.easeInOut",
    });
  }
}
