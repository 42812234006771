import {
  AchievementCompletedEvent,
  FirstProfitEvent,
} from "../analytics/analytics";
import {
  EventTriggerCategories,
  IEventParams,
} from "../config/gameplayParameters";
import { warn } from "../helpers/loggerHelper";
import { getAnalyticsPaydayProgress, queueEvent } from "../state/game-state";
import { WalkAwayReason } from "./customer";
import { GameModel } from "./gameModel";

export class ModelReducer {
  private static instance: ModelReducer;
  prev: GameModel;
  events: Map<EventTriggerCategories, IEventParams[]>;

  constructor(model: GameModel, params: IEventParams[]) {
    if (ModelReducer.instance && __BUILD_ENV__ === "TEST") {
      // In tests we don't want a different class overriding the reducer's instance
      return ModelReducer.instance;
    }
    ModelReducer.instance = this;
    this.updateEventParams(params);
    this.setModel(model);
  }

  public updateEventParams = (params: IEventParams[]) => {
    this.events = new Map();
    params.forEach((event) => {
      const triggerCategory = EventTriggerCategories[event.trigger.category];
      if (!this.events.has(triggerCategory)) {
        this.events.set(triggerCategory, []);
      }
      this.events.get(triggerCategory).push(event);
    });
  };

  private completeEvent = (model: GameModel, event: IEventParams) => {
    queueEvent(event);
    model.events.setEventCompleted(event.id);
    AchievementCompletedEvent(
      event.id,
      event.cash,
      event.businessValue,
      model.getPayday().getPaydayCount(),
      getAnalyticsPaydayProgress(),
    );
    // Analytics
    switch (event.id) {
      case "first-profit":
        FirstProfitEvent(
          model.getFinancialReportModel().profit,
          model.getPayday().getPaydayCount(),
        );
        break;
      default:
        return;
    }
  };

  private onValueChanged = (
    model: GameModel,
    event: IEventParams,
    prevValue: number,
    currValue: number,
  ) => {
    if (
      !model.events.isEventCompleted(event.id) &&
      prevValue < event.trigger.value &&
      currValue >= event.trigger.value
    ) {
      this.completeEvent(model, event);
    }
  };

  private checkEventCateogry = (
    categoryEvents: IEventParams[],
    model: GameModel,
    prevValue: number,
    currValue: number,
  ) => {
    categoryEvents.forEach((event) =>
      this.onValueChanged(model, event, prevValue, currValue),
    );
  };

  public update = (model: GameModel) => {
    let prevValue;
    let currValue;
    Object.values(EventTriggerCategories).forEach((category) => {
      if (!this.events.has(category)) return;
      const unfinishedEvents = this.events
        .get(category)
        .filter((ev) => !model.events.isEventCompleted(ev.id));
      if (!unfinishedEvents.length) return;
      switch (category) {
        case EventTriggerCategories.Cash:
          prevValue = this.prev.getGold();
          currValue = model.getGold();
          break;
        case EventTriggerCategories.BusinessValue:
          prevValue = this.prev.getBusinessValue();
          currValue = model.getBusinessValue();
          break;
        case EventTriggerCategories.ShopLevel:
          prevValue = this.prev.getShop().getLevel();
          currValue = model.getShop().getLevel();
          break;
        case EventTriggerCategories.Staff:
          prevValue = this.prev.allEmployees().length;
          currValue = model.allEmployees().length;
          break;
        case EventTriggerCategories.ProductLaunch:
          prevValue = this.prev.getShop().getProducts().length;
          currValue = model.getShop().getProducts().length;
          break;
        case EventTriggerCategories.LostSalePrice:
          prevValue = this.prev
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductTooExpensive);
          currValue = model
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductTooExpensive);
          break;
        case EventTriggerCategories.LostSaleStock:
          // Don't trigger this event before the user's bought a product.
          if (!model.getShop().getProducts().length) return;
          prevValue = this.prev
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductNotAvailable);
          currValue = model
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductNotAvailable);
          break;
        case EventTriggerCategories.LostSaleStaff:
          prevValue = this.prev
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.TillNotAvailable);
          currValue = model
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.TillNotAvailable);
          break;
        case EventTriggerCategories.LostSaleMarketing:
          prevValue = this.prev
            .getFinancialReportModel()
            .getAllFailedSales("no_marketing");
          currValue = model
            .getFinancialReportModel()
            .getAllFailedSales("no_marketing");
          break;
        case EventTriggerCategories.Payday:
          prevValue = this.prev.getPayday().getPaydayCount();
          currValue = model.getPayday().getPaydayCount();
          break;
        case EventTriggerCategories.Sales:
          prevValue = this.prev
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductPurchased);
          currValue = model
            .getFinancialReportModel()
            .getAllFailedSales(WalkAwayReason.ProductPurchased);
          break;
        case EventTriggerCategories.CanLaunchBy: {
          const prevCost = this.prev.getShop().nextProductCost;
          const currCost = model.getShop().nextProductCost;
          if (prevCost == undefined || currCost == undefined) return;
          prevValue = this.prev.getGold() - prevCost;
          currValue = model.getGold() - currCost;
          break;
        }
        case EventTriggerCategories.CanHireBy: {
          const prevCost = this.prev.getEmployeeHiringCost();
          const currCost = model.getEmployeeHiringCost();
          if (prevCost <= 0 || currCost <= 0) return;
          prevValue = this.prev.getGold() - prevCost;
          currValue = model.getGold() - currCost;
          break;
        }
        case EventTriggerCategories.Profit:
        case EventTriggerCategories.Loss:
          if (
            category === EventTriggerCategories.Profit &&
            model.getFinancialReportModel().profit <= 0
          )
            return;
          if (
            category === EventTriggerCategories.Loss &&
            model.getFinancialReportModel().profit >= 0
          )
            return;
          if (
            model.getPayday().getPaydayCount() ===
            this.prev.getPayday().getPaydayCount()
          )
            return;
          // We can't use prev payday as once you go past payday 3 the evaluation will break
          prevValue = 0;
          currValue = model.getPayday().getPaydayCount();
          break;
        case EventTriggerCategories.StaffIdleTime:
        default:
          return;
      }
      if (prevValue !== currValue) {
        this.checkEventCateogry(unfinishedEvents, model, prevValue, currValue);
      }
    });
    this.setModel(model);
  };

  private setModel = (model: GameModel) => {
    this.prev = GameModel.fromJSON(model.toJSON());
  };

  static onModelUpdate(model: GameModel) {
    if (!this.instance) {
      warn("[modelReducer] Model Reducer wasn't instantiated.");
      return;
    }
    this.instance.update(model);
  }

  static clearInstance() {
    if (__BUILD_ENV__ !== "TEST") {
      warn(
        "[modelReducer] The model reducer's static instance shouldn't be cleared outside of tests",
      );
      return;
    }
    this.instance = undefined;
  }
}
