import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages, getProductSpriteInfo } from "../data/images";
import { INeed, WalkAwayReason } from "../models/customer";
import { CostTextGameObject } from "./cost-text";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min.js";

const bubbleKey = "bubble.png";
const noStaffKey = "bubble_no_staff.png";
const prohibitedKey = "bubble_prohibited.png";
// Accurate Constants based on the asset
const speechBubbleHeightPx = 48;

export class ThoughtBubbleGameObject extends Phaser.GameObjects.Container {
  public bubble: Phaser.GameObjects.Image;
  private thoughtImage: Phaser.GameObjects.Image;
  private alertPip: Phaser.GameObjects.Image;
  private costText: CostTextGameObject;
  // Caching to prevent multiple-redraws of text or prohibited sign
  private reason: WalkAwayReason;
  private yCentreOffset: number;
  private juice: phaserJuice;
  private pulseJuice: phaserJuice;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    sheet: string,
    frame?: string,
  ) {
    super(scene);
    this.create(sheet, frame);
    this.setPosition(x, y - this.bubble.height / 2);
    this.setDepth(GameObjectDepth.ThoughtBubble);
    this.juice = new phaserJuice(this.scene);

    this.costText = new CostTextGameObject(
      scene,
      this.bubble.width / 4,
      this.bubble.height / 4,
    );
    this.costText.setDepth(GameObjectDepth.ThoughtBubble);
    this.add(this.costText);
    this.costText.setVisible(false);
  }

  setImageWithKeyAndFrame = (key: string, frame: string) => {
    if (this.thoughtImage.texture.key === key) {
      return;
    }
    this.thoughtImage.setTexture(key, frame);
  };

  setPayPipVisibility = (visible: boolean) => {
    if (this.alertPip.visible !== visible) {
      this.alertPip.setVisible(visible);
      this.pulse();
    }
  };

  private spawnProhibitedSign = () => {
    const sign = this.scene.add.image(
      0,
      this.yCentreOffset,
      GameImages.EmoticonAndThoughtBubbleSheet,
      prohibitedKey,
    );
    this.add(sign);
  };

  setImageFromWalkAwayReason = (reason: WalkAwayReason, need: INeed) => {
    if (reason === this.reason) return;
    this.reason = reason;
    switch (reason) {
      case WalkAwayReason.ProductPurchased:
        this.bubble.setVisible(false);
        this.thoughtImage.setVisible(false);
        this.costText.setCostAmount(need.purchasePrice);
        this.costText.setVisible(true);
        break;
      case WalkAwayReason.ProductNotAvailable:
        this.alertPip.setVisible(true);
        this.thoughtImage.setTexture(
          getProductSpriteInfo(need.kind).spriteKey,
          getProductSpriteInfo(need.kind).frame,
        );
        this.spawnProhibitedSign();
        this.costText.setVisible(false);
        return;
      case WalkAwayReason.TillNotAvailable:
        this.thoughtImage.setTexture(
          GameImages.EmoticonAndThoughtBubbleSheet,
          noStaffKey,
        );
        this.spawnProhibitedSign();
        this.costText.setVisible(false);
        return;
      case WalkAwayReason.ProductTooExpensive:
        this.thoughtImage.setTexture(GameImages.Coin);
        this.spawnProhibitedSign();
        this.costText.setVisible(false);
        return;
      case WalkAwayReason.Exception:
      default:
        return;
    }
  };

  create = (key: string, frame?: string) => {
    this.bubble = this.scene.add.image(
      0,
      0,
      GameImages.EmoticonAndThoughtBubbleSheet,
      bubbleKey,
    );
    this.add(this.bubble);
    this.yCentreOffset =
      this.bubble.getTopCenter().y + speechBubbleHeightPx / 2;

    this.thoughtImage = this.scene.add.image(0, this.yCentreOffset, key, frame);
    this.add(this.thoughtImage);

    this.alertPip = this.scene.add.image(
      this.bubble.getTopRight().x,
      this.bubble.getTopRight().y,
      GameImages.StockAndPayAlert,
    );
    this.alertPip.setVisible(false);
    this.add(this.alertPip);
  };

  public pulse() {
    this.alertPip.setScale(1);
    this.pulseJuice = this.juice.pulse(this.alertPip, {
      repeat: -1,
      duration: 500,
      scaleX: this.alertPip.scaleX * 1.2,
      scaleY: this.alertPip.scaleY * 1.2,
      ease: "Quad.easeInOut",
    });
  }
}
