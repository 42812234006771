import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { gameDialogStyles } from "../styles/game-dialog";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { playSoundEffect } from "../sound/sound-manager";
import { UIImages, hireEmloyeeDialogImages } from "../data/images";
import { translate } from "../translations/translations";
import "./popup-close-button";
import { popIn } from "../juice/juice";

@customElement("hire-dialog")
export class HireDialog extends LitElement {
  static styles = [
    popIn,
    gameDialogStyles,
    css`
      arrow-container {
        width: 224px;
        height: 144px;
        position: absolute;
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .header {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .header-text {
        display: flex;
        padding: 6px 6px 7px;
        font-size: 20px;
        font-weight: 700;
      }

      .content {
        width: 100%;
        height: 108px;
        display: flex;
        flex-direction: row;
      }

      .staff-img {
        width: 26px;
        height: 56px;
      }

      .staff-img-container {
        margin: 12px 14px 38px 18px;
      }

      .salary-text-container {
        margin-top: 24px;
        gap: 2px;
      }

      .salary-text {
        font-size: 14px;
        font-weight: 700;
      }

      .salary-text.subheading {
        font-weight: 500;
      }

      .salary-amount-container {
        display: flex;
        gap: 2px;
        margin-left: 12px;
        margin-top: 42px;
      }

      .coin-line-icon {
        width: 16px;
        height: 16px;
      }

      .hire-btn-container {
        display: flex;
        justify-content: center;
        transform: translate(0, -50%);
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  onHire = () => {
    this.dispatchEvent(new CustomEvent("accept"));
    playSoundEffect("Purchase");
  };

  protected firstUpdated(): void {
    playSoundEffect("PopIn");
  }

  render() {
    const employeeIndex = this.game.getModel().allEmployees().length - 1;

    const isBtnDisabled =
      this.game.getModel().getGold() <
      this.game.getModel().getEmployeeHiringCost();

    return html`
      <arrow-container smallGreen>
        <div class="header">
          <div class="header-text">${translate("HireStaff_HireStaff")}</div>
          <popup-close-button></popup-close-button>
        </div>
        <div class="content">
          <div class="staff-img-container">
            <img
              class="staff-img"
              src=${hireEmloyeeDialogImages[employeeIndex]}
              draggable="false"
            />
          </div>
          <div class="salary-text-container">
            <div class="salary-text">${translate("HireStaff_Heading")}</div>
            <div class="salary-text subheading">
              ${translate("HireStaff_Body")}
            </div>
          </div>
          <div class="salary-amount-container ">
            <img
              class="coin-line-icon"
              src=${UIImages.CoinLine}
              draggable="false"
            />
            <div class="salary-text">
              ${this.game.getModel().getEmployeeHiringCost()}
            </div>
          </div>
        </div>
        <div class="hire-btn-container">
          <app-button
            class="popIn game-button"
            ?shorter-height=${true}
            imgURL=${UIImages.CoinColor}
            text=${this.game.getModel().getEmployeeHiringCost()}
            @click=${this.onHire}
            ?disabled=${isBtnDisabled}
          ></app-button>
        </div>
      </arrow-container>
    `;
  }
}
