import { effect } from "@lit-labs/preact-signals";
import { init, setUser } from "@sentry/browser";
import { appState } from "../state/app-state";

export function initializeSentry() {
  init({
    dsn: "https://2ae6f34cb4d5d27c05f0bd556ba6621d@o393088.ingest.us.sentry.io/4506824621883392",
    tracesSampleRate: 0.1,
    environment: __BUILD_ENV__,
    release: __GIT_SHA__,
  });
}

effect(() => {
  if (appState.user.value && appState.user.value.userId) {
    setUser({
      id: appState.user.value.userId,
      email: appState.user.value.email,
    });
  }
});
