import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { Screen, setScreen } from "../state/app-state";
import { UIImages } from "../data/images";
import { classMap } from "lit/directives/class-map.js";
import { translate } from "../translations/translations";
import { borderStyles, buttonStyle } from "../styles/theme";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { RequestStatus, apiState } from "../state/api-state";
import { ConfettiJuice } from "./confetti-juice";
import { playSoundEffect } from "../sound/sound-manager";
import { refreshHighScore, saveManager, saveState } from "../state/save-state";
import { LottieAnimationJSONs } from "../data/images";
import "@lottiefiles/lottie-player";
import "./app-button";
import { toLeaderboard } from "../redirects";
import { popIn } from "../juice/juice";

@customElement("game-over")
export class GameOver extends SignalWatcher(LitElementI18n) {
  static styles = [
    popIn,
    borderStyles,
    buttonStyle,
    css`
      :host {
        position: relative;
        width: 100%;
        height: 100%;
        background: no-repeat center url("assets/game-over/endgame_bg.svg");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        --title-z-index: 0;
        --container-z-index: 1;
        --info-z-index: 2;
      }

      #main-container {
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;

        @media (min-width: 1000px) {
          gap: 57px;
        }
      }

      #center-container {
        position: relative;
        z-index: var(--container-z-index);
        height: 280px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 47px;

        @media (min-width: 1000px) {
          height: 312px;
        }
      }

      confetti-juice {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        margin: 0;
      }

      .game-over-img {
        position: absolute;
        top: 0;
        z-index: var(--title-z-index);
        width: 498px;
        height: 150px;

        @media (min-width: 1000px) {
          top: 8px;
        }
      }

      .score-container {
        position: relative;
        width: 236px;
        height: 66px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        border-radius: 4px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-white);
      }
      .score-container.is-animated {
        animation-name: popInAnimGameOver;
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(0.17, 0.67, 0.55, 1.35);
      }

      .score-container.high-score {
        color: var(--orange);
        border-radius: 4px;
        border: 2px solid var(--orange);
        background: no-repeat center
          url("assets/game-over/endgame_container_highscore.svg");
        background-color: var(--yellow-highlight);
        background-size: cover;
      }

      .value-pill {
        position: absolute;
        width: max-content;
        height: 22px;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        padding: 2px 6px 2px 34px;

        border-radius: 0px 11px 11px 0px;
        background: var(--primary-navy);

        color: var(--primary-white);
        text-align: center;
        font-family: Ubuntu;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .value-pill > * {
        flex-shrink: 1;
      }

      .value-icon {
        position: absolute;
        left: 0;
        top: -4px;

        flex-shrink: 0;
        width: 28px;
        height: 28px;
      }

      .score-pill {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .score-pill > i {
        font-weight: 400;
        font-style: normal;
      }

      .score-pill:not(.high-score) {
        width: max-content;
        padding: 0px 14px;
        border-radius: 14px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-teal-main);
      }

      .score-pill.high-score {
        width: 154px;
        height: 46px;
        color: var(--orange);
        background: no-repeat center/100%
          url("assets/game-over/endgame_pill_highscore.svg");
      }

      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      app-button {
        height: 44px;
        width: max-content;
      }

      /* Used to balance out the info-container in the main-container on desktop sizing, so the
      center-container stays in the center*/
      #info-container-balancer {
        height: 0;

        @media (min-width: 1000px) {
          height: 150px;
          flex-grow: 1;
          flex-shrink: 1;
        }
      }

      #info-container {
        position: absolute;
        left: 0;
        bottom: 0;
        min-height: 150px;
        width: 152px;

        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        @media (min-width: 1000px) {
          justify-content: start;
          position: relative;
          width: 248px;
          flex-grow: 1;
          flex-shrink: 1;
        }
      }

      .info-tab {
        box-sizing: border-box;
        padding: 8px;
        border-radius: 0px 4px 0px 0px;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 10px;

        background-color: var(--primary-white);

        color: var(--primary-navy, #16006d);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (min-width: 1000px) {
          border-radius: 4px;
        }
      }

      .info-tab > p {
        margin: 0;
      }

      .info-alert {
        height: 24px;
        position: absolute;
        top: -24px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        padding: 4px 8px;
        border-radius: 4px 4px 0px 0px;
        background: var(--ginger);

        color: var(--primary-white);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .info-pip {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }

      .character-img {
        width: 180px;
        height: 210px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .retry-popup {
        width: 354px;
        position: relative;
        background-color: var(--primary-white);
        border-radius: 6px;
      }

      .retry-popup-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px 4px 0px 0px;
        background: var(--primary-teal-main);
        width: 100%;
        height: 36px;
        flex-grow: 0;

        box-sizing: border-box;
        padding: 6px 8px;
      }

      .retry-popup-content {
        box-sizing: border-box;
        width: 100%;
        flex-grow: 1;
        border-radius: 0px 0px 4px 4px;
        padding: 14px 22px 36px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .retry-popup-buttons {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    `,
  ];

  @state()
  hasTriedToSubmit = false;

  @consume({ context: gameContext })
  game: GameController;

  @query("confetti-juice")
  confettiJuice: ConfettiJuice;

  isOffline = false;
  isLoading = false;
  currentScore = 0;
  highScore = 0;
  isHighScore = false;
  shouldAnimate = true;

  connectedCallback() {
    super.connectedCallback();
    if (saveState.highScore.value === null) {
      refreshHighScore();
    }
  }

  submit = () => {
    this.game.save();
    this.hasTriedToSubmit = true;
    this.confettiJuice.stop();
  };

  toLeaderboard = () => {
    this.confettiJuice.stop();
    toLeaderboard();
  };

  toMainMenu = () => {
    setScreen(Screen.MainMenu);
    this.confettiJuice.stop();
  };

  renderScoreText = (score: number): string => {
    return score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  renderButtons = () => {
    if (this.isOffline && this.isHighScore) {
      return html`
        <app-button @click=${this.submit}>
          ${translate("GameEnd_Button_SubmitNow")}
        </app-button>
        <app-button @click=${this.toMainMenu}>
          ${translate("GameEnd_Button_SubmitLater")}
        </app-button>
      `;
    }
    return html`
      <app-button class="button" @click=${this.toLeaderboard}>
        ${translate("GameEnd_Button_LeaderBoard")}
      </app-button>
      <app-button @click=${this.toMainMenu}>
        ${translate("GameEnd_Button_PlayAgain")}
      </app-button>
    `;
  };

  renderInfoTab = () => {
    if (this.isOffline) {
      return html`
        <div class="info-alert">
          ${translate("GameEnd_Offline_Heading")}
          <img class="info-pip" src=${UIImages.AlertPip} draggable="false" />
        </div>
        <div class="info-tab">
          <p>${translate("GameEnd_Offline_Offline_Body01")}</p>
          <p>${translate("GameEnd_Offline_Offline_Body02")}</p>
        </div>
      `;
    }
    if (this.isHighScore) {
      return html`
        <div class="info-tab">
          <p>${translate("GameEnd_Submitted_Body01")}</p>
          <p>${translate("GameEnd_Submitted_Body02")}</p>
        </div>
      `;
    }
    return html`
      <div class="info-tab">
        <p>${translate("GameEnd_WellDone")}</p>
      </div>
    `;
  };

  render() {
    this.highScore = saveState.highScore.value ?? 0;
    const localSave = saveManager.localSave();
    // Local save gets cleared after you submit successfully.
    // if that's the case, use the game's score
    this.currentScore =
      localSave?.score ?? this.game.getModel().getBusinessValue();
    this.isHighScore = this.currentScore >= this.highScore;
    const requestState = apiState.saveGameRequest.value;
    this.isOffline = requestState.status === RequestStatus.Failed;
    this.isLoading = requestState.status === RequestStatus.InProgress;

    if (this.hasTriedToSubmit && this.isHighScore) {
      return html`
        <div class="retry-popup border-normal">
          <div class="retry-popup-header">
            ${translate("GameEnd_Heading")}
            <img src=${UIImages.Cross_Active} draggable="false" />
          </div>
          <div class="retry-popup-content">
            <img src=${UIImages.AlertPip} draggable="false" />
            ${translate("GameEnd_Body")}
          </div>
          <div class="retry-popup-buttons">
            <app-button @click=${this.toMainMenu}>
              ${translate("GameEnd_Button_Later")}
            </app-button>
            <app-button @click=${this.submit} ?disabled=${this.isLoading}>
              ${translate("GameEnd_Button_TryAgain")}
            </app-button>
          </div>
        </div>
      `;
    }

    if (this.isLoading) {
      return html`
        <loading-spinner style="width: 160px; height: 160px;"></loading-spinner>
      `;
    }

    playSoundEffect("Success");
    return html`
      <confetti-juice autoplay></confetti-juice>
      <div id="main-container">
        <div id="info-container-balancer"></div>
        <div id="center-container">
          <div class="game-over-img">
            <lottie-player
              autoplay
              mode="normal"
              src=${LottieAnimationJSONs.GameOver}
              style="width: 100%; height: 100%;"
            ></lottie-player>
          </div>
          <div
            class=${classMap({
              "score-container": true,
              "high-score": this.isHighScore,
              "is-animated": this.shouldAnimate,
            })}
          >
            ${this.renderScoreText(this.currentScore)}
            <div class="value-pill">
              ${translate("GameEnd_BusinessValue")}
              <img
                class="value-icon"
                src=${UIImages.ValueGraph}
                draggable="false"
              />
            </div>
            <div
              class=${classMap({
                "score-pill": true,
                "high-score": this.isHighScore,
              })}
            >
              ${this.isHighScore
                ? translate("GameEnd_NewBest")
                : html`${translate("GameEnd_BestScore")}
                    <i>${this.renderScoreText(this.highScore)}</i>`}
            </div>
          </div>
          <div class="button-container">${this.renderButtons()}</div>
        </div>
        <div id="info-container">${this.renderInfoTab()}</div>
      </div>
      <img
        class="character-img"
        src=${UIImages.GameOver_MainCharacter}
        draggable="false"
      />
    `;
  }
}
