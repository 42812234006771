/* eslint-disable @typescript-eslint/no-explicit-any */

let doc: any;
let docElement: any;

let requestFullScreen: () => void;
let cancelFullScreen: () => void;

if (__BUILD_ENV__ !== "TEST") {
  doc = window.document;
  docElement = doc.documentElement;
  requestFullScreen =
    docElement.requestFullscreen ||
    docElement.mozRequestFullScreen ||
    docElement.webkitRequestFullScreen ||
    docElement.msRequestFullscreen;
  cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;
}

export function isFullScreen() {
  if (__BUILD_ENV__ === "TEST") return false;
  return (
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
}

// Note - not supported on iOS, where the fullscreen callbacks will be null.
// iPad 'supports' fullscreen, but it breaks the layout, and
// the touch-based fullscreen exit makes it unsuitable for games anyway.
// Source - https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
function iOS() {
  return (
    /iPad|iPhone|iPod|ipad|iphone|ipod/.test(navigator.userAgent) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function goFullscreen() {
  if (__BUILD_ENV__ === "TEST" || iOS() || isFullScreen()) return;
  requestFullScreen?.call(docElement);
}

export function exitFullscreen() {
  if (__BUILD_ENV__ === "TEST" || iOS() || !isFullScreen()) return;
  cancelFullScreen?.call(doc);
}
