import { css } from "lit";
import { LitElement, html } from "lit-element";
import { customElement } from "lit/decorators.js";

// Followed this tutorial for creating the component: https://medium.com/@blueyorange/make-a-tabs-web-component-in-litelement-using-slots-and-css-293c55dbd155
@customElement("tabbed-container")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TabbableComponent extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
    }
    nav {
      display: flex;
      align-items: flex-end;
      flex-grow: 0;
    }
    ::slotted([slot="tab"]) {
      flex: 1 1 auto;
      color: grey;
      text-align: center;
      margin: 0;
    }
    ::slotted([slot="tab"][selected]) {
      color: white;
    }
    ::slotted([slot="panel"]) {
      display: none;
    }
    ::slotted([slot="panel"][selected]) {
      display: block;
      width: 100%;
      height: 100%;
      flex-grow: 1;
    }
  `;

  tabs: HTMLSlotElement[];
  panels: HTMLSlotElement[];

  constructor() {
    super();
    this.tabs = Array.from(this.querySelectorAll("[slot=tab]"));
    this.panels = Array.from(this.querySelectorAll("[slot=panel]"));
    this.selectTab(0);
  }

  selectTab = (index: number) => {
    this.tabs.forEach((tab: HTMLSlotElement) =>
      tab.removeAttribute("selected"),
    );
    this.tabs[index].setAttribute("selected", "");
    this.panels.forEach((panel: HTMLSlotElement) =>
      panel.removeAttribute("selected"),
    );
    this.panels[index].setAttribute("selected", "");
  };

  handleSelectTab = (e: Event) => {
    const index = this.tabs.findIndex((tab) => tab === e.target);
    this.selectTab(index);
  };

  render() {
    return html`
      <nav>
        <slot name="tab" @click=${this.handleSelectTab}> </slot>
      </nav>
      <slot name="panel"></slot>
    `;
  }
}
