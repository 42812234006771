/* eslint-disable @typescript-eslint/no-explicit-any */
import { Signal, signal } from "@lit-labs/preact-signals";
import { IGameSave, IGameSaveData } from "../portal/type";
import { PortalAPI } from "../portal/api";
import { error, warn } from "../helpers/loggerHelper";
import { decrypt, encrypt } from "../hash";
import { FinalPaydayBusinessValuation } from "../analytics/analytics";

export enum RequestState {
  None = "none",
  Loading = "loading",
  Successful = "successful",
  Missing = "missing",
  Failed = "failed",
}

export interface ISaveState {
  highScore: Signal<number | null>;
  local: Signal<IGameSave | null>;
  cloud: Signal<IGameSave | null>;
  cloudRequestState: Signal<RequestState>;
}

const highScore = signal(null);
const local = signal(null);
const cloud = signal(null);
const cloudRequestState = signal(RequestState.None);

export const saveState: ISaveState = {
  highScore,
  local,
  cloud,
  cloudRequestState,
};

export const refreshHighScore = async () => {
  try {
    saveState.highScore.value = await PortalAPI.getUserHighScore();
  } catch (e) {
    error(e);
  }
};
// <===== SAVE MANAGER =====>

const SAVE_KEY = "game_save";

const getDate = (data: any) =>
  data?.timestamp ? new Date(Date.parse(data.timestamp)) : null;

const createGameSave = (data: IGameSaveData): null | IGameSave => {
  if (!data) {
    error("[save-state] createGameSave was passed a null data object", {
      data,
    });
    return null;
  }
  if (
    !data.game ||
    data.game["completed"] == undefined ||
    data.game["score"] == undefined
  ) {
    error("[save-state] createGameSave was passed an invalid data object", {
      data,
    });
    return null;
  }
  return {
    score: data.game.score,
    completed: data.game.completed,
    data,
  };
};

export class SaveManager {
  save = async (data: any) => {
    data.timestamp = new Date(Date.now()).toISOString();
    localStorage.setItem(SAVE_KEY, encrypt(JSON.stringify(data)));
    this.refreshLocalSave();
    try {
      const game: IGameSave | null = createGameSave(data);
      await PortalAPI.saveGame(game);
      // Just set the state instead of doing a refresh,
      // which would need to make a call to the backend.
      saveState.cloud.value = game;
      saveState.cloudRequestState.value = RequestState.Successful;
      refreshHighScore();
    } catch (e) {
      warn(`Failed to save game in the cloud: ${e.toString()}`);
    } finally {
      this.refreshCloudSave();
    }
  };

  cloudSave = (): IGameSave => saveState.cloud.value;
  localSave = (): IGameSave => saveState.local.value;

  onCloudSaveSubmission = (game: IGameSave) => {
    // When we successfully save to the cloud, the game is completed, and the local save hasn't been cleared yet,
    // We clear the local save and submit the final payday event.
    if (game.completed && localStorage.getItem(SAVE_KEY)) {
      localStorage.removeItem(SAVE_KEY);
      FinalPaydayBusinessValuation(
        game.score,
        game.data.game["financialReportModel"]?.["totalProfit"] ?? null,
      );
    }
    this.refreshLocalSave();
  };

  localSaveTimeStamp = (): Date | null => getDate(this.localSave()?.data);
  refreshLocalSave = async (): Promise<void> => {
    const storedData = localStorage.getItem(SAVE_KEY);
    if (storedData === null) {
      saveState.local.value = null;
      return;
    }
    try {
      saveState.local.value = createGameSave(JSON.parse(decrypt(storedData)));
    } catch {
      error("Failed to load local save file");
      saveState.local.value = null;
    }
  };

  cloudSaveRequestState = () => saveState.cloudRequestState.value;
  cloudSaveTimeStamp = (): Date | null => getDate(this.cloudSave()?.data);
  refreshCloudSave = async (): Promise<void> => {
    // Don't clear the current game data
    saveState.cloudRequestState.value = RequestState.Loading;

    try {
      const gameData = await PortalAPI.loadGame();
      saveState.cloud.value = gameData;
      saveState.cloudRequestState.value = !gameData
        ? RequestState.Missing
        : RequestState.Successful;
    } catch (e) {
      warn(`Failed to load the cloud save: ${e.toString()}`);
      saveState.cloud.value = null;
      saveState.cloudRequestState.value =
        getErrorStatus(e) === 404 ? RequestState.Missing : RequestState.Failed;
    }
  };
}

const getErrorStatus = (e: any): number | undefined =>
  Object.prototype.hasOwnProperty.call(e, "status") ? e.status : undefined;

export const saveManager = new SaveManager();
