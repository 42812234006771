import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages } from "../data/images";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min";

export class PointerJuice extends Phaser.GameObjects.Container {
  public pointer: Phaser.GameObjects.Image;
  private juice: phaserJuice;

  constructor(scene: Phaser.Scene) {
    super(scene);

    this.juice = new phaserJuice(this.scene);
    this.setDepth(GameObjectDepth.Product);

    this.pointer = this.scene.add.image(0, 0, GameImages.Pointer);
    this.add(this.pointer);
    this.pointer.setVisible(false);
  }

  destroy(fromScene?: boolean): void {
    super.destroy(fromScene);
  }

  public playJuice() {
    this.pointer.setVisible(true);
    this.juice.wobble(this.pointer, {
      x: -20,
      y: -20,
      repeat: -1,
      yoyo: false,
      ease: "Linear",
      duration: 500,
      onComplete: () => {
        this.pointer.setVisible(false);
      },
    });
  }
}
