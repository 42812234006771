import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { SpriteAnimationInfo } from "../types/spriteTypes";
import { CustomerEmote, EmployeeEmote } from "../types/emoteStates";
import { GameImages } from "../data/images";

const getAnimationFrame = (
  emote: CustomerEmote | EmployeeEmote,
  frame: number,
): Phaser.Types.Animations.AnimationFrame => {
  return {
    key: GameImages.EmoticonAndThoughtBubbleSheet,
    frame: `emote_${emote}_${frame}.png`,
    duration: 0.5,
    visible: true,
  };
};

const getEmoteSpriteInfo = (
  emote: CustomerEmote | EmployeeEmote,
): SpriteAnimationInfo => {
  return {
    spriteKey: GameImages.EmoticonAndThoughtBubbleSheet,
    frames: [getAnimationFrame(emote, 1), getAnimationFrame(emote, 2)],
  };
};

export class EmoteGameObject extends Phaser.GameObjects.Container {
  readonly width = 200;
  readonly height = 125;
  public emoteSprite?: Phaser.GameObjects.Sprite;

  private xPos: number;
  private yPos: number;

  constructor(scene: Phaser.Scene, width: number, height: number) {
    super(scene);

    this.xPos = width;
    this.yPos = height - 15;
    this.setDepth(GameObjectDepth.ThoughtBubble);
  }

  setEmoteReaction = (
    emote: CustomerEmote | EmployeeEmote,
    isEmployee: boolean,
  ) => {
    if (emote !== CustomerEmote.Happy && emote !== EmployeeEmote.Annoyed) {
      // Based on Sea Monster feedback, we are disabling all emotes except Happy and Annoyed.
      return;
    }
    const newSprite = getEmoteSpriteInfo(emote);

    if (
      this.emoteSprite &&
      (this.emoteSprite?.anims?.getFrameName() === newSprite.frames[0].frame ||
        this.emoteSprite?.anims?.getFrameName() === newSprite.frames[1].frame)
    ) {
      if (isEmployee) {
        this.replayEmote(newSprite);
      }
      return;
    }

    this.emoteSprite?.destroy();

    this.emoteSprite = this.scene.add.sprite(
      this.xPos,
      this.yPos,
      newSprite.spriteKey,
    );
    this.add(this.emoteSprite);

    const repeat = emote === CustomerEmote.Happy ? 2 : 0;

    this.emoteSprite.anims.create({
      key: newSprite.spriteKey,
      frameRate: 4,
      repeat,
      frames: newSprite.frames,
    });

    this.emoteSprite.anims.play(newSprite.spriteKey);
  };

  replayEmote = (spriteInfo: SpriteAnimationInfo) => {
    this.emoteSprite.anims.play(spriteInfo.spriteKey);
  };
}
