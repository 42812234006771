import Phaser, { GameObjects } from "phaser";
import { ThoughtBubbleGameObject } from "./thought-bubble";
import { GameImages, getProductSpriteInfo } from "../data/images";
import {
  Customer,
  CustomerState,
  WalkAwayReason,
  INeed,
} from "../models/customer";
import { GameObjectDepth } from "../data/game-objects";
import { customerDirectionHelper } from "../helpers/directionHelper";
import { SpriteDirection } from "../types/spriteDirection";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min.js";
import { EmoteGameObject } from "./emote";
import { CustomerEmote } from "../types/emoteStates";
import { CostTextGameObject } from "./cost-text";

export class CustomerGameObject extends Phaser.GameObjects.Container {
  id: string;
  spriteIndex: number;
  spriteBody: Phaser.GameObjects.Image;
  thoughtBubble: ThoughtBubbleGameObject;
  emote: EmoteGameObject;

  private prevX: number;
  private prevY: number;

  private juice: phaserJuice;
  private bobMovement: phaserJuice;

  private emoteState: CustomerEmote;

  private costText: CostTextGameObject;

  private need: INeed;

  private particleEmitter: GameObjects.Particles.ParticleEmitter;

  constructor(scene: Phaser.Scene, model: Customer) {
    super(scene);
    this.id = model.id;
    this.create(model);
    this.setDepth(GameObjectDepth.Customer);
    this.juice = new phaserJuice(this.scene);

    this.particleEmitter = this.scene.add.particles(
      this.spriteBody.getCenter().x,
      this.spriteBody.getCenter().y,
      GameImages.Coin,
      {
        duration: 2000,
        lifespan: { min: 1000, max: 2000 },
        speed: { min: 150, max: 200 },
        scale: { start: 2, end: 0, ease: "Cubic.easeOut" },
        gravityY: 200,
        gravityX: 0,
        emitting: false,
        alpha: { start: 1, end: 0 },
      },
    );
    this.particleEmitter.setDepth(GameObjectDepth.CoinJuice);

    this.bobJuice();
    this.updateWithModel(model);
  }

  updateWithModel = (customer: Customer) => {
    if (!customer) {
      return;
    }

    this.setPosition(customer.getX(), customer.getY());

    // SPRITE DIRECTION
    const direction = customerDirectionHelper(
      this.prevX,
      this.prevY,
      customer.getX(),
      customer.getY(),
    );

    this.updateCustomerDirection(direction);

    // BOB MOVEMENT
    if (this.prevX === customer.getX() && this.prevY === customer.getY()) {
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).pause();
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).restart();
    } else {
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).resume();
    }

    // REACTIONS: THOUGHT BUBBLE + EMOTE
    this.updateCustomerReaction(customer);

    if (this.need !== customer.need) {
      this.need = customer.need;
    }

    this.prevX = customer.getX();
    this.prevY = customer.getY();
  };

  private updateCustomerReaction = (customer: Customer) => {
    // SET THOUGHT BUBBLE IMAGE + EMOTE
    if (customer.getState() === CustomerState.WalkingAway) {
      this.setEmoteReaction(customer.getWalkAwayReason());

      this.thoughtBubble.setImageFromWalkAwayReason(
        customer.getWalkAwayReason(),
        customer.need,
      );
    }

    // SET VISIBILITY
    if (
      this.emote.emoteSprite?.anims?.isPlaying &&
      this.thoughtBubble.visible
    ) {
      this.thoughtBubble.setVisible(false);
      this.emote.setVisible(true);
      if (
        this.emote.visible &&
        customer.getWalkAwayReason() === WalkAwayReason.ProductPurchased
      ) {
        this.coinJuice();
      }
    } else if (
      !this.emote.emoteSprite?.anims?.isPlaying &&
      !this.thoughtBubble.visible
    ) {
      this.thoughtBubble.setVisible(true);
      this.emote.setVisible(false);
    }
  };

  private setEmoteReaction(walkAwayReason: WalkAwayReason) {
    if (
      this.emoteState !== this.getEmoteStateFromWalkAwayReason(walkAwayReason)
    ) {
      this.emoteState = this.getEmoteStateFromWalkAwayReason(walkAwayReason);
      this.emote.setEmoteReaction(this.emoteState, false);
    }
  }

  private updateCustomerDirection = (direction: SpriteDirection) => {
    if (direction === SpriteDirection.Left) {
      this.spriteBody
        .setTexture(GameImages.CustomerSheet, this.getFrame("side"))
        .setScale(1, 1);
    } else if (direction === SpriteDirection.Right) {
      this.spriteBody
        .setTexture(GameImages.CustomerSheet, this.getFrame("side"))
        .setScale(-1, 1);
    } else {
      this.spriteBody
        .setTexture(GameImages.CustomerSheet, this.getFrame(direction))
        .setScale(1, 1);
    }
  };

  private create = (model: Customer) => {
    this.prevX = model.getX();
    this.prevY = model.getYPath();

    // Setting the depth doesn't seem to have any effect on objects within a container
    // The order in which they are created becomes important - shadow needs to be created first
    const shadow = this.scene.add.image(
      0,
      0,
      GameImages.CustomerSheet,
      "Shadow.png",
    );
    this.add(shadow);

    this.spriteIndex = Phaser.Math.RND.between(0, 19);
    this.spriteBody = this.scene.add
      .image(0, 0, GameImages.CustomerSheet, this.getFrame("front"))
      .setOrigin(0.5);
    this.add(this.spriteBody);

    const { spriteKey, frame } = getProductSpriteInfo(model.need.kind);

    this.thoughtBubble = new ThoughtBubbleGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y,
      spriteKey,
      frame,
    );
    this.add(this.thoughtBubble);

    this.emote = new EmoteGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y,
    );
    this.add(this.emote);
  };

  private getFrame(direction: "front" | "back" | "side") {
    return `customer_${this.spriteIndex}_${direction}.png`;
  }

  public bobJuice() {
    this.bobMovement = this.juice.wobble(this.spriteBody, {
      x: 0,
      y: -10,
      repeat: -1,
      yoyo: true,
      angle: -45,
      ease: "linear",
      duration: 200,
    });
  }

  getEmoteStateFromWalkAwayReason = (reason: WalkAwayReason) => {
    switch (reason) {
      case WalkAwayReason.ProductNotAvailable:
        return CustomerEmote.Sad;
      case WalkAwayReason.ProductTooExpensive:
        return CustomerEmote.Shocked;
      case WalkAwayReason.TillNotAvailable: // no staff
        return CustomerEmote.Frustrated;
      case WalkAwayReason.ProductPurchased:
      default:
        return CustomerEmote.Happy;
    }
  };

  public coinJuice() {
    if (!this.need) {
      return;
    }

    this.particleEmitter.setPosition(this.x, this.y);
    this.particleEmitter.explode(5);

    const endYOffset = 12.5;
    const xOffset = 10;

    const currentCustomerX = this.x + xOffset;
    const currentCustomerY = this.y;

    this.costText = new CostTextGameObject(
      this.scene,
      currentCustomerX,
      currentCustomerY,
    );
    this.costText.setDepth(GameObjectDepth.CoinJuiceText);
    this.scene.add.existing(this.costText);
    this.costText.setCostAmount(this.need.purchasePrice);

    const floatUpTweenConfig = {
      targets: this.costText,
      y: {
        start: currentCustomerY,
        to: currentCustomerY - endYOffset,
      },
      x: currentCustomerX,
      ease: "easeInOut",
      duration: 400,
      repeat: 0,
      yoyo: false,
    };

    const fadeOutTweenConfig = {
      targets: this.costText,
      alpha: 0,
      duration: 150,
      delay: 450,
    };

    const floatUpTween = this.scene.tweens.add(floatUpTweenConfig);
    const fadeOutTween = this.scene.tweens.add(fadeOutTweenConfig);

    floatUpTween.play();
    fadeOutTween.play();
  }

  destroy(fromScene?: boolean): void {
    if (this.costText) {
      this.costText.destroy(fromScene);
    }
    super.destroy(fromScene);
  }
}
