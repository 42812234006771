import {
  CoffeeShopProducts,
  FashionProducts,
  StationeryProducts,
  TechnologyProducts,
  ToiletriesProducts,
} from "../data/images";
import { ProductGroup } from "../data/products";

export const getProductImage = (
  index: number,
  businessType: ProductGroup,
): string => {
  switch (businessType) {
    case ProductGroup.CoffeeShop:
      return CoffeeShopProducts[index];
    case ProductGroup.Clothing:
      return FashionProducts[index];
    case ProductGroup.Toiletries:
      return ToiletriesProducts[index];
    case ProductGroup.Technology:
      return TechnologyProducts[index];
    case ProductGroup.Stationary:
      return StationeryProducts[index];
  }
};
