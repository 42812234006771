import { html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { fontStyles } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { popIn } from "../juice/juice";
import "./dialog-arrow";

@customElement("intro-flow-character-dialog")
export class IntroFlowCharacterDialog extends SignalWatcher(LitElementI18n) {
  static styles = [
    fontStyles,
    popIn,
    css`
      .dialogue-content {
        display: flex;
        flex-direction: row;
        gap: 36px;
        position: relative;
      }

      .character-img {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 2px solid var(--primary-navy);
        background: var(--yellow);
        overflow: hidden;
        @media (min-width: 1000px) {
          width: 240px;
          height: 240px;
          border: 3px solid var(--primary-navy);
        }
      }
      .character-img > img {
        width: 100%;
        height: 100%;
      }

      .speech-bubble-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }

      .speech-bubble {
        width: 273px;
        border-radius: 8px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-white);
        padding: 12px;
        margin-bottom: 31px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        flex-direction: column;
        place-content: center;
        @media (min-width: 1000px) {
          padding: 18px;
          width: 410px;
          margin-bottom: 46.5px;
          border-radius: 10px;
          border: 3px solid var(--primary-navy);
        }
      }

      .no-margin {
        margin: 0;
      }

      .body-spacing {
        margin-bottom: 12px;
        @media (min-width: 1000px) {
          margin-bottom: 18px;
        }
      }

      .next-btn {
        position: absolute;
        right: 0;
        bottom: -5px;
        transform: translateY(50%);
        transform-origin: center;
      }
    `,
  ];

  @property()
  image: string;
  @property()
  body1: string;
  @property()
  body2: string;
  @property()
  nextText: string;

  onNextClicked = () => {
    this.dispatchEvent(new Event("next"));
  };

  render() {
    return html`
      <div class="dialogue-content">
        <div class="character-img">
          <img src=${this.image} draggable="false" />
        </div>
        <div class="speech-bubble-container popIn">
          <div class="speech-bubble">
            <p class="body-copy no-margin body-spacing">${this.body1}</p>
            <p class="body-copy no-margin">${this.body2}</p>
            <dialog-arrow direction="left"></dialog-arrow>
          </div>
        </div>
        <app-button
          class="next-btn popIn ui-button-dialogue"
          @click=${this.onNextClicked}
          text=${this.nextText}
        >
        </app-button>
      </div>
    `;
  }
}
