import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { borderStyles, buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { playSoundEffect } from "../sound/sound-manager";
import { gameState } from "../state/game-state";
import { translate } from "../translations/translations";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import "./business-value";
import "./close-button-fin-report";
import "./market-research";
import "./profit-loss";
import "./tabbed-container";
import { popIn } from "../juice/juice";

@customElement("financial-report")
export class FinancialReport extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    borderStyles,
    popIn,
    css`
      :host {
        max-width: 100%;
        margin-left: 72px;
        margin-right: 11px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: var(--z-index-financial-report);

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        color: var(--primary-navy);
      }

      .report-root {
        width: 588px;
        height: 288px;

        overflow: hidden;

        box-sizing: border-box;
        background-color: var(--primary-teal-main);
        display: flex;
        position: relative;
        flex-direction: column;

        @media (min-width: 1000px) {
          width: 824px;
          height: 382px;
        }
      }

      .flex-col {
        display: flex;
        flex-direction: column;
      }
      .no-grow {
        flex-grow: 0;
      }
      .grow {
        flex-grow: 1;
      }

      .header {
        height: 39px;
        width: 100%;
        flex-grow: 0;

        display: flex;
        flex-direction: row;
        align-items: center;

        @media (min-width: 1000px) {
          height: 54px;
        }
      }

      .header-text {
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        line-height: normal;
      }

      .header-img {
        width: 28px;
        height: 28px;
        padding-left: 10px;
        padding-right: 6px;
      }

      close-button-fin-report {
        padding-right: 9px;
        @media (min-width: 1000px) {
          padding-right: 11px;
        }
      }

      .content {
        width: 100%;
        flex-grow: 1;
      }

      .tab-header {
        height: 22px;
        border-radius: 4px 4px 0 0;

        display: flex;
        place-items: center;
        place-content: center;

        font-size: 12px;
        font-weight: 500;
        color: var(--primary-white);

        @media (min-width: 1000px) {
          height: 26px;
          font-size: 16px;
        }
      }

      .tab-header[selected] {
        height: 28px;
        border-radius: 6px 6px 0 0;

        font-size: 14px;
        font-weight: 700;
        color: var(--primary-navy);

        @media (min-width: 1000px) {
          height: 32px;
          font-size: 18px;
        }
      }

      .tab-0.tab-header {
        background-color: var(--ginger-background);
      }

      .tab-0.tab-header[selected] {
        background-color: var(--ginger);
      }

      .tab-1.tab-header {
        background-color: var(--yellow-highlight);
      }

      .tab-1.tab-header[selected] {
        background-color: var(--yellow-lowlight);
      }

      .tab-2.tab-header {
        background-color: var(--violet-background);
      }

      .tab-2.tab-header[selected] {
        color: var(--primary-white);
        background-color: var(--violet-lowlight);
      }

      .tab-panel {
        overflow: hidden;
        background-color: white;
        border: var(--border-width) solid black;
        border-radius: 0 0 calc(var(--border-radius) - var(--border-width))
          calc(var(--border-radius) - var(--border-width));
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }

      .tab-0.tab-panel {
        background-color: var(--ginger-highlight);
        border-color: var(--ginger);
      }
      .tab-1.tab-panel {
        background-color: var(--yellow-highlight);
        border-color: var(--yellow-lowlight);
      }
      .tab-2.tab-panel {
        background-color: var(--violet-background);
        border-color: var(--violet-lowlight);
      }
    `,
  ];

  @consume({ context: gameContext })
  gameController: GameController;

  tabClicked() {
    playSoundEffect("Swipe");
  }

  onFinancialReportClose = (e: InputEvent) => {
    e.preventDefault();
    this.gameController.dismissFinancialReport();
  };

  parseTitle = (text: string, paydayCount: number) => {
    return text.replace("%x", `${paydayCount + 1}`).replace("%y", "12");
  };

  firstUpdated() {
    playSoundEffect("Report");
  }

  render() {
    return html`
      <div class="report-root flex-col border-normal popIn">
        <div class="header">
          <img
            src=${UIImages.Clock}
            class="header-img no-grow"
            draggable="false"
          />
          <div class="header-text grow">
            ${this.parseTitle(
              translate("FinancialReport_Payday"),
              gameState.paydayCount.value,
            )}
          </div>
          <close-button-fin-report
            class="no-grow"
            @click=${this.onFinancialReportClose}
          ></close-button-fin-report>
        </div>
        <div class="content rounded-b">
          <tabbed-container>
            <h2 class="tab-0 tab-header" slot="tab" @click=${this.tabClicked}>
              ${translate("FinancialReport_BV_Tab_Heading")}
            </h2>
            <div class="tab-0 tab-panel" slot="panel">
              <business-value></business-value>
            </div>
            <h2 class="tab-1 tab-header" slot="tab" @click=${this.tabClicked}>
              ${translate("FinancialReport_Profit_Tab_Heading")}
            </h2>
            <div class="tab-1 tab-panel" slot="panel">
              <profit-loss></profit-loss>
            </div>
            <h2 class="tab-2 tab-header" slot="tab" @click=${this.tabClicked}>
              ${translate("FinancialReport_MarketResearch_Tab_Heading")}
            </h2>
            <section class="tab-2 tab-panel" slot="panel">
              <market-research></market-research>
            </section>
          </tabbed-container>
        </div>
      </div>
    `;
  }
}
