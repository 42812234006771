import sjcl from "sjcl";

export const sha256 = (text: string): string => {
  const hashBitArray = sjcl.hash.sha256.hash(text);
  return sjcl.codec.hex.fromBits(hashBitArray);
};

const p = "CB6W7R2envJfkXW4NEjT";
export const encrypt = (text: string): string =>
  sjcl.encrypt(p, text).toString();
export const decrypt = (encrypted: string): string =>
  sjcl.decrypt(p, encrypted);
