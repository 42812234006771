import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, query } from "lit/decorators.js";
import Phaser from "phaser";
import GameScene from "../scenes/GameScene";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameView } from "../views/gameView";
import { GameController } from "../controllers/gameController";
import phaserConfig from "../config/phaserConfig";

@customElement("phaser-game")
export class PhaserGame extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    #phaser-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  `;

  phaserConfig: Phaser.Types.Core.GameConfig;
  phaserGame: Phaser.Game;

  @query("#phaser-canvas")
  phaserCanvas: HTMLCanvasElement;

  @query("#phaser-wrapper")
  phaserWrapper: HTMLDivElement;

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.loadPhaser();
  }

  @consume({ context: gameContext })
  game: GameController;

  handleResize = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;

    // Always the canvas in landscape orientation
    if (h > w) {
      [w, h] = [h, w];
    }

    const newWidth = w * window.devicePixelRatio;
    const newHeight = h * window.devicePixelRatio;

    this.phaserGame.scale.resize(newWidth, newHeight);

    this.phaserWrapper.style.width = `${w}px`;
    this.phaserWrapper.style.height = `${h}px`;

    this.phaserGame.scale.canvas.width = newWidth;
    this.phaserGame.scale.canvas.height = newHeight;

    // css width and height of the canvas (displayed sized)
    // the canvas is scaled down
    this.phaserGame.scale.canvas.style.width = `${w}px`;
    this.phaserGame.scale.canvas.style.height = `${h}px`;
  };

  onResize = () => {
    this.handleResize();
    setTimeout(() => {
      // Resize debounce for iOS devices
      this.handleResize();
    }, 500);
  };

  loadPhaser() {
    const scene = new GameScene(this.game, () => {
      // NOTE: Have to use this callback, since the scene.events
      // is still undefined right after construction. Would be great
      // to just call scene.events.on("ready", <fn>)
      this.game.setView(new GameView(scene));
    });

    this.phaserConfig = {
      ...phaserConfig,
      canvas: this.phaserCanvas,
      scene: scene,
      scale: {
        mode: Phaser.Scale.NONE,
      },
    };

    this.phaserGame = new Phaser.Game(this.phaserConfig);

    // Set initial size of the canvas
    this.handleResize();

    window.addEventListener("resize", this.onResize);
    document.addEventListener("fullscreenchange", this.onResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("resize", this.onResize);
    document.removeEventListener("fullscreenchange", this.onResize);
  }

  render() {
    return html`
      <div id="phaser-wrapper">
        <canvas id="phaser-canvas"></canvas>
      </div>
    `;
  }
}
