import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import "./loading-spinner";
import { UIImages } from "../data/images";
import { translate } from "../translations/translations";

@customElement("loading-screen")
export class LoadingScreen extends LitElementI18n {
  static styles = [
    buttonStyle,
    css`
      @keyframes tileLoop {
        0% {
          background-position: 0px 0px;
        }
        100% {
          background-position: 500px 500px;
        }
      }

      :host {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 34px;
        background: url("assets/tiles/BG_pattern_transparent.png") repeat 0 0;
        background-size: 500px 500px;
        background-color: var(--primary);
        animation: tileLoop 15s infinite;
        animation-timing-function: linear;
      }

      .loader-logo {
        width: 216px;
        height: 96px;
      }

      .secondary-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 4px;

        border-radius: 59px;
        border: 2px solid var(--primary-teal-main);
        background-color: var(--primary-teal-highlight);

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      .loader-text {
        width: 105px;
      }

      loading-spinner {
        width: 26px;
        height: 28px;
      }
    `,
  ];

  render() {
    return html`
      <img class="loader-logo" src=${UIImages.LogoLoader} draggable="false" />
      <div class="secondary-button">
        <loading-spinner></loading-spinner>
        <div class="loader-text">${translate("LoadingScreen_Loading")}</div>
      </div>
    `;
  }
}
