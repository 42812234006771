import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { borderStyles } from "../styles/theme";
import { popIn } from "../juice/juice";
import { UIImages } from "../data/images";
@customElement("business-value-tracker")
export class BusinessValueTracker extends LitElement {
  static styles = [
    borderStyles,
    popIn,
    css`
      button {
        background: var(--primary);
        box-shadow: 0px -4px 0px 0px var(--primary-lowlight) inset;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        width: 100%;
        height: 100%;

        padding-bottom: 4px;
      }

      button:hover {
        cursor: pointer;
      }

      button:active {
        background: var(--primary-lowlight);
        box-shadow: none;
      }

      button:disabled {
        background: var(--button-disabled-primary);
        border: 2px solid var(--button-disabled-lowlight);
        box-shadow: 0px -4px 0px 0px var(--button-disabled-lowlight) inset;
      }

      .content-wrapper {
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px;

        display: flex;
        align-items: center;

        width: 100%;
        height: 100%;
      }
    `,
  ];

  clickHandler = () => {
    this.dispatchEvent(
      new Event("open", {
        bubbles: true,
        composed: true,
      }),
    );
  };

  render() {
    return html`
      <button @click=${this.clickHandler} class="border-normal popIn">
        <div class="content-wrapper">
          <img src=${UIImages.ValueGraph} height="28px" draggable="false" />
          <div>Business Value</div>
        </div>
      </button>
    `;
  }
}
