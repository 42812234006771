import { css } from "lit";

export const borderStyles = css`
  .border-normal {
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--primary-navy);
  }

  .rounded-t {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  .rounded-b {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
`;

export const removeGenericBtnStyles = css`
  .remove-btn-style {
    padding: 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const blockPointerEvents = css`
  .block-pointers {
    pointer-events: auto;
  }
`;

export const buttonStyle = css`
  button {
    font-family: inherit;
    font-size: 20px;

    border: none;
    border-radius: 3px;
    background-color: var(--normal);
    color: #000000;
    cursor: pointer;
  }
  button:hover {
    filter: brightness(1.1);
  }
  button.primary {
    background-color: var(--primary);
    color: #ffffff;
  }
  button.secondary {
    background-color: var(--secondary);
    color: #ffffff;
  }
  button:disabled {
    background-color: gray;
    color: lightgray;
  }
`;

export const greenContainer = css`
  .green-container {
    border-radius: 6px;
    background: var(--primary);
    padding: 4px;
    box-shadow: 0px -4px 0px 0px var(--primary-lowlight) inset;
    border: 2px solid var(--primary-navy);
  }
`;

export const fontStyles = css`
  .heading-1 {
    /* Heading 1 */
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    @media (min-width: 1000px) {
      font-size: 30px;
    }
  }
  .body-copy {
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (min-width: 1000px) {
      font-size: 21px;
    }
  }

  .skip-intro-copy {
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
