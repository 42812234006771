import { css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { buttonStyle, removeGenericBtnStyles } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { FinancialReportModel } from "../models/financialReportModel";
import { ProductInfo } from "../models/financialReportModel";
import { translate } from "../translations/translations";
import { UIImages } from "../data/images";

@customElement("market-research")
export class MarketResearch extends LitElementI18n {
  static styles = [
    buttonStyle,
    removeGenericBtnStyles,
    css`
      :host {
        width: 100%;
        height: 100%;
      }

      .container {
        background-color: var(--marketResearch-lighter-purple);
        padding: 0 17px 16px 17px;
        box-sizing: border-box;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;

        @media (min-width: 1000px) {
          padding: 0 52px 30px 52px;
        }
      }

      .top {
        flex-grow: 1;
        background-color: var(--bg-color);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        box-sizing: border-box;
        padding: 4px 0;
        width: 100%;

        font-size: 16px;
        font-weight: 500;
        @media (min-width: 1000px) {
          font-size: 18px;
          font-weight: 700;
        }
      }

      .top-text {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
      }

      .product-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 160px;
      }

      .product-select {
        background-color: var(--secondary-violet-highlight);
        border-radius: 6px;
        border: 2px solid var(--primary-navy);

        width: 100%;
        height: 30px;
        box-sizing: border-box;

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .product-select > img {
        height: 20px;
        width: 20px;
      }

      .dropdown-background {
        background-color: var(--primary-white);
        border-radius: 6px;
        border: 2px solid var(--primary-navy);

        position: absolute;
        z-index: 10;
        bottom: 0;
        transform: translateY(100%);

        height: fit-content;
        max-height: 110px;
        width: 100%;
        box-sizing: border-box;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;

        padding: 6px;

        overflow-y: scroll;

        scrollbar-color: var(--primary-navy) transparent;
        scrollbar-width: thin;
      }

      .dropdown-background::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
      }

      .dropdown-background::-webkit-scrollbar-thumb {
        background-color: var(--primary-navy);
        border-radius: 10px;
      }

      .dropdown-container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .dropdown-button {
        height: 16px !important;
        padding: 4px;
        box-sizing: border-box;

        flex: 0 0;

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;

        overflow: hidden;
      }

      .dropdown-button:hover {
        border-radius: 6px;
        background-color: var(--marketResearch-light-purple);
      }

      .filter-clear {
        display: flex;
        height: 30px;
        box-sizing: border-box;
        padding: 0px 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 59px;
        background-color: var(--secondary-violet-highlight);

        color: var(--primary-navy);
        font-size: 14px;
        font-weight: 400;
      }

      .filter-clear > img {
        width: 16px;
        height: 16px;
      }

      .bottom {
        @media (min-width: 1000px) {
          gap: 50px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
      }

      .marketing-panel {
        position: relative;
        width: 114px;
        height: 140px;

        border-radius: 6px;
        border: 2px solid var(--primary-navy);

        display: flex;
        flex-direction: column;
        @media (min-width: 1000px) {
          width: 140px;
          height: 176px;
        }
      }

      .panel-header {
        background-color: var(--secondary-violet-highlight);
        padding: 4px;
        box-sizing: border-box;

        border-radius: 4px 4px 0 0;

        display: flex;
        place-items: center;
        place-content: center;
        gap: 4px;

        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }

      .panel-body {
        background-color: var(--primary-white);
        border-radius: 0 0 4px 4px;

        flex-grow: 1;
        display: flex;
        justify-content: center;
      }

      .panel-body > img {
        flex-grow: 1;
      }

      .panel-number {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);

        display: flex;
        width: 84px;
        justify-content: center;
        align-items: center;

        border-radius: 10px;
        background: var(--violet-lowlight);

        color: var(--primary-white);
        text-align: center;
        font-size: 12px;
        font-weight: 500;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  financialReportModel: FinancialReportModel;
  totalProductInfo: ProductInfo;

  totalNumberOfCustomers: number = 0;

  @state()
  currentProductKey?: string;

  @state()
  isDropdownOpen: boolean = false;

  productStrings: string[][];

  connectedCallback(): void {
    super.connectedCallback();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
    const { productInfo } = this.financialReportModel;
    this.totalProductInfo = this.financialReportModel.getTotalProductInfo();
    Object.values(productInfo).forEach((info) => {
      this.totalNumberOfCustomers += this.financialReportModel.getCustomerCount(
        info.kind,
      );
    });
    this.productStrings = this.game
      .getModel()
      .getShop()
      .getProducts()
      .map((p) => [p.kind.key(), p.kind.productName()]);
  }

  renderSaleTotal = (productInfo: Partial<ProductInfo>) => {
    const total = this.currentProductKey
      ? this.financialReportModel.getCustomerCount(productInfo.kind)
      : this.totalNumberOfCustomers;
    return translate("FinancialReport_MR_Tab_XY")
      .replace("%x", `${productInfo.product_purchased}`)
      .replace("%y", `${total}`);
  };

  private renderDropdown = (category: string) => {
    if (this.currentProductKey) {
      return html`
        <button
          class="filter-clear"
          @click=${() => (this.currentProductKey = "")}
        >
          ${category}
          <img src=${UIImages.Cross_Active} />
        </button>
      `;
    }
    const content = this.isDropdownOpen
      ? html`
          <div class="dropdown-background">
            <div class="dropdown-container">
              ${this.productStrings.map(([key, text]) => {
                const onClick = () => {
                  this.isDropdownOpen = false;
                  this.currentProductKey = key;
                };
                return html`
                  <button
                    class="remove-btn-style dropdown-button"
                    @click=${onClick}
                  >
                    ${text}
                  </button>
                `;
              })}
            </div>
          </div>
        `
      : nothing;
    const onClick = () => {
      this.isDropdownOpen = !this.isDropdownOpen;
    };
    return html`
      <div class="product-container">
        <button class="product-select" @click=${onClick}>
          ${category}
          <img
            src=${this.isDropdownOpen
              ? UIImages.Cross_Active
              : UIImages.Chevron_Down_Active}
          />
        </button>
        ${content}
      </div>
    `;
  };

  render() {
    const productInfo = this.currentProductKey
      ? this.financialReportModel.productInfo[this.currentProductKey]
      : this.totalProductInfo;
    const categoryString = this.currentProductKey
      ? this.financialReportModel.productInfo[
          this.currentProductKey
        ].kind.productName()
      : translate("FinancialReport_MR_Tab_Default");

    return html`
      <div class="container">
        <div class="top">
          <div class="top-text">
            ${translate("FinancialReport_MR_Tab_NumberOfSales")}
            ${this.renderSaleTotal(productInfo)}
          </div>
          <div class="top-text">
            <div>${translate("FinancialReport_MR_Tab_Filter")}</div>
            ${this.renderDropdown(categoryString)}
          </div>
        </div>
        <div class="bottom">
          <div class="marketing-panel">
            <div class="panel-header">
              <tip-button key="Tip_MissedSalesPriceTooHigh"></tip-button>
              ${translate("FinancialReport_MR_Tab_PriceTooHigh")}
            </div>
            <div class="panel-body">
              <img
                src=${UIImages.Report_Portrait_Expensive}
                draggable="false"
              />
            </div>
            <div class="panel-number">${productInfo.product_too_expensive}</div>
          </div>
          <div class="marketing-panel">
            <div class="panel-header">
              <tip-button key="Tip_MissedSalesNoStaffAvailable"></tip-button>
              ${translate("FinancialReport_MR_Tab_NoStaff")}
            </div>
            <div class="panel-body">
              <img src=${UIImages.Report_Portrait_Staff} draggable="false" />
            </div>
            <div class="panel-number">${productInfo.till_not_available}</div>
          </div>
          <div class="marketing-panel">
            <div class="panel-header">
              <tip-button key="Tip_MissedSaleLackOfMarketing"></tip-button>
              ${translate("FinancialReport_MR_Tab_Lack")}
            </div>
            <div class="panel-body">
              <img
                src=${UIImages.Report_Portrait_Marketing}
                draggable="false"
              />
            </div>
            <div class="panel-number">
              ${Math.floor(productInfo.no_marketing)}
            </div>
          </div>
          <div class="marketing-panel">
            <div class="panel-header">
              <tip-button key="Tip_MissedSaleOutofStock"></tip-button>
              ${translate("FinancialReport_MR_Tab_OutOfStock")}
            </div>
            <div class="panel-body">
              <img src=${UIImages.Report_Portrait_Stock} draggable="false" />
            </div>
            <div class="panel-number">${productInfo.product_not_available}</div>
          </div>
        </div>
      </div>
    `;
  }
}
