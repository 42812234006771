import { css, html, LitElement } from "lit";
import { consume } from "@lit/context";
import { customElement, property, state } from "lit/decorators.js";
import { borderStyles, buttonStyle } from "../styles/theme";
import { UIImages } from "../data/images";
import { when } from "lit/directives/when.js";
import { translate } from "../translations/translations";
import {
  appState,
  Screen,
  toggleMute,
  setScreen,
  closeDialog,
} from "../state/app-state";
import { isProdEnv, usingFirebaseBackend } from "../env";
import { PortalAPIFirebase } from "../portal/dev";
import { apiState, RequestStatus } from "../state/api-state";
import { isOnline } from "../helpers/connectivityHelper";
import { logOutUser } from "../user";
import { installApp } from "../install";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { getAnalyticsPaydayProgress, setIsPaused } from "../state/game-state";
import { GameAbandonedEvent } from "../analytics/analytics";
import { popIn } from "../juice/juice";
import { toLogin } from "../redirects";

@customElement("hamburger-menu")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HamburgerMenu extends SignalWatcher(LitElement) {
  static styles = [
    buttonStyle,
    borderStyles,
    popIn,
    css`
      /*Hamburger menu button */
      .menu-button-events {
        touch-action: auto;
        pointer-events: auto;
      }

      app-button {
        width: 44px;
        height: 44px;
      }

      /*Hamburger menu content */
      .menu-background {
        position: absolute;
        top: 8px;
        left: 8px;
        min-width: 179px;

        display: flex;
        flex-direction: column;
        background: var(--white);
        touch-action: auto;
        pointer-events: auto;
      }

      .menu-header {
        display: flex;
        flex-direction: row;
        background: var(--primary);
        height: 36px;

        place-items: center;
      }

      .menu-avatar-container {
        width: 20px;
        height: 20px;
        flex-grow: 0;
        margin: 0 10px;
      }

      .menu-avatar {
        width: 100%;
        height: 100%;
      }

      .menu-username {
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;

        flex-grow: 1;
      }

      .menu-close-btn {
        width: 20px;
        height: 20px;
        flex-grow: 0;
        padding: 0;
        margin: 0 13px;
      }

      .menu-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin: 14px;
      }

      .sub-menu-btn-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        width: 136px;
        height: 48px;
        gap: 10px;
        cursor: pointer;
      }

      .sub-menu-btn-text {
        margin-left: 10px;
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .build-number {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        box-sizing: border-box;
        padding: 2px 12px;
        border-radius: 4px;
        background-color: var(--primary-navy);
        width: max-content;
        text-align: center;
        color: var(--primary-white);
        font-family: Ubuntu;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .menu-overlay-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--semi-translucent-bg);
      }

      .no-highlight {
        -webkit-tap-highlight-color: transparent;
      }
    `,
  ];

  @property({ type: String })
  placeholderProfileName: string = "Guest";

  @state()
  displayMenu: boolean;

  @property({ type: Boolean })
  disabled: boolean;

  @consume({ context: gameContext })
  game: GameController;

  unsubscribeFromSignal: () => void;

  connectedCallback() {
    super.connectedCallback();
    // Close menu when changing screens
    appState.screen.subscribe(this.closeMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unsubscribeFromSignal?.();
  }

  closeMenu = () => {
    this.displayMenu = false;
    setIsPaused(this.displayMenu);
  };

  toggleDisplayMenu = () => {
    this.displayMenu = !this.displayMenu;
    setIsPaused(this.displayMenu);
  };

  onHome = () => {
    if (appState.screen.value === Screen.Game) {
      // clean up game
      GameAbandonedEvent(
        this.game.getModel().getPayday().getPaydayCount(),
        getAnalyticsPaydayProgress(),
      );
      this.game.exitGame();
      // close dialogs otherwise they will open when starting a new game
      closeDialog();
    }
    setScreen(Screen.MainMenu);
  };

  onSignOut = () => {
    if (usingFirebaseBackend) {
      PortalAPIFirebase.signOut();
      return;
    }
    if (
      apiState.userRequest.value?.status !== RequestStatus.Success ||
      !apiState.userRequest.value?.data ||
      !isOnline()
    ) {
      setScreen(Screen.LogoutWarningScreen);
      return;
    }
    logOutUser();
    toLogin();
  };

  render() {
    return html`
      ${when(
        this.displayMenu && this.game.active(),
        () => html`<div class="menu-overlay-background"></div>`,
      )}
      ${when(
        this.displayMenu,
        () => html`
          <div class="menu-background border-normal">
            <div class="menu-header">
              <div class="menu-avatar-container">
                <img
                  class="menu-avatar"
                  src=${UIImages.Menu_Avatar}
                  draggable="false"
                />
              </div>
              <div class="menu-username">
                ${appState.user.value?.displayName ||
                appState.user.value?.email ||
                this.placeholderProfileName}
              </div>
              <button @click=${this.toggleDisplayMenu} class="menu-close-btn">
                <img src=${UIImages.Cross_Active} draggable="false" />
              </button>
            </div>
            <div class="menu-content-container">
              ${when(
                appState.screen.value !== Screen.MainMenu,
                () =>
                  html`<div
                    class="sub-menu-btn-container no-highlight"
                    @click=${this.onHome}
                  >
                    <app-button
                      class="popIn"
                      ?hamburgerMenu=${true}
                      imgURL=${UIImages.Menu_Home}
                    ></app-button>
                    <div class="sub-menu-btn-text">
                      ${translate("HamburgerMenu_Home")}
                    </div>
                  </div>`,
              )}
              <div
                class="sub-menu-btn-container no-highlight"
                @click=${toggleMute}
              >
                <app-button
                  class="popIn"
                  ?hamburgerMenu=${true}
                  imgURL=${appState.isMuted.value
                    ? UIImages.Menu_Mute
                    : UIImages.Menu_Unmute}
                >
                </app-button>
                <div class="sub-menu-btn-text">
                  ${appState.isMuted.value
                    ? translate("HamburgerMenu_Unmute")
                    : translate("HamburgerMenu_Mute")}
                </div>
              </div>
              <div
                class="sub-menu-btn-container no-highlight"
                @click=${this.onSignOut}
              >
                <app-button
                  class="popIn"
                  ?hamburgerMenu=${true}
                  imgURL=${UIImages.Menu_Logout}
                ></app-button>
                <div class="sub-menu-btn-text">
                  ${translate("HamburgerMenu_Logout")}
                </div>
              </div>
              ${when(
                window.installPrompt,
                () =>
                  html`<div
                    class="sub-menu-btn-container no-highlight"
                    @click=${installApp}
                  >
                    <app-button
                      class="popIn"
                      ?hamburgerMenu=${true}
                      imgURL=${UIImages.Menu_Install}
                    ></app-button>
                    <div class="sub-menu-btn-text">
                      ${translate("HamburgerMenu_InstallApp")}
                    </div>
                  </div>`,
              )}
              <div class="build-number">
                ${__VERSION__}${isProdEnv ? "" : ` ${__BUILD_ENV__}`}
              </div>
            </div>
          </div>
        `,
        () => html`
          <app-button
            ?hamburgerMenu=${true}
            class="menu-button-events"
            ?disabled=${this.disabled}
            @click=${this.toggleDisplayMenu}
            imgURL=${UIImages.Menu_Active}
          >
          </app-button>
        `,
      )}
    `;
  }
}
