import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import "./app-button";

export interface IDialogButton {
  text: string;
  onClick: () => void;
  img?: string;
}

@customElement("warning-dialog")
export class WarningDialog extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
      }

      dialog {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 4px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      #header {
        width: 100%;
        height: 35px;
        flex-grow: 0;

        background: var(--seconday-ginger-highlight);
        color: var(--primary-navy);

        text-align: center;
        font-family: Ubuntu;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        display: flex;
        place-content: center;
        place-items: center;

        @media (min-width: 1000px) {
          height: 54px;
          font-size: 32px;
        }
      }

      #content {
        flex-grow: 1;

        color: var(--primary-navy);

        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */

        display: flex;
        place-content: center;
        place-items: center;
        padding: 0px 74px 0px 74px;

        @media (min-width: 1000px) {
          padding: 0px 76px 0px 72px;
          font-size: 20px;
        }
      }

      #button-container {
        position: absolute;

        bottom: -22px;
        width: 100%;
        height: 44px;

        display: flex;
        justify-content: center;
        gap: 24px;
      }
    `,
  ];

  @property()
  title: string;
  @property({ attribute: false })
  buttons: IDialogButton[];

  render() {
    return html`<dialog open>
      <div id="header">${this.title}</div>
      <div id="content">
        <slot name="content"></slot>
      </div>
      <div id="button-container">
        ${this.buttons.map(
          (btn) =>
            html`<app-button
              @click=${btn.onClick}
              text=${btn.text}
              imgUrl=${btn.img ?? ""}
            ></app-button>`,
        )}
      </div>
    </dialog>`;
  }
}
