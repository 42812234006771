import { Signal, signal } from "@lit-labs/preact-signals";
import { IGameSave, IUserData } from "../portal/type";

export enum RequestStatus {
  None,
  InProgress,
  Success,
  Failed,
}

export interface IRequestState<T> {
  status: RequestStatus;
  data?: T;
  errorMessage?: string;
}

export interface IAPIState {
  userRequest: Signal<IRequestState<IUserData>>;
  loadGameRequest: Signal<IRequestState<IGameSave>>;
  saveGameRequest: Signal<IRequestState<void>>;
  userHighScoreRequest: Signal<IRequestState<number>>;
}

const userRequest: Signal<IRequestState<IUserData>> = signal({
  status: RequestStatus.None,
});

const loadGameRequest: Signal<IRequestState<IGameSave>> = signal({
  status: RequestStatus.None,
});

const saveGameRequest: Signal<IRequestState<void>> = signal({
  status: RequestStatus.None,
});

const userHighScoreRequest: Signal<IRequestState<number>> = signal({
  status: RequestStatus.None,
});

export const apiState: IAPIState = {
  userRequest,
  loadGameRequest,
  saveGameRequest,
  userHighScoreRequest,
};

interface IRequestActions<T> {
  start: () => void;
  success: (data: T) => void;
  failed: (errorMessage: string) => void;
}

const NewRequestActions = <T>(
  state: Signal<IRequestState<T>>,
): IRequestActions<T> => ({
  start: () => {
    state.value = { status: RequestStatus.InProgress };
  },
  success: (data: T) => {
    state.value = {
      status: RequestStatus.Success,
      data,
    };
  },
  failed: (errorMessage: string) => {
    state.value = {
      status: RequestStatus.Failed,
      errorMessage,
    };
  },
});

export const requestActions = {
  getUser: NewRequestActions<IUserData>(userRequest),
  loadGame: NewRequestActions<IGameSave>(loadGameRequest),
  saveGame: NewRequestActions<void>(saveGameRequest),
  getUserHighScore: NewRequestActions<number>(userHighScoreRequest),
};
