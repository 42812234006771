import Phaser from "phaser";
import { GameImages } from "../data/images";
import { gameConfig } from "../config/gameConfig";

export class CostTextGameObject extends Phaser.GameObjects.Container {
  private coinImage: Phaser.GameObjects.Image;
  private costText: Phaser.GameObjects.Text;
  private coinXOffset: number = 25;
  private cost: number = 5;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene);
    this.setPosition(x, y);
    this.costText = this.scene.add.text(0, 0, this.cost.toString(), {
      color: `${gameConfig.textColor}`,
      fontSize: "14px",
      fontStyle: "Bold",
      fontFamily: "Ubuntu",
      stroke: `${gameConfig.strokeColor}`,
      strokeThickness: 4,
    });
    this.costText.setOrigin(0.5, 0.5);
    this.add(this.costText);

    this.coinImage = this.scene.add.image(
      this.costText.getCenter().x - this.coinXOffset,
      this.costText.getCenter().y,
      GameImages.Coin,
    );
    this.add(this.coinImage);
  }

  public setCostAmount(cost: number) {
    this.cost = cost;
    this.costText.setText(this.cost.toString());
  }
}
