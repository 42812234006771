import Phaser from "phaser";
import { TillGameObject } from "./till";
import { ProductGameObject } from "./product";
import { Till } from "../models/till";
import { Product } from "../models/product";
import { AddProductButton } from "./add-product-btn";
import { Vector2 } from "../models/vector2";
import { GameObjectDepth } from "../data/game-objects";
import { gameConfig } from "../config/gameConfig";
import { ActiveDialog, openDialog } from "../state/app-state";
import { HireEmployeeButton } from "./hire-employee-btn";
import { mapData } from "../controllers/mapData";
import { effect } from "@lit-labs/preact-signals";
import { gameState } from "../state/game-state";

export class ShopGameObject extends Phaser.GameObjects.Container {
  scene: Phaser.Scene;
  tills: { [id: string]: TillGameObject };
  products: { [kind: string]: ProductGameObject };
  addProduct: AddProductButton;
  hireEmployee: HireEmployeeButton;

  private unsubscribeFromSignal: () => void;

  constructor(
    scene: Phaser.Scene,
    level: number,
    nextLaunchProductPosition: Vector2 | undefined,
    nextHireEmployeePosition: Vector2 | undefined,
    nextProductIndex: number | undefined,
  ) {
    super(scene);
    this.scene = scene;
    this.tills = {};
    this.products = {};

    const img = this.scene.add.image(0, 0, `shop_level${level}`);
    img.setOrigin(0, 0);
    img.setDepth(GameObjectDepth.Background);
    img
      .setDisplaySize(gameConfig.backgroundWidth, gameConfig.backgroundHeight)
      .setPosition(0, 0);

    // only create the button if the shop can have another product
    if (nextLaunchProductPosition) {
      this.createAddProductButton(nextLaunchProductPosition, nextProductIndex);
    }

    if (nextHireEmployeePosition) {
      this.createHireEmployeeButton(nextHireEmployeePosition);
    }
  }

  destroy = () => {
    Object.keys(this.tills).forEach((k) => {
      this.tills[k].destroy();
    });
    Object.values(this.products).forEach((p) => p.destroy(true));
    this.addProduct?.destroy(true);
    this.hireEmployee?.destroy(true);

    if (this.unsubscribeFromSignal !== undefined) {
      this.unsubscribeFromSignal();
    }
  };

  till = (id: string): TillGameObject => {
    return this.tills[id];
  };

  createTill = (till: Till) => {
    const t = new TillGameObject(this.scene);
    t.setPosition(till.x, till.y);
    this.scene.add.existing(t);
    this.tills[till.id] = t;
  };

  createProduct = (p: Product) => {
    const pObj = new ProductGameObject(this.scene, p);

    this.scene.add.existing(pObj);
    this.products[p.kind.key()] = pObj;

    // The camera needs to centre on the UI and not the product - an offset was added to position the camera
    pObj.on("click", () => {
      const product = p;

      openDialog(ActiveDialog.OpenProduct, {
        product,
      });
    });
  };

  createAddProductButton = (pos: Vector2, nextProductIndex: number) => {
    this.addProduct?.destroy();
    this.addProduct = new AddProductButton(this.scene);
    this.addProduct
      .setPosition(pos.x, pos.y)
      .setDepth(GameObjectDepth.AddProductButton);

    // Only play the pointer juice for the 1st add product btn
    if (nextProductIndex <= 0) {
      this.addProduct.playPointerJuice();
    } else {
      this.addProduct.destroyPointer();
    }

    this.addProduct.on("click", () => {
      openDialog(ActiveDialog.LaunchProduct);
    });
    this.scene.add.existing(this.addProduct);
  };

  createHireEmployeeButton = (pos: Vector2) => {
    this.hireEmployee?.destroy();
    this.hireEmployee = new HireEmployeeButton(this.scene);
    this.hireEmployee
      .setPosition(pos.x, pos.y)
      .setDepth(GameObjectDepth.HireEmployeeButton);

    if (this.unsubscribeFromSignal !== undefined) {
      this.unsubscribeFromSignal();
    }

    // this is a quick way to being able to play the pointer juice
    // and couples the ui signals to the shop. This should rather be done in the game view
    // but will take time to rework
    this.unsubscribeFromSignal = effect(() => {
      // only play for the first hire employee btn after event has been shown
      if (
        pos.x === mapData.hireEmployeePosition[1].x &&
        gameState.eventQueue.value.length >= 1 &&
        gameState.eventQueue.value[0].id === "lost-sale-no-staff"
      ) {
        this.hireEmployee.playPointerJuice();
      }
    });

    if (pos.x !== mapData.hireEmployeePosition[1].x) {
      this.hireEmployee.destroyPointer();
    }

    this.hireEmployee.on("click", () => {
      openDialog(ActiveDialog.HireEmployee);
    });
    this.scene.add.existing(this.hireEmployee);
  };

  displayAddProductAlert = (enabled: boolean) => {
    this.addProduct?.setUpgradePipVisibility(enabled);
  };

  displayHireEmployeeAlert = (enabled: boolean) => {
    this.hireEmployee?.setUpgradePipVisibility(enabled);
  };
}
