import { CopyKey } from "../translations/keys";
import { translate } from "../translations/translations";

export enum ProductGroup {
  Clothing = "clothing",
  Technology = "technology",
  CoffeeShop = "coffeeShop",
  Stationary = "stationary",
  Toiletries = "toiletries",
}

export class ProductKind {
  readonly group: ProductGroup;
  readonly index: number;
  constructor(group: ProductGroup, index: number) {
    this.group = group;
    this.index = index;
  }
  key = (): string => `product_${this.group}_${this.index}`;
  equals = (other: ProductKind): boolean =>
    this.group === other.group && this.index === other.index;
  productName = () => {
    switch (this.group) {
      case ProductGroup.Clothing:
        return translate(`ItemPopUp_Fashion_0${this.index + 1}` as CopyKey);
      case ProductGroup.CoffeeShop:
        return translate(`ItemPopUp_CoffeeShop_0${this.index + 1}` as CopyKey);
      case ProductGroup.Technology:
        return translate(`ItemPopUp_Technology_0${this.index + 1}` as CopyKey);
      case ProductGroup.Stationary:
        return translate(`ItemPopUp_Stationery_0${this.index + 1}` as CopyKey);
      case ProductGroup.Toiletries:
        return translate(
          `ItemPopUp_HairAndBeauty_0${this.index + 1}` as CopyKey,
        );
    }
  };
}

export const productGroupInfoMap = {
  [ProductGroup.Clothing]: [
    "Caps",
    "Flip flops",
    "T-shirt",
    "Strappy top",
    "Hoodie",
    "Jean",
    "Boots",
    "Sneakers",
    "Street Puffer Jacket",
  ],
  [ProductGroup.Technology]: [
    "Phone cover",
    "Earphones",
    "Bluetooth speaker",
    "Ring light camera",
    "Smart watch",
    "Cellphone",
    "Camera",
    "Laptop",
    "Game Console",
  ],
  [ProductGroup.CoffeeShop]: [
    "Coffee",
    "Pastry",
    "Bubble Tea",
    "Sandwich",
    "Fancy Coffee (Starbucks)",
    "Full cake",
    "Cake slice",
    "Coffee Beans",
    "Coffee Machine",
  ],
  [ProductGroup.Stationary]: [
    "Notebook",
    "Colourful pens",
    "Pencil case",
    "(Tote?) Bag",
    "Pencil holder",
    "Desk calendar",
    "Novel",
    "Journal",
    "Art Supplies Box Set",
  ],
  [ProductGroup.Toiletries]: [
    "Eyeshadow",
    "Lipstick",
    "Nail polish",
    "False Eyelashes",
    "Hair treatment",
    "Hair clip",
    "Face cream",
    "Fancy hair weave",
    "Serum",
  ],
};
