import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { appState, closeDialog } from "../state/app-state";
import { Employee } from "../models/employee";
import { error } from "../helpers/loggerHelper";
import { gameDialogStyles } from "../styles/game-dialog";
import { borderStyles } from "../styles/theme";
import { translate } from "../translations/translations";
import { UIImages } from "../data/images";
import "./arrow-container";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("pay-employee-dialog")
export class PayEmployeeDialog extends SignalWatcher(LitElement) {
  static styles = [
    gameDialogStyles,
    borderStyles,
    css`
      arrow-container {
        width: 146px;
        height: 108px;

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        flex-direction: column;
      }

      .heading-padding {
        padding: 6px 8px;
      }

      .green-header {
        width: 100%;
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .body {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        padding: 14px;
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  private employee: Employee;
  connectedCallback() {
    super.connectedCallback();
    const employee = appState.dialog.value.props;
    if (!employee || !(employee instanceof Employee)) {
      error(
        "[pay-employee-dialog] Wrong properties passed to this dialog!",
        appState.dialog.value,
      );
      closeDialog();
    } else {
      this.employee = employee;
    }
  }

  payEmployee = () => {
    this.game.payEmployee(this.employee);
    closeDialog();
  };

  firstUpdated() {
    playSoundEffect("PopIn");
  }

  render() {
    const canPay = this.game.getModel().getGold() >= this.employee.getCost();
    return html`
      <arrow-container smallGreen>
        <div class="green-header">
          <div class="heading-padding">${translate("Staff_Heading")}</div>
          <popup-close-button></popup-close-button>
        </div>

        <div class="body">
          <img src=${UIImages.AlertPip} height="16px" draggable="false" />
          <div style="padding-left: 14px">${translate("Staff_Body")}</div>
        </div>

        <div class="btn-wrapper">
          <app-button @click=${this.payEmployee} ?disabled=${!canPay}>
            <img
              src=${UIImages.CoinColor}
              height="28px"
              style=${!canPay ? "opacity: 0.3" : ""}
            />
            ${this.employee.getCost()}
          </app-button>
        </div>
      </arrow-container>
    `;
  }
}
