import { consume } from "@lit/context";
import { html, css, nothing, LitElement, HTMLTemplateResult } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { OpenProductProps, appState, closeDialog } from "../state/app-state";
import { Product } from "../models/product";
import { error } from "../helpers/loggerHelper";
import { Signal, SignalWatcher, watch } from "@lit-labs/preact-signals";
import { LottieAnimationJSONs, UIImages } from "../data/images";
import { playSoundEffect } from "../sound/sound-manager";
import { gameDialogStyles } from "../styles/game-dialog";
import { borderStyles, greenContainer } from "../styles/theme";

import "./app-button";
import "./arrow-container";
import "./popup-close-button";
import { translate } from "../translations/translations";
import { CopyKey } from "../translations/keys";
import { getProductImage } from "../helpers/productImageHelper";
import { ProductGroup } from "../data/products";
import { ProductPricingEvent, StockOrderedEvent } from "../analytics/analytics";
import "@lottiefiles/lottie-player";
import "./coin-slider";
import { popIn } from "../juice/juice";

// Animation is 0.7s, so we can clean it up after 1.5s to be safe
const juiceAnimationTimeout = 1500;

@customElement("open-product-dialog")
export class OpenProductDialog extends SignalWatcher(LitElement) {
  static styles = [
    gameDialogStyles,
    greenContainer,
    borderStyles,
    popIn,
    css`
      :host {
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        color: var(--primary-navy);

        --button-container: 1;
        --tabbed-container: 2;
        --product-count-z-index: 10;
        --product-z-index: 1;
        --marketing-juice-lottie: 15;
      }

      arrow-container {
        width: 272px;
        height: 242px;
        position: absolute;
      }

      .wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        top: 0px;
      }

      .header-color {
        height: 67px;
        width: 100%;
        background-color: var(--primary);
      }

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .header-content {
        display: flex;
        padding: 8px 6px 6px;
      }

      .tabbed-wrapper {
        width: 256px;
        height: 127px;
        display: flex;
        align-items: center;
        padding: 6px;
        position: relative;
        z-index: var(--tabbed-container);
      }

      .gold-value {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
      }

      .gold-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
      }

      .gold-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .buy-stock {
        font-size: 14px;
        display: flex;
        justify-content: center;
      }

      .button-pill-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: var(--button-container);
      }

      .pill {
        width: 100px;
        height: 20px;
        background-color: var(--primary-highlight);
        border-radius: 12px;
        border: 2px solid var(--primary);

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;

        position: relative;
        z-index: var(--product-count-z-index);
      }

      .tab-header {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 14px;
        font-weight: 700;
        color: var(--white);
      }

      .tab-header.marketing {
        background-color: var(--secondary-violet-highlight);
      }

      .tab-header.marketing[selected] {
        background-color: var(--violet-lowlight);
      }

      .tab-header.price {
        background-color: var(--light-purple);
      }

      .tab-header.price[selected] {
        background-color: var(--blue-lowlight);
      }

      .tab-section {
        height: 105px;
        box-sizing: border-box;
        border-radius: 0px 0px 4px 4px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .tab-section.price {
        background-color: var(--light-purple);
        box-sizing: border-box;
        border: 2px solid var(--blue-lowlight);
      }

      .tab-section.marketing {
        background-color: var(--secondary-violet-highlight);
        box-sizing: border-box;
        border: 2px solid var(--violet);

        font-size: 14px;
        font-weight: 700;

        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        display: flex;
        padding: 6px;
        margin-bottom: 8px;
      }

      .marketing-bar-wrapper {
        font-weight: 500;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .marketing-bar-outer {
        width: 236px;
        height: 10px;
        box-sizing: border-box;
        border: 2px solid var(--primary-navy);
        border-radius: 6px;
        background-color: var(--white);
      }

      .marketing-bar-inner {
        width: 50%;
        height: 100%;
        border-radius: 10px;
        background-color: var(--violet);
      }

      .levels {
        display: flex;
      }

      .level-indicator {
        display: flex;
        justify-content: space-between;
      }

      .level-circle {
        display: flex;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid var(--primary-navy);
        background-color: var(--white);
        color: var(--primary-navy);
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        margin: 2px;
      }

      .level-circle.completed {
        background-color: var(--violet);
        color: var(--white);
      }

      .marketing-button {
        display: flex;
        justify-content: center;
        translate: 0px -50%;
        position: relative;
      }

      .selling-price-indicator {
        width: 232px;
        height: 24px;
        box-sizing: border-box;
        background-color: var(--white);
        display: flex;
        font-size: 14px;
        line-height: normal;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
      }

      .item-price {
        display: flex;
        gap: 8px;
      }

      .pill-wrapper {
        position: relative;
      }

      .product-juice-pos {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50px;
        top: -50px;
        opacity: 0;
        z-index: var(--product-z-index);
      }

      .product-juice-anim {
        animation: 0.7s ease-in stockAnim;
        transform-origin: center;
      }

      @keyframes stockAnim {
        0% {
          top: -50px;
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        99% {
          top: -5px;
        }
        100% {
          top: -50px;
          opacity: 0;
        }
      }

      .marketing-juice {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: var(--marketing-juice-lottie);
        width: 300px;
        height: 240px;
        pointer-events: none;
      }

      #buy-stock-btn {
        --app-btn-padding: 4px 6px 8px;
        --app-btn-padding-active: 4px 6px;
      }

      #price-tab-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 14px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      #coin-slider-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 14px;
        margin-bottom: 4px;

        font-size: 14px;
        width: 100%;
      }
    `,
  ];

  // Slider with image was taken from here:
  // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_js_rangeslider_pic

  @consume({ context: gameContext })
  game: GameController;

  @state()
  nextPromoteCost: number;

  @state()
  itemPrice: number;

  @query("#price-input")
  priceSlider: HTMLInputElement;

  @state()
  productJuice: Record<string, HTMLTemplateResult>;

  @state()
  juiceLottiePlayers: Record<number, HTMLTemplateResult>;

  private gold: Signal<number>;

  private product: Product;

  private stockBought: number = 0;

  protected firstUpdated(): void {
    playSoundEffect("PopIn");
  }

  connectedCallback() {
    super.connectedCallback();
    this.juiceLottiePlayers = {};
    this.productJuice = {};
    this.product = (
      appState.dialog.value.props as unknown as OpenProductProps
    ).product;
    this.stockBought = 0;
    if (!this.product || !(this.product instanceof Product)) {
      error(
        "[open-product-dialog] Wrong properties passed to this dialog!",
        appState.dialog.value,
      );
      closeDialog();
    } else {
      this.calculateDemand();
      this.itemPrice = this.product.price;
    }
    this.gold = this.game.getModel().getUIWatchableModel().gold;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // This event wants how many stock items are bought 'in bulk', so we
    // log the event when the dialog is closed and the 'purchase session' is complete.
    // Same logic applies for the product pricing event.
    if (this.product) {
      StockOrderedEvent(
        this.stockBought,
        this.product.cost,
        this.product.kind.key(),
        this.product.kind.productName(),
      );
      ProductPricingEvent(
        this.product.price,
        this.product.maxPrice,
        this.product.cost,
        this.product.kind.key(),
        this.product.kind.productName(),
      );
    }
  }

  private currentAnimationIndex = 0;
  createMarketingJuice = (demand: number) => {
    const level = Math.floor(demand / 10);
    if (level >= LottieAnimationJSONs.MarketingJuice.length) return;
    if (
      this.currentAnimationIndex >=
      LottieAnimationJSONs.MarketingJuice[level].length
    ) {
      this.currentAnimationIndex = 0;
    }
    const src =
      LottieAnimationJSONs.MarketingJuice[level][this.currentAnimationIndex];
    this.currentAnimationIndex++;

    const onComplete = () => {
      this.juiceLottiePlayers = {
        ...this.juiceLottiePlayers,
        [demand]: html`${nothing}`,
      };
    };
    const player = html`
      <lottie-player
        id="marketing-juice-${demand}"
        autoplay
        mode="normal"
        src=${src}
        class="marketing-juice"
        @complete=${onComplete}
      >
      </lottie-player>
    `;
    this.juiceLottiePlayers = {
      ...this.juiceLottiePlayers,
      [demand]: player,
    };
  };

  addStock = () => {
    this.game.buyStock(this.product);
    this.stockBought++;
    playSoundEffect("Purchase");
    this.buyStockJuice();
  };
  addDemand = () => {
    this.game.promoteProduct(this.product);
    this.createMarketingJuice(this.product.demand);
    this.calculateDemand();
    playSoundEffect("Purchase");
  };
  calculateDemand = () => {
    const { cost, increasePerLevel } = this.game.gameplayParams.marketing;
    this.nextPromoteCost = this.product.getMarketingCost(
      cost,
      increasePerLevel,
    );
  };
  onPriceChanged = (e: CustomEvent<{ value: number }>) => {
    this.itemPrice = e.detail.value;
    this.game.setProductPrice(this.product, this.itemPrice);
  };

  renderMarketingLevelCircle = (marketingLevel: number, level: number) => {
    return html`
      <div
        class=${level <= marketingLevel
          ? "level-circle completed"
          : "level-circle"}
      >
        ${level}
      </div>
    `;
  };

  displayMarketingLevel = (marketingLevel: number) => {
    const levelCircles = [];
    for (let i = 1; i <= 5; i++) {
      levelCircles.push(this.renderMarketingLevelCircle(marketingLevel, i));
    }
    return html`<div class="levels">${levelCircles}</div> `;
  };

  productImage = (
    productIndex: number,
    businessType: ProductGroup,
    imgIdx: string,
  ) => {
    return html`
      <img
        id="product_${imgIdx}"
        class="product-juice-pos product-juice-anim"
        src=${getProductImage(productIndex, businessType)}
        draggable="false"
      />
    `;
  };

  buyStockJuice = () => {
    const key = new Date().toISOString();
    const businessType = this.game.getModel().getShop().getBusinessType();
    const productIndex = this.product.kind.index;

    const img = this.productImage(productIndex, businessType, key);

    this.productJuice = {
      ...this.productJuice,
      [key]: img,
    };

    setTimeout(() => {
      this.productJuice = {
        ...this.productJuice,
        [key]: html`${nothing}`,
      };
    }, juiceAnimationTimeout);
  };

  getMarketingPercentagePerLevel = (marketingLevel: number): number => {
    let marketingPercentagePerLevel =
      (this.product.demand - marketingLevel * 10 + 1) * 10;

    if (marketingLevel < 1) {
      marketingPercentagePerLevel =
        (this.product.demand - marketingLevel * 10) * 11.1;
    }
    if (marketingLevel === 5) {
      marketingPercentagePerLevel = 100;
    }

    return marketingPercentagePerLevel;
  };

  render() {
    if (!this.product || !(this.product instanceof Product)) return nothing;
    const isStockEnabled =
      this.product.cost <= this.gold.value &&
      this.product.availableStock.value < this.product.stockLimit;
    const isMaxStock =
      this.product.availableStock.value === this.product.stockLimit;
    const isMaxDemand =
      this.product.demand >= this.game.gameplayParams.marketing.maxDemand;

    const isPromoteEnabled =
      this.nextPromoteCost <= this.gold.value && !isMaxDemand;
    const marketingLevel = Math.floor(this.product.demand / 10);

    const marketingPercentagePerLevel =
      this.getMarketingPercentagePerLevel(marketingLevel);

    const marketingText =
      marketingLevel >= 0 && marketingLevel < 5
        ? translate(`ItemPopUp_Marketing0${marketingLevel + 1}` as CopyKey)
        : translate("ItemPopUp_Max");

    return html`
      <arrow-container>
        <div class="wrapper">
          <div class="header">
            <div class="header-content">${this.product.kind.productName()}</div>
            <popup-close-button></popup-close-button>
          </div>

          <div class="button-pill-container">
            <app-button
              id="buy-stock-btn"
              @click=${this.addStock}
              class="gold-value popIn game-button"
              ?disabled=${!isStockEnabled}
            >
              <div class="gold-wrapper">
                <div class="gold-amount">
                  <img
                    src=${UIImages.CoinColor}
                    height="28px"
                    style=${!isStockEnabled ? "opacity: 0.3" : ""}
                    draggable="false"
                  />
                  ${isMaxStock
                    ? translate("ItemPopUp_Button_Full")
                    : this.product.cost}
                </div>
                <div class="buy-stock">
                  ${translate("ItemPopUp_Button_BuyStock")}
                </div>
              </div>
            </app-button>
            <div class="pill-wrapper">
              <div class="pill">
                ${watch(this.product.availableStock)}
                ${translate("ItemPopUp_InStock")}
              </div>
              ${Object.values(this.productJuice)}
            </div>
          </div>

          <div class="tabbed-wrapper">
            <tabbed-container>
              <h2 class="tab-header price" slot="tab">
                ${translate("ItemPopUp_SetPrice")}
              </h2>
              <section slot="panel">
                <div class="tab-section price">
                  <div id="price-tab-container">
                    <div class="selling-price-indicator border-normal">
                      <div>${translate("ItemPopUp_SellingPrice")}:</div>
                      <div class="item-price">
                        <img
                          src=${UIImages.CoinLine}
                          height="16px"
                          draggable="false"
                        />
                        ${this.itemPrice}
                      </div>
                    </div>
                    <div>
                      <div id="coin-slider-text">
                        <div>${translate("ItemPopUp_LowRisk")}</div>
                        <div>${translate("ItemPopUp_HighRisk")}</div>
                      </div>
                      <coin-slider
                        minValue=${this.product.cost + 1}
                        maxValue=${this.product.maxPrice}
                        value=${this.itemPrice}
                        @change=${this.onPriceChanged}
                      ></coin-slider>
                    </div>
                  </div>
                </div>
              </section>
              <h2 class="tab-header marketing" slot="tab">
                ${translate("ItemPopUp_Marketing")}
              </h2>
              <section slot="panel">
                <div class="tab-section marketing">
                  ${translate("ItemPopUp_MoreCustomers")}
                  <div class="marketing-bar-wrapper">
                    <div class="level-indicator">
                      <div>${translate("ItemPopUp_Level")}</div>
                      ${this.displayMarketingLevel(marketingLevel)}
                    </div>
                    <div class="marketing-bar-outer">
                      <div
                        class="marketing-bar-inner"
                        style=${"width:" + marketingPercentagePerLevel + "%"}
                      ></div>
                    </div>

                    <div
                      style="font-size: 14px; weight: 500; padding-top: 8px; margin: 0px;"
                    >
                      ${marketingText}
                    </div>
                  </div>
                </div>
                <div class="marketing-button ">
                  <app-button
                    class="popIn game-button"
                    ?disabled=${!isPromoteEnabled}
                    @click=${this.addDemand}
                  >
                    <div style="display: flex; align-items: center; gap: 10px">
                      <img
                        src=${UIImages.CoinColor}
                        style="padding-right: 8px;"
                        style=${!isPromoteEnabled ? "opacity: 0.3" : ""}
                        draggable="false"
                      />
                      ${isMaxDemand
                        ? translate("ItemPopUp_Button_Max")
                        : this.nextPromoteCost}
                    </div>
                    ${Object.values(this.juiceLottiePlayers)}
                  </app-button>
                </div>
              </section>
            </tabbed-container>
          </div>
        </div>
      </arrow-container>
    `;
  }
}
