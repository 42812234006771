export const GameObjectDepth = {
  Background: 1,
  Customer: 5, // The customers depth are being set by their yPos for depth sorting
  Till: 1000,
  HireEmployeeButton: 1005,
  Employee: 1015, // The employee depth are being set by their yPos for depth sorting
  ThoughtBubble: 1030,
  Product: 2020,
  AddProductButton: 2025,
  CoinJuice: 2070,
  CoinJuiceText: 2071,
};
