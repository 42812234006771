import { css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { PortalAPIFirebase } from "../portal/dev";
import { usingFirebaseBackend } from "../env";
import { appState, Screen, setScreen } from "../state/app-state";
import { SignalWatcher } from "@lit-labs/preact-signals";

@customElement("sign-in-screen")
export class SignInScreen extends SignalWatcher(LitElementI18n) {
  static styles = [
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .signInContainer {
        max-width: 400px;
        max-height: 200px;
      }

      .btnContainer {
        display: flex;
        flex-direction: row;
      }
      .btnContainer > button {
        flex-grow: 1;
      }
    `,
    buttonStyle,
  ];

  @query("#email")
  emailRef: HTMLInputElement;
  @query("#password")
  passwordRef: HTMLInputElement;

  constructor() {
    super();
  }

  onSignIn = () => {
    PortalAPIFirebase.signIn(this.emailRef.value, this.passwordRef.value)
      .then(() => setScreen(Screen.MainMenu))
      .catch(() => {
        alert("Failed to log in");
        this.emailRef.value = "";
        this.passwordRef.value = "";
        this.emailRef.focus();
      });
  };

  continueToGame = () => {
    setScreen(Screen.MainMenu);
  };

  render() {
    if (!usingFirebaseBackend || appState.user.value !== null) {
      return html`
        <div class="signInContainer">
          <button class="primary" @click=${this.continueToGame}>
            Continue
          </button>
        </div>
      `;
    }
    return html`
      <div class="signInContainer">
        <div>Email: <input id="email" type="email" /></div>
        <div>Password: <input id="password" type="password" /></div>
        <div class="btnContainer">
          <button class="primary" @click=${this.onSignIn}>Sign In</button>
        </div>
      </div>
    `;
  }
}
