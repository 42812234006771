import { Signal, signal } from "@lit-labs/preact-signals";
import { ProductKind } from "../data/products";
import { WalkAwayReason } from "./customer";
export interface ProductInfo {
  kind: ProductKind;
  income: number;
  [WalkAwayReason.ProductNotAvailable]: number;
  [WalkAwayReason.TillNotAvailable]: number;
  [WalkAwayReason.ProductTooExpensive]: number;
  [WalkAwayReason.ProductPurchased]: number;
  no_marketing: number;
}

interface ReportData {
  expenses: {
    stockCost: number;
    marketing: number;
    salaries: number;
  };
  income: {
    achievementsRevenue: number;
  };
  productInfo: Record<string, ProductInfo>;
}

export class FinancialReportModel {
  ///Business value tab variables
  public stockValue: Signal<number>;
  public achievementsValue: Signal<number>;

  //Profit/loss tab: Expenses variables
  public stockCost: number;
  public marketing: number;
  public salaries: number;

  //Profit/loss tab: Revenue variables
  public achievementsRevenue: number;
  public productInfo: Record<string, ProductInfo>;

  private previousReports: { [index: number]: ReportData };
  private totalProfit: number;

  constructor(opts?: {
    stockValue?: number;
    achievementsValue?: number;

    stockCost?: number;
    marketing?: number;
    salaries?: number;

    achievementsRevenue?: number;
    productInfo?: Record<string, ProductInfo>;
    previousReports?: { [index: number]: ReportData };
    totalProfit?: number;
  }) {
    this.stockValue = signal(opts?.stockValue ?? 0);
    this.achievementsValue = signal(opts?.achievementsValue ?? 0);

    this.stockCost = opts?.stockCost ?? 0;
    this.marketing = opts?.marketing ?? 0;
    this.salaries = opts?.salaries ?? 0;

    this.achievementsRevenue = opts?.achievementsRevenue ?? 0;
    this.productInfo = opts?.productInfo ?? {};
    this.previousReports = opts?.previousReports ?? {};
    this.totalProfit = opts?.totalProfit ?? 0;
  }

  private initializeProductInfo(kind: ProductKind) {
    this.productInfo[kind.key()] = {
      kind,
      income: 0,
      product_not_available: 0,
      product_purchased: 0,
      product_too_expensive: 0,
      till_not_available: 0,
      no_marketing: 0,
    };
  }

  public getProduct(kind: ProductKind): ProductInfo {
    if (!this.productInfo[kind.key()]) {
      this.initializeProductInfo(kind);
    }
    return this.productInfo[kind.key()];
  }

  public sellProduct = (amount: number, kind: ProductKind) => {
    this.getProduct(kind).income += amount;
    this.getProduct(kind)[WalkAwayReason.ProductPurchased]++;
  };

  public loseProductSale = (
    kind: ProductKind,
    walkAwayReason: WalkAwayReason,
  ) => {
    if (walkAwayReason === WalkAwayReason.Exception)
      throw new Error(
        "[financialReportModel] Lost a product sale due to an exception",
      );
    this.getProduct(kind)[walkAwayReason]++;
  };

  public getCustomerCount = (kind: ProductKind, includeMarketing = true) => {
    const info = this.getProduct(kind);
    return (
      info[WalkAwayReason.ProductNotAvailable] +
      info[WalkAwayReason.ProductPurchased] +
      info[WalkAwayReason.ProductTooExpensive] +
      info[WalkAwayReason.TillNotAvailable] +
      (includeMarketing ? info.no_marketing : 0)
    );
  };

  public get expenses(): number {
    return this.stockCost + this.marketing + this.salaries;
  }

  public get salesIncome(): number {
    return Object.values(this.productInfo).reduce(
      (total: number, info: ProductInfo) => total + info.income,
      0,
    );
  }

  public get income(): number {
    // Exclude the achievements revenue from the income calculation.
    return this.salesIncome;
  }

  public get profit(): number {
    return this.income - this.expenses;
  }

  public getAllFailedSales = (reason: WalkAwayReason | "no_marketing") => {
    if (reason === WalkAwayReason.Exception) return;
    let total = 0;
    Object.values(this.productInfo).forEach((info) => (total += info[reason]));
    Object.values(this.previousReports).forEach((prevReport) =>
      Object.values(prevReport).forEach((info) => (total += info[reason])),
    );
    return total;
  };

  public getTotalProductInfo = () => {
    const totalProductInfo: ProductInfo = {
      kind: undefined,
      income: 0,
      product_purchased: 0,
      product_not_available: 0,
      product_too_expensive: 0,
      till_not_available: 0,
      no_marketing: 0,
    };
    Object.values(this.productInfo).forEach((info) => {
      totalProductInfo.product_purchased += info.product_purchased;
      totalProductInfo.product_not_available += info.product_not_available;
      totalProductInfo.product_too_expensive += info.product_too_expensive;
      totalProductInfo.till_not_available += info.till_not_available;
      totalProductInfo.no_marketing += info.no_marketing;
    });
    return totalProductInfo;
  };

  public getTotalProfit = () => this.totalProfit;

  public clearReportData = (paydayCount: number) => {
    this.totalProfit += this.profit;
    this.previousReports[paydayCount] = {
      expenses: {
        stockCost: this.stockCost,
        marketing: this.marketing,
        salaries: this.salaries,
      },
      income: {
        achievementsRevenue: this.achievementsRevenue,
      },
      productInfo: this.productInfo,
    };
    this.stockCost = 0;
    this.marketing = 0;
    this.salaries = 0;
    this.achievementsRevenue = 0;
    Object.values(this.productInfo).forEach((info) => {
      this.initializeProductInfo(info.kind);
    });
  };

  toJSON = (): object => {
    return {
      stockValue: this.stockValue.value,
      achievementsValue: this.achievementsValue.value,

      stockCost: this.stockCost,
      marketing: this.marketing,
      salaries: this.salaries,

      achievementsRevenue: this.achievementsRevenue,
      productInfo: this.productInfo,
      previousReports: this.previousReports,
      totalProfit: this.totalProfit,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): FinancialReportModel => {
    const productInfo: Record<string, ProductInfo> = {};
    const previousReports: { [index: number]: ReportData } = {};
    if (obj.productInfo) {
      Object.entries(obj.productInfo as Record<string, ProductInfo>).forEach(
        ([key, info]) => {
          productInfo[key] = {
            ...info,
            kind: new ProductKind(info.kind.group, info.kind.index),
          };
        },
      );
    }
    if (obj.previousReports) {
      Object.entries(
        obj.previousReports as { [index: number]: ReportData },
      ).forEach(([index, report]) => {
        const pastProductInfo: Record<string, ProductInfo> = {};

        Object.entries(
          report.productInfo as Record<string, ProductInfo>,
        ).forEach(([key, info]) => {
          pastProductInfo[key] = {
            ...info,
            kind: new ProductKind(info.kind.group, info.kind.index),
          };
        });

        previousReports[+index] = {
          ...report,
          productInfo: pastProductInfo,
        };
      });
    }

    return new FinancialReportModel({
      ...obj,
      productInfo,
      previousReports,
    });
  };
}
