import { Workbox } from "workbox-window";
import { isDevEnv } from "./env";

/* eslint-disable no-console */
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    const wb = new Workbox("sw.js");
    wb.addEventListener("installed", (event) => {
      if (isDevEnv && event.isUpdate) {
        if (confirm(`Refresh for new build`)) {
          window.location.reload();
        }
      }
    });
    wb.register()
      .then(() => {
        console.log("Service worker registered!");
      })
      .catch((error) => {
        console.warn("Error registering service worker:");
        console.warn(error);
      });
  });
}
